var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parent-container"},[_c('Bar'),_vm._m(0),_c('div',{staticClass:"NIT-content fx-r"},[_vm._m(1),_c('div',{staticClass:"NIT-content-right"},[_c('div',{staticClass:"NIT-right-title fx-r"},[_c('div',{staticClass:"recent"},[_vm._v("近期最热文章")]),_c('div',{staticClass:"imageText fx-r"},[_c('span',{staticClass:"span-g"},[_vm._v("图文")]),_c('span',{staticClass:"span-I"},[_vm._v("|")]),_c('span',[_c('router-link',{staticClass:"span-v",attrs:{"to":"/VideoNews"}},[_vm._v("视频")])],1)])]),_c('div',{staticClass:"recommend fx-h"},[_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news1}},[_c('img',{attrs:{"src":require("@/assets/news1.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(2)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news2}},[_c('img',{attrs:{"src":require("@/assets/new2.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(3)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news3}},[_c('img',{attrs:{"src":require("@/assets/new3.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(4)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news5}},[_c('img',{attrs:{"src":require("@/assets/new5.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(5)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news6}},[_c('img',{attrs:{"src":require("@/assets/new6.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(6)]),_c('div',{staticClass:"see-more",on:{"click":_vm.news}},[_vm._v(" 查看更多 "),_c('img',{attrs:{"src":require("@/assets/MoreIcon.png"),"alt":""}})])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI1"},[_c('div',{staticClass:"BI11 fx-h"},[_c('h1',{staticClass:"BI111"},[_vm._v("新闻")]),_c('br'),_c('a',{staticClass:"BI111 h3"},[_vm._v("掌握市场动态，领先一步")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NIT-content-left fx-h"},[_c('div',{staticClass:"NIT-title"},[_c('h1',[_vm._v("北海：2023年对越南外贸进出口总值59.3亿元")]),_c('span',[_vm._v("2024-07-27")]),_c('span',[_vm._v("12：30")]),_c('span',[_vm._v("来源 ：今日头条")])]),_c('div',{staticClass:"NIT-model1 fx-h"},[_c('div',{staticClass:"fx-r"},[_c('img',{attrs:{"src":require("@/assets/new4-01.png"),"alt":"","width":"100%","height":"350px"}})]),_c('p',{staticClass:"note"},[_vm._v("（图片来源：互联网）")]),_c('p',{staticClass:"text"},[_vm._v(" 2月26日，北海海关消息，2023年北海对越南外贸进出口总值59.3亿元，同比增长64.8%。其中，出口48.5亿元，同比增长232.6%。 ")]),_c('p',{staticClass:"text"},[_vm._v(" 近年来，北海发挥西南地区重要出海通道和“一带一路”有机衔接重要节点城市的区位优势，依托综合保税区、铁山港区国家进口贸易促进创新示范区等平台优势，相关单位部门积极响应外贸企业发展诉求，畅通沟通渠道，精准实施业务指导，支持企业积极开拓越南市场，抓订单、增效益。 ")]),_c('p',{staticClass:"text"},[_vm._v(" 统计数据显示，2023年北海以一般贸易方式对越南进出口53.1亿元，同比增长66.4%；以加工贸易方式对越南进出口6.1亿元，同比增长10.3%。北海民营企业对越南进出口54.9亿元，同比增长76.7%，占比92.6%，主力军作用突出。北海与越南保持贸易往来的企业数量不断增加、贸易量持续增长，从企业进出口商品种类来看，主要从越南进口木片或木粒、微电机等产品；出口牛马皮革、未漂平纹布等产品。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("海关总署：上半年我国跨境电商进出口1.22万亿元 同比增长10.5%")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("了解中国对农业发展的政策扶持")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("优势互补、同向发力 农业农村部全力推进高水平农业科技自立自强")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("泰国将2024年大米出口预测上调至820万吨")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("4750亿美元！中国跌至第三，不再是美国第一大进口国")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
}]

export { render, staticRenderFns }