import { render, staticRenderFns } from "./ProductIntroduction.vue?vue&type=template&id=468be5f5&scoped=true"
import script from "./ProductIntroduction.vue?vue&type=script&lang=js"
export * from "./ProductIntroduction.vue?vue&type=script&lang=js"
import style0 from "./ProductIntroduction.vue?vue&type=style&index=0&id=468be5f5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468be5f5",
  null
  
)

export default component.exports