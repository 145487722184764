<template>
  <div class="container">
    <div class="bar fx-r fx-bc">
      <div class="menu-container fx-r fx-bc">
        <div class="logo-container fx-r fx-ac fx-bc" @click="showVideo1">
          <img class="logo" src="@/assets/logo.png" alt="" />
        </div>
        <!-- 公司 -->
        <div class="menu-item underline fx-r fx-ac fx-bc">
          <el-dropdown append-to-body>
            <span class="el-dropdown-link" @click="toCompany"> 公司 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/introduction"
                    >品牌介绍</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/about">关于我们</router-link></el-link
                >
              </el-dropdown-item>
              <!-- <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/structure">公司架构</router-link></el-link
                >
              </el-dropdown-item> -->
              <el-dropdown-item class="drop-link">
                <el-link><router-link to="/legal">法务团队</router-link></el-link>
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link><router-link to="/media">媒体宣传</router-link></el-link>
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/investor">投资者关系</router-link></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/contact">联系我们</router-link></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link @click="showVideo" style="color: white !important"
                  >观看视频</el-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-divider direction="vertical"></el-divider>
        <!-- 产品 -->
        <div class="menu-item underline fx-r fx-ac fx-bc">
          <el-dropdown>
            <span class="el-dropdown-link" @click="toProduct"> 产品 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/MeatFrozen">冷冻肉类</router-link></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/FrozenWater"
                    >冷冻海鲜水产</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/FrozenSnack"
                    >冷冻小吃面点</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/FrozenDishes"
                    >冷冻预制菜</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/FrozenConditioning"
                    >冷冻调理品</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/FrozenVegetable"
                    >冷冻果蔬菌类</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/RefrigeratedFruits"
                    >冷藏进口水果</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/HotpotProducts"
                    >火锅产品</router-link
                  ></el-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-divider direction="vertical"></el-divider>
        <!-- 新闻 -->
        <div class="menu-item underline fx-r fx-ac fx-bc">
          <el-dropdown>
            <span class="el-dropdown-link" @click="toNews"> 新闻 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/VideoNews">视频</router-link></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/GraphicNews">图文</router-link></el-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-divider direction="vertical"></el-divider>
        <!-- 行情 -->
        <div class="menu-item underline fx-r fx-ac fx-bc" v-if="0">
          <el-dropdown>
            <span class="el-dropdown-link" @click="Quotation"> 行情 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/Quotation/Futures"
                    >进口期货行情</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/Quotation/Spot"
                    >进口现货行情</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/Quotation/Domestic"
                    >国产行情</router-link
                  ></el-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-divider direction="vertical" v-if="0"></el-divider>
        <!-- 指数 -->
        <div class="menu-item underline fx-r fx-ac fx-bc">
          <el-dropdown>
            <span class="el-dropdown-link" @click="toTrend"> 指数 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/trend">冻品指数</router-link></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/FarmTrend"
                    >农产品指数</router-link
                  ></el-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-divider direction="vertical"></el-divider>
        <!-- 进口数据 -->
        <div class="menu-item underline fx-r fx-ac fx-bc">
          <el-dropdown>
            <span class="el-dropdown-link" @click="ImportData"> 进口数据 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/Importdata/Factoryadmission"
                    >准入工厂查询</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/Importdata/Importquantity"
                    >进口数量统计</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <!-- <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/Importdata/ImportEV"
                    >进口蔬菜水果</router-link
                  ></el-link
                >
              </el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-divider direction="vertical"></el-divider>
        <!-- 销售渠道 -->
        <div class="menu-item underline fx-r fx-ac fx-bc">
          <el-dropdown>
            <span class="el-dropdown-link" @click="toChannels"> 销售网络</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/OnlineChannels"
                    >线上渠道</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/OfflineChannels"
                    >线下渠道</router-link
                  ></el-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-divider direction="vertical"></el-divider>
        <!-- 食品研究院 -->
        <div class="menu-item underline fx-r fx-ac fx-bc">
          <el-dropdown>
            <span class="el-dropdown-link" @click="toFood">食品研究院</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/FoodResearch"
                    >食品成份检测</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/FoodTesting"
                    >食品有毒有害检测</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/PesticideTesting"
                    >农残检测</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/OrganicFood"
                    >有机食品认证</router-link
                  ></el-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-divider direction="vertical"></el-divider>
        <!-- 加入我们 -->
        <div class="menu-item underline fx-r fx-ac fx-bc">
          <el-dropdown>
            <span class="el-dropdown-link" @click="joinUs"> 加入我们 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/JoinUs/Customer"
                    >客户</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/JoinUs/Supplier"
                    >供应商</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/JoinUs/Business"
                    >商家</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/JoinUs/Management"
                    >资方</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/JoinUs/Coldchain"
                    >冷链物流</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/JoinUs/Frozenstorage"
                    >冷冻仓储</router-link
                  ></el-link
                >
              </el-dropdown-item>
              <el-dropdown-item class="drop-link">
                <el-link
                  ><router-link to="/JoinUs/Freightforwarder"
                    >货代报关</router-link
                  ></el-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 选择语言 -->
        <div class="menu-item language fx-r fx-ac fx-bc">
          <el-dropdown>
            <span class="el-dropdown-link nav-language">
              {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                class="drop-link language-link"
                v-for="item in languages"
                :key="item.name"
              >
                <el-link style="color: white" @click="handleChange">{{
                  item.name
                }}</el-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "NavBar",
  data() {
    return {
      language: localStorage.getItem("language") || "English",
      languages: [
        { name: "中文" },
        { name: "English" },
        { name: "Tiếng Việt" },
        { name: "ไทย" },
        { name: "Español" },
      ],
    };
  },
  methods: {
    //食品研究院
    FoodResearch() {
      this.$router.push("/FoodResearch").catch((err) => {});
    },
    //食品研究院
    toFood() {
      this.$router.push("/FoodResearch").catch((err) => {});
    },
    //进口数据
    ImportData() {
      this.$router.push("/Importdata/Factoryadmission").catch((err) => {});
    },
    //行情
    Quotation() {
      this.$router.push("/Quotation/Futures").catch((err) => {});
    },
    //加入我们
    joinUs() {
      this.$router.push("/JoinUs/Customer").catch((err) => {});
    },
    //公司
    toCompany() {
      this.$router.replace("/introduction").catch((err) => {});
    },
    //产品
    toProduct() {
      this.$router.replace("/ProductIntroduction").catch((err) => {});
    },
    //指数
    toTrend() {
      this.$router.replace("/trend").catch((err) => {});
    },
    //销售
    toChannels() {
      this.$router.replace("/OnlineChannels").catch((err) => {});
    },
    //新闻
    toNews() {
      this.$router.replace("/VideoNews").catch((err) => {});
    },
    //视频
    showVideo1() {
      // localStorage.setItem("isVideoShown", "false");
      this.showVideo();
      this.$router.push("/").catch((err) => {});
    },
    //视频
    showVideo() {
      localStorage.setItem("isVideoShown", "false");
      this.$emit("show-video");
      this.$emit("update:isShow", true);
    },
    //改变语言
    handleChange(event: Event) {
      if (event.target) {
        const targetElement = event.target as HTMLElement;
        localStorage.setItem("language", targetElement.textContent!);
        this.$nextTick(() => {
          this.language = localStorage.getItem("language")!;
        });
      }
    },
  },
  mounted() {
    if (localStorage.getItem("language")) {
      this.language = localStorage.getItem("language")!;
    }
  },
});
</script>

<style>
a {
  color: white;
}
/* 覆盖子菜单项悬浮时的背景颜色 */
.el-dropdown-menu__item:hover {
  background-color: rgba(245, 5, 13, 0.5) !important;
}
.el-dropdown-menu {
  background: rgba(18, 4, 4, 0.8) !important;
  border: 0px !important;
  text-align: center !important;
  width: auto !important;
}

.el-popper {
  margin-top: 15px !important;
}
/* 消除小三角 */
.el-popper {
  overflow: auto;
}
</style>

<style scoped>
.underline {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.underline::after {
  content: "";
  position: absolute;
  left: 50%;
  /* 从文字底部中间开始 */
  top: 26px;
  width: 0%;
  height: 2px;
  background-color: #fff;
  transition: width 0.3s ease, left 0.3s ease;
  /* 定义过渡效果 */
  transform: translateX(-50%);
  /* 居中显示 */
}

.underline:hover::after {
  width: 100%;
  /* 鼠标经过时展开下划线 */
  left: 50%;
  /* 动画从文字底部中间开始 */
}

.container {
  width: 100vw;
  max-width: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.bar {
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.logo-container {
  cursor: pointer;
  width: 150px;
  height: 80px;
  margin-right: 10px;
}

.logo {
  width: 80%;
  height: 50%;
  background-size: 100% 100%;
}

.el-divider {
  margin-left: 20px;
  margin-right: 20px;
  height: 20px;
}
.el-dropdown-link {
  color: white;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 3px;
}

.showVideo-btn {
  height: 50px;
  width: 80px;
  color: white;
  cursor: pointer;
  border-radius: 40px;
  position: absolute;
  right: 20px;
  top: 15px;
}

.showVideo-btn:hover {
  background: rgba(0, 0, 0, 1);
}

.menu-container {
  margin-left: 1vw;
}

.menu-item {
  min-width: 30px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 600;

  letter-spacing: 2px;
}

.drop-link {
  color: white;
}

.language {
  width: auto;
  position: absolute;
  right: 30px;
  font-family: "Times New Roman", Times, serif !important;
}

.language-link {  
  font-family: 'Times New Roman', Times, serif !important;  
} 
</style>
