<template>
  <div class="parent-container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px"/>
      <div class="BI1">
      <div class="BI11 fx-h">
        <h1 class="BI111">销售网络</h1>
        <br />
        <h3 class="BI111">掌握市场动态，领先一步</h3>
      </div>
      </div>
      <div class="BI2 fx-r">
      <div class="BI21">
        <ul>
          <li class="fx-r">
            <div class="circle"></div>
            <router-link to="/OnlineChannels" class="tolink"
              ><a class="ul1">线上渠道</a></router-link
            >
          </li>
          <li
            v-for="item in menuItems"
            :key="item.title"
            @click="toggleSubMenu(item)"
          >
          <i v-if="item.isOpen" class="fx-r ul1"
              ><div class="circle"></div>
              {{ item.title
              }}<span style="float: right">&nbsp;<i class="bi bi-chevron-down"></i>&nbsp;</span></i
            >
            <i v-else class="fx-r ul1"
              ><div class="circle"></div>
              {{ item.title
              }}<span style="float: right">&nbsp;<i class="bi bi-chevron-up"></i>&nbsp;</span></i
            >
            <ul v-show="item.isOpen">
              <li
                v-for="subItem in item.subMenus"
                :key="subItem.title"
                class="fx-r ul1 sub-menu-item"
              >
                <div class="circle1"></div>
                <router-link :to="subItem.ht" class="tolink"
                  ><a class="ul2">{{ subItem.title }}</a></router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="BI22 fx-h">
        <div class="BI2211">
          <h2 class="BI2212">大客户</h2>
          <a class="BI2213">Traditional&nbsp;Channels</a>
        </div>
        <div class="BI2221 fx-r">
          <div class="BI2222">
            <span class="BI22221">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;好心动科技有限公司，一家专注于提供高品质冻
            </span>
            <span class="BI22221">
              品的企业，致力于为消费者带来安全、美味、便捷的
            </span>
            <span class="BI22221">食品体验。</span>
          </div>
          <div class="BI01"></div>
        </div>
        <div class="BI22211 fx-r">
          <div class="BI02"></div>
          <div class="BI2223">
            <span class="BI22221">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的品牌理念是“品质至上，服务第一”。公
            </span>
            <span class="BI22221">
              司拥有严格的质量控制体系，从源头抓起，确保每
            </span>
            <span class="BI22221">一件产品都符合严格的质量标准</span>
          </div>
        </div>
        <div class="BI2221 fx-r">
          <div class="BI2222">
            <span class="BI22221">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们与众多优质供应商建立了长期合作关系，精选
            </span>
            <span class="BI22221">
              新鲜、优质的食材，采用先进的冷冻技术，保留食材的
            </span>
            <span class="BI22221">原汁原味和营养成分。</span>
          </div>
          <div class="BI03"></div>
        </div>
        <div class="BI22211 fx-r">
          <div class="BI04"></div>
          <div class="BI2223">
            <span class="BI22221">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的产品种类丰富多样，涵盖了肉类、海
            </span>
            <span class="BI22221">
              鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是
            </span>
            <span class="BI22221">
              餐饮行业，我们都能满足您的需求。我们的产品不
            </span>
            <span class="BI22221">
              仅品质优良，而且价格合理，具有很高的性价比。
            </span>
          </div>
        </div>
        <div class="BI2221 fx-r">
          <div class="BI2222">
            <span class="BI22221">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除了产品品质，我们还注重服务质量。我们拥有
            </span>
            <span class="BI22221">
              一支专业的销售团队和客服团队，能够为客户提供及时、
            </span>
            <span class="BI22221">周到的服务</span>
          </div>
          <div class="BI05"></div>
        </div>
        <div class="BI22211 fx-r">
          <div class="BI06"></div>
          <div class="BI2223">
            <span class="BI22221">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们还提供定制化的解决方案，根据客户的
            </span>
            <span class="BI22221">需求提供个性化的产品和服务。</span>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";
import Video from "@/components/Video.vue";
export default {
  name: "OfflineChannels",
  components: {
    Bar,
    Video,
  },
  methods: {
    toggleSubMenu(item) {
      item.isOpen = !item.isOpen;
    },
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  data() {
    return {
      menuItems: [
        {
          title: "线下渠道",
          subMenus: [
            { title: "传统渠道", ht: "/OfflineChannels" },
            { title: "现代渠道", ht: "/ModernChannels" },
            { title: "餐饮渠道", ht: "/CateringChannels" },
            { title: "专卖店", ht: "/ExclusiveShop" },
            { title: "大客户", ht: "/KeyAccount" },
            // 更多三级菜单...
          ],
          isOpen: true,
        },
      ],
      //显示
      isShow: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
};
</script>

<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.sub-menu-item {
  padding-left: 20%; /* 缩进表示层级关系 */
}
.BI1 {
  height: 500px;
  overflow-x: hidden;
  background-image: url(@/assets/销售网络.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BI111 {
  color: aliceblue;
}
.BI2 {
  width: 100%;
  height: 1500px;
  overflow: hidden;
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: #ffffff;
  display: flex;
  margin-top: 22px;
  margin-right: 10px;
  margin-left: 50px;
}
.circle1 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  /* border: 1px solid red; */
  /* background-color: #9f9192; */
  border: 1px solid red;
  background-color: #ffffff;
  display: flex;
  margin-top: 22px;
  margin-right: 10px;
  margin-left: 50px;
}
ul {
  /* text-align: center; */
  line-height: 3;
  text-decoration: none;
}
.tolink {
  text-decoration: none;
}
.ul1 {
  font-size: 20px;
  text-decoration: none;
  color: black;
}
.ul2 {
  font-size: 15px;
  text-decoration: none;
  color: black;
}
.hover-list li {
  padding: 10px;
  background-color: #f0f0f0; /* 默认的背景色 */
  color: #020202; /* 默认的文本颜色 */
  transition: background-color 0.3s, color 0.3s; /* 平滑过渡效果 */
  text-align: center;
  line-height: 3;
  text-decoration: none;
}
.hover-list li:hover {
  /* 鼠标悬停时的样式 */
  background-color: #db1919;
  color: #fff;
}
.BI21 {
  width: 20vw;
  height: 100%;
  border-right: 1px solid red;
  border-left: 8px solid red;
}
.BI22 {
  width: 78%;
  height: 100%;
}
.BI2211 {
  width: 200px;
  height: 80px;
  border: 1px solid red;
  border-radius: 20px 20px 20px 20px;
  background-color: lightpink;
  margin-top: 50px;
  margin-left: 100px;
  text-align: left 20px;
}
.BI2212 {
  margin-top: 10px;
  margin-left: 20px;
}
.BI2213 {
  margin-top: 10px;
  font-size: 10dm;
  margin-left: 20px;
}
.BI2221 {
  height: 200px;
  width: 90%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
.BI2221::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid red;
}
.BI22211 {
  height: 200px;
  width: 90%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
.BI22211::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-top: 20px solid red;
}
.BI2222 {
  width: 55%;
  height: 100%;
  margin-left: 10px;
  margin-top: 100px;
}
.BI2223 {
  width: 55%;
  height: 100%;
  margin-left: 50px;
  margin-top: 100px;
}
.BI22221 {
  font-size: 16px;
  /* letter-spacing: 4px; */
}
.BI01 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/Frame\ 18.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-left: 50px; */
}
.BI02 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/Rectangle\ 11.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI03 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/Frame\ 1.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI04 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/线上渠道4.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI05 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/线上渠道5.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI06 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/线上渠道6.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
</style>
