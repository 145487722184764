<template>
  <div class="parent-container">
    <Bar />
    <div class="BI1">
      <div class="BI11 fx-h">
        <h1 class="BI111">新闻</h1>
        <br />
        <a class="BI111 h3">掌握市场动态，领先一步</a>
      </div>
    </div>
    <div class="NIT-content fx-r">
      <div class="NIT-content-left fx-h">
        <div class="NIT-title">
          <h1>乳山：跑山鸡“跑出”集体致富新希望</h1>
          <span>2024-07-19</span><span>12：30</span><span>来源 ：闪电新闻</span>
        </div>
        <div class="NIT-model1 fx-h">
          <div class="NIT-model1-photo fx-r">
            <img
              src="@/assets/new9-01.png"
              alt=""
              width="100%"
              height="450px"
            />
          </div>
          <p class="note">（图片来源：互联网）</p>
          <p class="text">
            近年来，乳山市大孤山镇小孤山村充分利用北靠正阳山的地理优势，采取党支部领办合作社的形式大力发展跑山鸡养殖产业，壮大村级集体经济。
          </p>
          <p class="text">
            在小孤山村的跑山鸡养殖基地里，村干部们正在将白菜、方瓜切成小块喂养跑山鸡，这里的鸡除了吃玉米面、蔬菜外，也会自行在山上找寻昆虫、草籽进食。目前，养殖基地里总共养鸡1000多只，其中公鸡700多只，母鸡200多只，品种有芦花鸡、黑珍珠鸡等。作为肉鸡出售的公鸡出栏周期为六个月，这一批公鸡将于12月底陆续开始出栏销售。乳山市大孤山镇小孤山村党支部书记、村委会主任于建国介绍说：“现在多数都是饭店、个人或者是公司有团建活动的时候，他们来预定，公鸡的收益一年能有3万块钱左右。”
          </p>
          <div class="NIT-model1-photo2 fx-r">
            <img
              src="@/assets/new9-02.png"
              alt=""
              width="100%"
              height="350px"
            />
          </div>
          <p class="note">（图片来源：互联网）</p>
          <p class="text">
            母鸡产蛋销售也是合作社增收的一条途径，每年可为村集体增收一万多块钱。除了跑山鸡外，养殖基地里还养了黑猪、鹅、欧洲雁等畜禽。后续村里计划根据市场需求逐渐扩大规模，邀请村民入股，带领村民集体致富。
          </p>
        </div>
      </div>
      <div class="NIT-content-right">
        <div class="NIT-right-title fx-r">
          <div class="recent">近期最热文章</div>
          <div class="imageText fx-r">
            <span class="span-g">图文</span>
            <span class="span-I">|</span>
            <span
              ><router-link to="/VideoNews" class="span-v"
                >视频</router-link
              ></span
            >
          </div>
        </div>
        <div class="recommend fx-h">
          <div class="re-model fx-r" @click="news2">
            <img src="@/assets/new2.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>了解中国对农业发展的政策扶持</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news3">
            <img src="@/assets/new3.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>优势互补、同向发力 农业农村部全力推进高水平农业科技自立自强</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news4">
            <img src="@/assets/new4.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>北海：2023年对越南外贸进出口总值59.3亿元</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news5">
            <img src="@/assets/new5.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>泰国将2024年大米出口预测上调至820万吨</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news8">
            <img src="@/assets/new8.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>比普通牛肉贵一倍的牦牛肉，很多餐厅却抢着买</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="see-more" @click="news">
            查看更多&nbsp;<img src="@/assets/MoreIcon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bar from "@/components/NavBar.vue";

export default {
  name: "NewsImageText8",
  components: {
    Bar,
  },
  methods: {
    news() {
      this.$router.push("/GraphicNews").catch((err) => {});
    },
    news2() {
      this.$router.push("/NewsImageText2").catch((err) => {});
    },
    news3() {
      this.$router.push("/NewsImageText3").catch((err) => {});
    },
    news4() {
      this.$router.push("/NewsImageText4").catch((err) => {});
    },
    news5() {
      this.$router.push("/NewsImageText5").catch((err) => {});
    },
    news8() {
      this.$router.push("/NewsImageText8").catch((err) => {});
    },
  },
};
</script>
<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
}
.BI1 {
  /* width: 100vw; */
  height: 500px;
  overflow-x: hidden;
  background-image: url(@/assets/新闻.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BI111 {
  color: aliceblue;
  font-size: 50px;
  letter-spacing: 30px;
}

.h3 {
  font-size: 30px;
  letter-spacing: 10px;
}

.NIT-content {
  width: 80%;
  /* height: 2000px; */
  margin-left: 10%;
  margin-top: 80px;
  margin-bottom: 50px;
}
.NIT-content-left {
  width: 55%;
  height: 100%;
}
.NIT-content-right {
  width: 35%;
  height: 60%;
  margin-left: 10%;
}
.NIT-title h1 {
  margin-bottom: 5px;
}
.NIT-title span {
  font-size: 14px;
  color: #757575;
  margin-right: 20px;
}
.NIT-model1 {
  width: 100%;
  /* height: 500px; */
  margin-top: 40px;
}
.note {
  font-size: 14px;
  color: #757575;
  text-align: center;
}

.text {
  /* font-weight: 500; */
  font-size: 16px;
  letter-spacing: 5px;
  font-size: 16px;
  text-indent: 2em;
  margin-top: 30px;
}
.NIT-model1-photo2 {
  margin-top: 50px;
}
/* 右边 */
.NIT-right-title {
  width: 100%;
  height: 39px;
  position: relative;
}
.recent {
  width: 100px;
  height: 25px;
  padding: 6px;
  border: 1px solid red;
  border-radius: 5px;
  position: absolute;
}
.imageText {
  width: fit-content;
  height: 25px;
  padding: 6px 10px;
  letter-spacing: 4px;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
}
.imageText a {
  text-decoration: none;
}
.span-g {
  color: red;
}
.span-v {
  color: black;
}
.recommend {
  width: 100%;
  height: 80%;
  margin-top: 30px;
}
.re-model {
  width: 100%;
  height: 120px;
  margin-top: 30px;
}
.re-right {
  width: 48%;
  margin-left: 4%;
  height: 100%;
}

.re-right-bottom {
  /* margin-top: 120px; */
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #757575;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.read {
  left: 0;
  position: absolute;
}
.time {
  position: absolute;
  right: 0;
}
.see-more {
  margin-top: 20px;
  border: 1px solid red;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  padding: 10px;
  letter-spacing: 5px;
}
</style>
