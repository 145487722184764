<template>
  <div class="any">
    <Bar />
    <div class="BI1">
      <div class="BI11 fx-h">
        <h1 class="BI111">新 闻</h1>
        <br />
        <a class="BI111 h3">掌握市场动态，领先一步</a>
      </div>
    </div>
    <div class="BI2 fx-h">
      <div class="B21 fx-h">
        <div class="b1 fx-r">
          <div class="imageText fx-r">
            <span
              ><router-link to="/VideoNews" class="span-v"
                >视频</router-link
              ></span
            >
            <span class="span-I">|</span>
            <span class="span-g">图文</span>
          </div>
          <div class="more">
            <span class="text-m">更多></span>
          </div>
        </div>
        <div class="model1 fx-r">
          <div class="news10 fx-h" @click="news10">
            <div class="news10-s"></div>
            <div class="time1">2024.09.10</div>
            <div class="news10-z">好心冻科技有限公司助力越南慈善事业</div>
            <div class="news10-x">
              爱心捐赠，共筑希望——好心冻科技助力越南儿童教育
            </div>
          </div>
        </div>
        <div class="model3 fx-r">
          <div class="news6 fx-h" @click="news12">
            <div class="news12-s"></div>
            <div class="time1">2024.11.08</div>
            <div class="news1-z">深圳海关助企迎战“双十一”</div>
            <div class="news1-x">
              深圳海关着力打造高效通关“硬基础”，“双十一”活动前夕提前做好业务系统、传输平台、网络等设施巡检、系统优化，业务高峰日安排专人24小时值守，实行定时巡检。
            </div>
          </div>
          <div class="news7 fx-h" @click="news11">
            <div class="news11-s"></div>
            <div class="time1">2024.10.25</div>
            <div class="news1-z">
              农业农村部召开高层次青年人才培养工作座谈会强调
              建强农业农村系统青年人才队伍 为加快建设农业强国提供有力人才支撑
            </div>
            <div class="news1-x">
              韩俊强调，人才是第一资源，青年人才是人才队伍中最富创新活力的生力军，建设农业强国、推进乡村全面振兴，离不开大批有理想、敢担当、肯奋斗的青年人才。
            </div>
          </div>
        </div>
        <div class="model1 fx-r">
          <div class="news1 fx-h" @click="news1">
            <div class="news1-s"></div>
            <div class="time1">2024.07.30</div>
            <div class="news1-z">
              海关总署：上半年我国跨境电商进出口1.22万亿元 同比增长10.5%
            </div>
            <div class="news1-x">
              据海关统计，2023年我国跨境电商进出口较2018年增长了1.2倍；初步测算，今年上半年进出口1.22万亿元，同比增长10.5%
            </div>
          </div>
          <div class="news2 fx-h" @click="news2">
            <div class="news2-s"></div>
            <div class="time1">2024.07.29</div>
            <div class="news1-z">了解中国对农业发展的政策扶持</div>
            <div class="news1-x">
              我国政府已出台了一系列旨在支持农业发展的优惠政策
            </div>
          </div>
        </div>
        <div class="model2 fx-r">
          <div class="news3 fx-h" @click="news3">
            <div class="news3-s"></div>
            <div class="time1">2024.07.28</div>
            <div class="news1-z">
              优势互补、同向发力 农业农村部全力推进高水平农业科技自立自强
            </div>
            <div class="news1-x">2023年我国农业科技进步贡献率达63.2%</div>
          </div>
          <div class="news4 fx-h" @click="news4">
            <div class="news4-s"></div>
            <div class="time1">2024.07.27</div>
            <div class="news1-z">北海：2023年对越南外贸进出口总值59.3亿元</div>
            <div class="news1-x">
              我国政府已出台了一系列旨在支持农业发展的优惠政策
            </div>
          </div>
          <div class="news5 fx-h" @click="news5">
            <div class="news5-s"></div>
            <div class="time1">2024.07.26</div>
            <div class="news1-z">泰国将2024年大米出口预测上调至820万吨</div>
            <div class="news1-x">
              泰国今年预计将出口820万吨大米，高于此前预测的750万吨
            </div>
          </div>
        </div>
        <div class="model3 fx-r">
          <div class="news6 fx-h" @click="news6">
            <div class="news6-s"></div>
            <div class="time1">2024.07.25</div>
            <div class="news1-z">
              4750亿美元！中国跌至第三，不再是美国第一大进口国
            </div>
            <div class="news1-x">墨西哥取代中国成为了美国第一大进口国</div>
          </div>
          <div class="news7 fx-h" @click="news7">
            <div class="news7-s"></div>
            <div class="time1">2024.07.29</div>
            <div class="news1-z">
              1月至3月阿根廷国内牛肉消费同比下降17.6% 出口仍保持增长
            </div>
            <div class="news1-x">
              据阿根廷肉类及衍生品工商协会(CICCRA)最新的一份报告，今年一到三月份全国牛肉消费为49.97万吨(带骨牛肉)，较去年同期下降了17.6%，创下了近30年来的最低水平
            </div>
          </div>
        </div>
        <div class="model4 fx-r">
          <div class="news8 fx-h" @click="news8">
            <div class="news8-s"></div>
            <div class="time1">2024.07.20</div>
            <div class="news1-z">
              比普通牛肉贵一倍的牦牛肉，很多餐厅却抢着买
            </div>
            <div class="news1-x">
              牦牛主要分布在我国的喜马拉雅山脉和青藏高原地区，虽然新疆、广西也有，但相对于青藏高原地区，产量较小。
            </div>
          </div>
          <div class="news9 fx-h" @click="news9">
            <div class="news9-s"></div>
            <div class="time1">2024.07.19</div>
            <div class="news1-z">乳山：跑山鸡“跑出”集体致富新希望</div>
            <div class="news1-x">大力发展跑山鸡养殖产业，壮大村级集体经济</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";
export default {
  name: "GraphicNews",
  components: {
    Bar,
  },
  methods: {
    data() {
      return;
    },
    news1() {
      this.$router.push("/NewsImageText1").catch((err) => {});
    },
    news2() {
      this.$router.push("/NewsImageText2").catch((err) => {});
    },
    news3() {
      this.$router.push("/NewsImageText3").catch((err) => {});
    },
    news4() {
      this.$router.push("/NewsImageText4").catch((err) => {});
    },
    news5() {
      this.$router.push("/NewsImageText5").catch((err) => {});
    },
    news6() {
      this.$router.push("/NewsImageText6").catch((err) => {});
    },
    news7() {
      this.$router.push("/NewsImageText7").catch((err) => {});
    },
    news8() {
      this.$router.push("/NewsImageText8").catch((err) => {});
    },
    news9() {
      this.$router.push("/NewsImageText9").catch((err) => {});
    },
    news10() {
      this.$router.push("/NewsImageText10").catch((err) => {});
    },
    news11() {
      this.$router.push("/NewsImageText11").catch((err) => {});
    },
    news12() {
      this.$router.push("/NewsImageText12").catch((err) => {});
    },
  },
};
</script>

<style scoped>
.BI1 {
  /* width: 100vw; */
  height: 500px;
  overflow-x: hidden;
  background-image: url(@/assets/新闻.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BI111 {
  color: aliceblue;
}

h1 {
  font-size: 50px;
}
.h3 {
  font-size: 17px;
}
.BI2 {
  width: 90%;
  margin-left: 100px;
  margin-bottom: 50px;
}
.B21 {
  width: 100%;
}
.b1 {
  width: 100%;
  height: 39px;
  margin-top: 100px;
  position: relative;
}
.imageText {
  width: fit-content;
  height: 25px;
  padding: 6px 10px;
  letter-spacing: 4px;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  left: 5%;
  position: absolute;
}
.imageText a {
  text-decoration: none;
}
.span-g {
  color: red;
}
.span-v {
  color: black;
}
.text-m {
  width: 80px;
  height: 25px;
  color: rgb(75, 73, 73);
  align-items: center;
  display: inline-block;
  border-radius: 10px;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
}
.more {
  text-align: center;
  height: 25px;
  right: 6%;
  position: absolute;
}

.model1 {
  width: 100%;
  height: 600px;
  margin-top: 60px;
}
.news10 {
  width: 89%;
  height: 100%;
  margin-left: 5%;
  /* margin-right: 2%; */
  margin-bottom: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.news10-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/new10.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.news10-z {
  width: 100%;
  /* height: 10%; */
  /* margin-left: 8%; */
  text-align: center;
  margin-top: 20px;
  font-size: 30px;
  font-weight: 600;
}
.news10-x {
  width: 100%;
  /* height: 10%; */
  /* margin-left: 8%; */
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: rgb(172, 167, 167);
}
.news11-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/news11.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.news12-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/news12.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.news1 {
  width: 59%;
  height: 100%;
  margin-left: 5%;
  margin-right: 2%;
  margin-bottom: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.news1-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/news1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.time1 {
  width: 90px;
  margin-top: 20px;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: red;
  color: red;
  padding: 5px 0;
}
.news1-z {
  width: 80%;
  /* height: 10%; */
  margin-left: 8%;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
}
.news1-x {
  width: 80%;
  /* height: 10%; */
  margin-left: 8%;
  margin-top: 10px;
  margin-bottom: 5px;
  color: rgb(172, 167, 167);
}
.news2 {
  width: 28%;
  height: 100%;
  margin-bottom: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.news2-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/new2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.model2 {
  width: 100%;
  height: 600px;
  margin-top: 60px;
}
.b3 {
  width: 100%;
  height: 45%;
  margin-top: 2%;
  margin-bottom: 2%;
}
.news3 {
  width: 28%;
  margin-left: 5%;
  margin-right: 2.5%;
  margin-bottom: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.news3-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/new3.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.news4 {
  width: 28%;
  height: 100%;
  margin-right: 2.5%;
  margin-bottom: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.news4-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/new4.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.news5 {
  width: 28%;
  height: 100%;
  margin-bottom: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.news5-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/new5.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.model3 {
  width: 100%;
  height: 600px;
  margin-top: 60px;
}
.news7 {
  width: 59%;
  height: 100%;
  margin-bottom: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.news7-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/new7.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.news6 {
  width: 28%;
  height: 100%;
  margin-left: 5%;
  margin-right: 2%;
  margin-bottom: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.news6-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/new6.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.model4 {
  width: 100%;
  height: 600px;
  margin-top: 60px;
}
.news8 {
  width: 59%;
  height: 100%;
  margin-left: 5%;
  margin-right: 2%;
  margin-bottom: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.news8-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/new8.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.news9 {
  width: 28%;
  height: 100%;
  margin-bottom: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.news9-s {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/new9.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
