var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parent-container"},[_c('Video',{attrs:{"isShow":_vm.isShow},on:{"update:isShow":function($event){_vm.isShow=$event},"update:is-show":function($event){_vm.isShow=$event}}}),_c('div',{staticClass:"main"},[_c('Bar',{staticStyle:{"position":"fixed","top":"0px"},on:{"show-video":_vm.handleShowVideo}}),_vm._m(0),_c('div',{staticClass:"BI2 fx-r"},[_c('div',{staticClass:"BI21 sidebar"},[_c('ul',[_c('li',{on:{"click":function($event){return _vm.selectContent('食品成份检测')}}},[_vm._m(1)]),_c('li',{on:{"click":function($event){return _vm.selectContent('食品有毒有害检测')}}},[_vm._m(2)]),_c('li',{on:{"click":function($event){return _vm.selectContent('农残检测')}}},[_vm._m(3)]),_c('li',{on:{"click":function($event){return _vm.selectContent('有机食品认证')}}},[_vm._m(4)])])]),_c('div',{staticClass:"BI22 content-area"},[(_vm.selectedContent === '食品成份检测')?_c('div',[_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)]):(_vm.selectedContent === '食品有毒有害检测')?_c('div',[_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18)]):(_vm.selectedContent === '农残检测')?_c('div',[_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25)]):(_vm.selectedContent === '有机食品认证')?_c('div',[_vm._m(26),_vm._m(27),_vm._m(28),_vm._m(29),_vm._m(30),_vm._m(31),_vm._m(32)]):_vm._e()])])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI1"},[_c('div',{staticClass:"BI11 fx-h"},[_c('h1',{staticClass:"BI111"},[_vm._v("食品研究院")]),_c('br'),_c('h3',{staticClass:"BI111"},[_vm._v("掌握市场动态，领先一步")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fx-r"},[_c('div',{staticClass:"circle"}),_c('span',[_vm._v("食品成份检测")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fx-r"},[_c('div',{staticClass:"circle"}),_c('span',[_vm._v("食品有毒有害检测")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fx-r"},[_c('div',{staticClass:"circle"}),_c('span',[_vm._v("农残检测")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fx-r"},[_c('div',{staticClass:"circle"}),_c('span',[_vm._v("有机食品认证")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2211"},[_c('h2',{staticClass:"BI2212"},[_vm._v("食品成份检测")]),_c('a',{staticClass:"BI2213"},[_vm._v("Traditional Channels")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       好心动科技有限公司，一家专注于提供高品质冻 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 品的企业，致力于为消费者带来安全、美味、便捷的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("食品体验。")])]),_c('div',{staticClass:"BI01"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI02"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们的品牌理念是“品质至上，服务第一”。公 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 司拥有严格的质量控制体系，从源头抓起，确保每 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("一件产品都符合严格的质量标准")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们与众多优质供应商建立了长期合作关系，精选 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 新鲜、优质的食材，采用先进的冷冻技术，保留食材的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("原汁原味和营养成分。")])]),_c('div',{staticClass:"BI03"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI04"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们的产品种类丰富多样，涵盖了肉类、海 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 餐饮行业，我们都能满足您的需求。我们的产品不 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 仅品质优良，而且价格合理，具有很高的性价比。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       除了产品品质，我们还注重服务质量。我们拥有 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 一支专业的销售团队和客服团队，能够为客户提供及时、 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("周到的服务")])]),_c('div',{staticClass:"BI05"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI06"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们还提供定制化的解决方案，根据客户的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("需求提供个性化的产品和服务。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2211"},[_c('h2',{staticClass:"BI2212"},[_vm._v("食品有毒有害检测")]),_c('a',{staticClass:"BI2213"},[_vm._v("Traditional Channels")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       好心动科技有限公司，一家专注于提供高品质冻 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 品的企业，致力于为消费者带来安全、美味、便捷的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("食品体验。")])]),_c('div',{staticClass:"BI01"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI02"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们的品牌理念是“品质至上，服务第一”。公 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 司拥有严格的质量控制体系，从源头抓起，确保每 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("一件产品都符合严格的质量标准")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们与众多优质供应商建立了长期合作关系，精选 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 新鲜、优质的食材，采用先进的冷冻技术，保留食材的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("原汁原味和营养成分。")])]),_c('div',{staticClass:"BI03"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI04"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们的产品种类丰富多样，涵盖了肉类、海 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 餐饮行业，我们都能满足您的需求。我们的产品不 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 仅品质优良，而且价格合理，具有很高的性价比。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       除了产品品质，我们还注重服务质量。我们拥有 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 一支专业的销售团队和客服团队，能够为客户提供及时、 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("周到的服务")])]),_c('div',{staticClass:"BI05"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI06"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们还提供定制化的解决方案，根据客户的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("需求提供个性化的产品和服务。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2211"},[_c('h2',{staticClass:"BI2212"},[_vm._v("农残检测")]),_c('a',{staticClass:"BI2213"},[_vm._v("Traditional Channels")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       好心动科技有限公司，一家专注于提供高品质冻 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 品的企业，致力于为消费者带来安全、美味、便捷的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("食品体验。")])]),_c('div',{staticClass:"BI01"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI02"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们的品牌理念是“品质至上，服务第一”。公 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 司拥有严格的质量控制体系，从源头抓起，确保每 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("一件产品都符合严格的质量标准")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们与众多优质供应商建立了长期合作关系，精选 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 新鲜、优质的食材，采用先进的冷冻技术，保留食材的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("原汁原味和营养成分。")])]),_c('div',{staticClass:"BI03"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI04"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们的产品种类丰富多样，涵盖了肉类、海 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 餐饮行业，我们都能满足您的需求。我们的产品不 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 仅品质优良，而且价格合理，具有很高的性价比。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       除了产品品质，我们还注重服务质量。我们拥有 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 一支专业的销售团队和客服团队，能够为客户提供及时、 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("周到的服务")])]),_c('div',{staticClass:"BI05"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI06"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们还提供定制化的解决方案，根据客户的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("需求提供个性化的产品和服务。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2211"},[_c('h2',{staticClass:"BI2212"},[_vm._v("有机食品认证")]),_c('a',{staticClass:"BI2213"},[_vm._v("Traditional Channels")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       好心动科技有限公司，一家专注于提供高品质冻 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 品的企业，致力于为消费者带来安全、美味、便捷的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("食品体验。")])]),_c('div',{staticClass:"BI01"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI02"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们的品牌理念是“品质至上，服务第一”。公 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 司拥有严格的质量控制体系，从源头抓起，确保每 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("一件产品都符合严格的质量标准")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们与众多优质供应商建立了长期合作关系，精选 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 新鲜、优质的食材，采用先进的冷冻技术，保留食材的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("原汁原味和营养成分。")])]),_c('div',{staticClass:"BI03"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI04"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们的产品种类丰富多样，涵盖了肉类、海 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 餐饮行业，我们都能满足您的需求。我们的产品不 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 仅品质优良，而且价格合理，具有很高的性价比。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI2221 fx-r"},[_c('div',{staticClass:"BI2222"},[_c('span',{staticClass:"BI22221"},[_vm._v("       除了产品品质，我们还注重服务质量。我们拥有 ")]),_c('span',{staticClass:"BI22221"},[_vm._v(" 一支专业的销售团队和客服团队，能够为客户提供及时、 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("周到的服务")])]),_c('div',{staticClass:"BI05"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI22211 fx-r"},[_c('div',{staticClass:"BI06"}),_c('div',{staticClass:"BI2223"},[_c('span',{staticClass:"BI22221"},[_vm._v("       我们还提供定制化的解决方案，根据客户的 ")]),_c('span',{staticClass:"BI22221"},[_vm._v("需求提供个性化的产品和服务。")])])])
}]

export { render, staticRenderFns }