var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parent-container"},[_c('Bar'),_vm._m(0),_c('div',{staticClass:"NIT-content fx-r"},[_vm._m(1),_c('div',{staticClass:"NIT-content-right"},[_c('div',{staticClass:"NIT-right-title fx-r"},[_c('div',{staticClass:"recent"},[_vm._v("近期最热文章")]),_c('div',{staticClass:"imageText fx-r"},[_c('span',{staticClass:"span-g"},[_vm._v("图文")]),_c('span',{staticClass:"span-I"},[_vm._v("|")]),_c('span',[_c('router-link',{staticClass:"span-v",attrs:{"to":"/VideoNews"}},[_vm._v("视频")])],1)])]),_c('div',{staticClass:"recommend fx-h"},[_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news2}},[_c('img',{attrs:{"src":require("@/assets/new2.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(2)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news7}},[_c('img',{attrs:{"src":require("@/assets/new7.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(3)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news4}},[_c('img',{attrs:{"src":require("@/assets/new4.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(4)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news5}},[_c('img',{attrs:{"src":require("@/assets/new5.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(5)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news6}},[_c('img',{attrs:{"src":require("@/assets/new6.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(6)]),_c('div',{staticClass:"see-more",on:{"click":_vm.news}},[_vm._v(" 查看更多 "),_c('img',{attrs:{"src":require("@/assets/MoreIcon.png"),"alt":""}})])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI1"},[_c('div',{staticClass:"BI11 fx-h"},[_c('h1',{staticClass:"BI111"},[_vm._v("新闻")]),_c('br'),_c('a',{staticClass:"BI111 h3"},[_vm._v("掌握市场动态，领先一步")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NIT-content-left fx-h"},[_c('div',{staticClass:"NIT-title"},[_c('h1',[_vm._v("好心冻科技有限公司助力越南慈善事业")]),_c('span',[_vm._v("2024.09.10")]),_c('span',[_vm._v("16：00")]),_c('span',[_vm._v("来源 ：公司内网")])]),_c('div',{staticClass:"NIT-model1 fx-h"},[_c('div',{staticClass:"NIT-model1-photo fx-r"},[_c('img',{attrs:{"src":require("@/assets/new10-01.png"),"alt":"","width":"100%","height":"400px"}})]),_c('p',{staticClass:"note"},[_vm._v("（图片来源：互联网）")]),_c('p',{staticClass:"text"},[_vm._v(" 2024年9月10日，好心冻科技有限公司向越南知名慈善团体Saigon Children's Charity捐赠1031万越南盾，以支持其在越南的弱势儿童教育项目。 ")]),_c('div',{staticClass:"NIT-model1-photo2 fx-r fx-ac"},[_c('img',{attrs:{"src":require("@/assets/new10-02.png"),"alt":"","width":"70%","height":"800px"}})]),_c('p',{staticClass:"note"},[_vm._v("（图片来源：互联网）")]),_c('p',{staticClass:"text"},[_vm._v(" Saigon Children's Charity（西贡儿童慈善机构）成立于1992年，致力于为越南的贫困、残疾及偏远地区儿童提供教育机会和帮助，通过设立奖学金、建设教育设施和提供职业培训等方式，给予这些儿童更好的学习环境和发展机会。 ")]),_c('p',{staticClass:"text"},[_vm._v(" 此次捐赠彰显了好心冻科技有限公司对社会责任的积极承担和对教育事业的深切关注。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("了解中国对农业发展的政策扶持")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("1月至3月阿根廷国内牛肉消费同比下降17.6% 出口仍保持增长")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("北海：2023年对越南外贸进出口总值59.3亿元")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("泰国将2024年大米出口预测上调至820万吨")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("4750亿美元！中国跌至第三，不再是美国第一大进口国")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
}]

export { render, staticRenderFns }