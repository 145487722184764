<template>
  <div class="any">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <div class="BI1">
        <div class="BI11 fx-h">
          <h1 class="BI111">产 品</h1>
          <br />
          <h3 class="BI111">冷冻锁住新鲜，美味尽在口中</h3>
        </div>
      </div>
      <div class="BI2 fx-r">
        <div class="BI22 content-area">
          <!-- 根据 selectedContent 显示内容 -->
            <div class="right-title">
              <div class="right-title-border">
                <span class="text-b">冷冻预制菜</span>
              </div>
            </div>
            <div class="right-content fx-h">
              <!-- 每一行 -->
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgDishes-1"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>奥尔良翅中</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgDishes-2"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>番茄牛肉</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgDishes-3"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>风味牛肉</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgDishes-4"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸡米花</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgDishes-5"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸡排</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgDishes-6"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>麻辣虾尾</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgDishes-7"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊肉串</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgDishes-8"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>肉串</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgDishes-9"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>肉末茄子</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgDishes-10"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>蒜香排骨</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgDishes-11"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>咸烧白</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgDishes-12"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>小郡肝</span>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 50px"></div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";
import Video from "@/components/Video.vue";
import { ref } from "vue";
export default {
  name: "FrozenWater",
  components: {
    Video,
    Bar,
  },
  data() {
    return {
      //显示
      isShow: false,
    };
  },
  methods: {
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
};
</script>

<style scoped>
.any {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.BI1 {
  height: 500px;
  overflow-x: hidden;
  background-image: url("@/assets/产品.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BI11 h1{
  font-size: 50px;
}
.BI11 h3{
  font-size: 17px;
}
.BI111 {
  color: white;
  font-weight: 500px;
}
.content-area {
  width: 78%;
  height: 100%;
  margin-left: 10px;
  padding: 0px;
}
.BI2 {
  width: 100%;
  /* height: 100vh; */
}

.BI22 {
  width: 100%;
  height: 100%;
}
.right-title {
  width: 100%;
  height: 30%;
}
.right-title-border {
  width: 25%;
  height: 50px;
  margin-top: 2%;
  margin-bottom: 1%;
  margin-left: 38%;
  border: 1px solid rgb(181, 180, 180);
  border-radius: 20px 20px 20px 20px;
  display: grid;
  place-items: center;
}
.right-content {
  width: 90%;
  height: 65%;
  margin-left: 6%;
}
.right-content-row {
  width: 100%;
  height: 30%;
  margin-top: 2%;
}
.right-content-row-one {
  width: 20%;
  height: 300px;
  margin-left: 6%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-two {
  width: 20%;
  height: 300px;
  margin-left: 2%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-three {
  width: 20%;
  height: 300px;
  margin-left: 2%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-four {
  width: 20%;
  height: 300px;
  margin-left: 2%;
  margin-right: 6%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-top {
  width: 94%;
  height: 85%;
  margin-left: 3%;
  margin-right: 3%;
}
.right-content-row-bottom {
  width: 90%;
  height: 13%;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
}
.text-b {
  font-size: 30px;
  letter-spacing: 10px;
}
.text-s {
  font-size: 16px;
  letter-spacing: 5px;
  text-align: center;
}
.imgDishes-1 {
  background-image: url(@/assets/dishes01.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgDishes-2 {
  background-image: url(@/assets/dishes02.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgDishes-3 {
  background-image: url(@/assets/dishes03.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgDishes-4 {
  background-image: url(@/assets/dishes04.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgDishes-5 {
  background-image: url(@/assets/dishes05.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgDishes-6 {
  background-image: url(@/assets/dishes06.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgDishes-7 {
  background-image: url(@/assets/dishes07.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgDishes-8 {
  background-image: url(@/assets/dishes08.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgDishes-9 {
  background-image: url(@/assets/dishes09.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgDishes-10 {
  background-image: url(@/assets/dishes10.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgDishes-11 {
  background-image: url(@/assets/dishes11.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgDishes-12 {
  background-image: url(@/assets/dishes12.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

</style>
