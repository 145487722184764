<template>
  <div class="parent-container">
    <Bar />
    <div class="NIT-top-photo fx-h">
      <div class="NIT-top-text">
        <h1 class="NIT-text">公司信息</h1>
      </div>
      <img src="@/assets/Vector.png" alt="" width="40px" height="auto" />
    </div>
    <div class="NIT-content fx-r">
      <div class="NIT-content-left fx-h">
        <div class="NIT-title">
          <h1>好心冻科技有限公司正式成立</h1>
          <span>2024-07-18</span><span>12：30</span><span>好心冻网</span>
        </div>
        <div class="NIT-model1 fx-h">
          <div class="NIT-model1-title fx-r">
            <div class="NIT-model1-number">01</div>
            <div class="NIT-model1-article">公司概述</div>
          </div>
          <p>
            好心冻科技有限公司（以下简称“好心冻”），成立于2024年
            ，是一家专注于冷冻食品研发、生产、销售及服务的高新技术企业。公司位于我国某省某市高新技术产业开发区，占地面积约10万平方米，拥有现代化的生产车间、研发中心和物流配送系统。
          </p>
          <div class="NIT-model1-photo fx-r">
            <img src="@/assets/NIT-logo.png" alt="" width="45%" height="auto"/>
            <img src="@/assets/NIT-01.png" alt="" width="45%" height="auto" class="photo2"/>
          </div>
        </div>
        <div class="NIT-model1 fx-h">
          <div class="NIT-model1-title fx-r">
            <div class="NIT-model1-number">01</div>
            <div class="NIT-model1-article">公司概述</div>
          </div>
          <p>
            好心冻科技有限公司（以下简称“好心冻”），成立于2024年
            ，是一家专注于冷冻食品研发、生产、销售及服务的高新技术企业。公司位于我国某省某市高新技术产业开发区，占地面积约10万平方米，拥有现代化的生产车间、研发中心和物流配送系统。
          </p>
          <div class="NIT-model1-photo fx-r">
            <img src="@/assets/NIT-logo.png" alt="" width="45%" height="auto"/>
            <img src="@/assets/NIT-01.png" alt="" width="45%" height="auto" class="photo2"/>
          </div>
        </div>
        <div class="NIT-model1 fx-h">
          <div class="NIT-model1-title fx-r">
            <div class="NIT-model1-number">01</div>
            <div class="NIT-model1-article">公司概述</div>
          </div>
          <p>
            好心冻科技有限公司（以下简称“好心冻”），成立于2024年
            ，是一家专注于冷冻食品研发、生产、销售及服务的高新技术企业。公司位于我国某省某市高新技术产业开发区，占地面积约10万平方米，拥有现代化的生产车间、研发中心和物流配送系统。
          </p>
          <div class="NIT-model1-photo fx-r">
            <img src="@/assets/NIT-logo.png" alt="" width="45%" height="auto"/>
            <img src="@/assets/NIT-01.png" alt="" width="45%" height="auto" class="photo2"/>
          </div>
        </div>
        <div class="NIT-model1 fx-h">
          <div class="NIT-model1-title fx-r">
            <div class="NIT-model1-number">01</div>
            <div class="NIT-model1-article">公司概述</div>
          </div>
          <p>
            好心冻科技有限公司（以下简称“好心冻”），成立于2024年
            ，是一家专注于冷冻食品研发、生产、销售及服务的高新技术企业。公司位于我国某省某市高新技术产业开发区，占地面积约10万平方米，拥有现代化的生产车间、研发中心和物流配送系统。
          </p>
          <div class="NIT-model1-photo fx-r">
            <img src="@/assets/NIT-logo.png" alt="" width="45%" height="auto"/>
            <img src="@/assets/NIT-01.png" alt="" width="45%" height="auto" class="photo2"/>
          </div>
        </div>
      </div>
      <div class="NIT-content-right">
        <div class="NIT-right-title fx-r">
            <div class="recent">近期最热文章</div>
            <div class="imageText fx-r">
                <span>图文</span>
                <span>|</span>
                <span>视频</span>
            </div>
        </div>
        <div class="recommend fx-h">
            <div class="re-model fx-r">
                <img src="@/assets/right-photo.png" alt="" width="60%" height="100%"/>
                <div class="re-right fx-h">
                    <p>好心冻科技有限公司秉承“品质第一，客户至上”的经营理念</p>
                    <div class="re-right-bottom"><span class="read">20次阅读</span><span>10分钟前</span></div>
                </div>
            </div>
            <div class="re-model fx-r">
                <img src="@/assets/right-photo.png" alt="" width="60%" height="100%"/>
                <div class="re-right fx-h">
                    <p>好心冻科技有限公司秉承“品质第一，客户至上”的经营理念</p>
                    <div class="re-right-bottom"><span class="read">20次阅读</span><span>10分钟前</span></div>
                </div>
            </div>
            <div class="re-model fx-r">
                <img src="@/assets/right-photo.png" alt="" width="60%" height="100%"/>
                <div class="re-right fx-h">
                    <p>好心冻科技有限公司秉承“品质第一，客户至上”的经营理念</p>
                    <div class="re-right-bottom"><span class="read">20次阅读</span><span>10分钟前</span></div>
                </div>
            </div>
            <div class="re-model fx-r">
                <img src="@/assets/right-photo.png" alt="" width="60%" height="100%"/>
                <div class="re-right fx-h">
                    <p>好心冻科技有限公司秉承“品质第一，客户至上”的经营理念</p>
                    <div class="re-right-bottom"><span class="read">20次阅读</span><span>10分钟前</span></div>
                </div>
            </div>
            <div class="re-model fx-r">
                <img src="@/assets/right-photo.png" alt="" width="60%" height="100%"/>
                <div class="re-right fx-h">
                    <p>好心冻科技有限公司秉承“品质第一，客户至上”的经营理念</p>
                    <div class="re-right-bottom"><span class="read">20次阅读</span><span>10分钟前</span></div>
                </div>
            </div>
            <div class="see-more">查看更多&nbsp;<img src="@/assets/MoreIcon.png" alt=""/></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bar from "@/components/NavBar.vue";

export default {
  name: "NewsImageText",
  components: {
    Bar,
  },
};
</script>
<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
}
/* 头部图片 */
.NIT-top-photo {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-image: url(@/assets/NewsImageText.png);
  background-size: cover;
  display: block;
  position: relative;
}
/* 图片上的文字 */
.NIT-top-text {
  width: 100%;
  height: 92%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.NIT-text {
  color: aliceblue;
}
.NIT-top-photo img {
  left: 50%;
  position: absolute;
}
.NIT-content {
  width: 80%;
  height: 2000px;
  margin-left: 10%;
  margin-top: 80px;
}
.NIT-content-left {
  width: 60%;
  height: 100%;
}
.NIT-content-right {
  width: 40%;
  height: 60%;
}
.NIT-title h1 {
  margin-bottom: 5px;
}
.NIT-title span {
  font-size: 14px;
  color: #757575;
  margin-right: 20px;
}
.NIT-model1 {
  width: 90%;
  height: 400px;
  margin-top: 40px;
}
.NIT-model1-title {
  font-size: 25px;
}
.NIT-model1-number {
  color: red;
  margin-right: 15px;
}
.NIT-model1 p {
  font-weight: 500;
  font-family: "黑体";
  font-size: 17px;
  text-indent: 2em;
  margin-top: 30px;
}
.NIT-model1-photo{
    margin-top: 22px;
}
.photo2{
    margin-left: 10%;
}
.recent{
    padding: 4px;
    border: 1px solid red;
    border-radius: 5px;
}
.imageText{
    /* width: 60px;
    height: 30px; */
    padding: 5px 5px;
    border: 1px solid white;
    border-radius: 5px;
    margin-left: 300px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
.imageText a{
    color: black;
    text-decoration: none;
}
.recommend{
    width: 100%;
    height: 80%;
    margin-top: 30px;
}
.re-model{
    width: 100%;
    height: 200px;
    margin-top: 30px;
}
.re-right{
    width: 37%;
    margin-left: 3%;
    height: 100%;
}

.re-right-bottom{
    margin-top: 120px;
    font-size: 14px;
    color: #757575;
}
.read{
    margin-right: 62px;
}
.see-more{
    margin-top: 20px;
    border: 1px solid red;
    border-radius: 5px;
    text-align: center;
    font-size: 18px;
    padding: 10px;
    letter-spacing: 5px;
}
</style>
