<template>
  <div id="video" class="container fx-r fx-ac fx-bc" :class="isShow ? 'video-show' : 'video-hide'">
    <button class="video-btn fx-r fx-ac fx-bc" @click="toggleVideo">关闭视频</button>
    <button class="video-btn fx-r fx-ac fx-bc" style="margin-right: 100px" @click="toggleMusic">
      声音开关
    </button>
    <!-- 自动播放 带控制器 结束自动跳转 -->
    <video
      ref="videoPlayer"
      width="100%"
      height="100%"
      muted
      autoplay
      controls
      @ended="handleVideoEnded"
      @contextmenu="disableContextMenu">
      <source src="http://www.haoxindong.com/media/video2.mp4" type="video/mp4" />
    </video>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Video",
  data() {
    return {};
  },
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  methods: {
    toggleVideo() {
      this.$emit("update:isShow", false);
      localStorage.setItem("isVideoShown", "true");
    },
    toggleMusic() {
      const video = this.$refs.videoPlayer as HTMLVideoElement;
      if (video) {
        if (video.muted) {
          video.muted = false;
        } else {
          video.muted = true;
        }
      }
    },
    stopVideo() {
      const video = this.$refs.videoPlayer as HTMLVideoElement;
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    },
    playVideo() {
      const video = this.$refs.videoPlayer as HTMLVideoElement;
      if (video) {
        video.currentTime = 0;
        setTimeout(() => {
          video.play();
        }, 1000);
      }
    },
    handleVideoEnded() {
      setTimeout(() => {
        this.$emit("update:isShow", false);
        localStorage.setItem("isVideoShown", "true");
      }, 200);
    },
    resetVideo() {
      const video = this.$refs.videoPlayer;
      if (video && video instanceof HTMLVideoElement) {
        video.currentTime = 0;
      }
      localStorage.setItem("isVideoShown", "true");
    },
    disableContextMenu(event: Event) {
      event.preventDefault();
    },
  },
  mounted() {
    document.body.style.overflow = "hidden";
    const video = this.$refs.videoPlayer as HTMLVideoElement;
    if (video && localStorage.getItem("isVideoShown") === "false") {
      video.addEventListener("ratechange", () => {
        if (video.playbackRate !== 1) {
          video.playbackRate = 1;
          localStorage.setItem("isVideoShown", "true");
          setTimeout(() => {
            this.playVideo();
          }, 1000);
        }
      });
    }
  },
  beforeDestroy() {
    this.resetVideo();
  },
  watch: {
    isShow(newVal) {
      if (newVal && localStorage.getItem("isVideoShown") === "false") {
        document.body.style.overflow = "hidden"; // 隐藏滚动条
        this.playVideo();
      } else {
        document.body.style.overflow = ""; // 恢复滚动条
        this.stopVideo();
        this.$emit("update:isShow", false);
      }
    },
  },
});
</script>

<style scoped lang="less">
.container {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: white;
}

.video-btn {
  z-index: 997;
  height: 60px;
  width: 80px;
  // background: rgba(0, 0, 0, 0.3);
  background-color: red;
  color: white;
  font-size: 14px;
  border: 0px;
  border-radius: 50px;
  position: absolute;
  top: 100px;
  right: 20px;
  cursor: pointer;
}

.video-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}

.video-show {
  opacity: 1;
  z-index: 10;
  transition: opacity 1s ease;
}

.video-hide {
  opacity: 0;
  z-index: -1;
  transition: opacity 1s ease;
}

.container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // justify-self: auto;
}
</style>
