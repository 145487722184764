<template>
  <!-- 这是上半部分主体 -->
  <div class="container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <!-- 图片部分 -->
      <div class="fx-h">
        <div class="header">
          <img
            src="@/assets/加入我们.jpg"
            alt="1"
            width="100%"
            height="100%"
            class="background-image" />
          <h1 class="text-overlay">加 入 我 们</h1>
          <br />
          <h3 class="text-overlay2">掌握市场动态，领先一步。</h3>
        </div>
      </div>
      <!-- 这是下半部分 -->
      <div class="row-b1">
        <div class="wp">
          <!-- 左边部分 -->
          <div class="col-l">
            <div class="work_list clearfix">
              <div class="tit" @click="selectContent('客户')"
                :class="{ 'active': selectedContent === '客户' }" >
                <div class="circle"></div>
                <div>客户</div>
              </div>
              <div class="tit" @click="selectContent('供应商')"
              :class="{ 'active': selectedContent === '供应商' }"> 
                <div class="circle"></div>
                <div>供应商</div>
              </div>

              <div class="tit" @click="selectContent('商家')"
              :class="{ 'active': selectedContent === '商家' }">
                <div class="circle"></div>
                <div>商家</div>
              </div>

              <div class="tit" @click="selectContent('资方')"
              :class="{ 'active': selectedContent === '资方' }">
                <div class="circle"></div>
                <div>资方</div>
              </div>

              <div class="tit" @click="selectContent('冷链物流')"
              :class="{ 'active': selectedContent === '冷链物流' }">
                <div class="circle"></div>
                <div>冷链物流</div>
              </div>

              <div class="tit" @click="selectContent('冷冻仓储')"
              :class="{ 'active': selectedContent === '冷冻仓储' }">
                <div class="circle"></div>
                <div>冷冻仓储</div>
              </div>

              <div class="tit" @click="selectContent('货代报关')"
              :class="{ 'active': selectedContent === '货代报关' }">
                <div class="circle"></div>
                <div>货代报关</div>
              </div>
            </div>
          </div>
          <!-- 右边部分 -->
          <div class="col-r">
            <div class="g-titIns1">好&nbsp;&nbsp;心&nbsp;&nbsp;冻</div>
            <div class="m-chanpin">
              <!-- 添加信息 -->
               <div  v-if="selectedContent === '加入我们'">
                <div class="information" >
                冻&nbsp;&nbsp;品&nbsp;&nbsp;领&nbsp;&nbsp;
                域&nbsp;&nbsp;未&nbsp;&nbsp;来&nbsp;&nbsp;
                全&nbsp;&nbsp;球&nbsp;&nbsp;独&nbsp;&nbsp;角&nbsp;&nbsp;兽
              </div>
              <div class="introduce" style="font-size: 20px">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                在浩瀚的美食宇宙中，有这样一群匠心独运的探索者，他们以自然的馈赠为起点，以科技的智慧为翼，在冻品行业的广阔天地间，
                编织着一份份鲜美与健康的传奇——欢迎走进<span style="color: #e60012">好心冻</span>
                ，一个让味蕾穿越四季、 尽享全球美味的冻品王国。<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                在这里，每一份冻品都是对品质的极致追求。我们精心挑选全球优质食材，从深海的鲜美鱼虾到高山的纯净果蔬，
                从草原的肥美牛羊到田野的鲜嫩时蔬，每一种原料都承载着大自然的精华与纯净。通过先进的急速冷冻技术，
                我们锁住食材最初的营养与风味，让每一口都能品尝到仿佛刚采摘、刚捕捞般的鲜活滋味。<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style="color: #e60012">好心冻</span
                >不仅仅是一家冻品供应商，更是美食文化的传播者。我们深知，
                每一道菜肴背后都承载着地域的风情与文化的韵味。因此，我们与国内外知名大厨紧密合作，不断研发创新，
                将传统美食与现代工艺完美融合，推出了一系列风味独特、健康美味的冻品佳肴。无论是家常小炒的温馨，
                还是宴席大餐的奢华，都能在<span style="color: #e60012">好心冻</span
                >找到那份触动心灵的味道。<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                我们坚持可持续发展理念，注重环境保护与资源合理利用。在生产过程中，采用环保材料与节能技术，
                减少对环境的影响。同时，我们积极参与公益事业，回馈社会，用实际行动诠释企业的社会责任与担当。<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 在<span style="color: #e60012">好心冻</span
                >，我们相信，好的食物能够连接人心，传递温暖与幸福。
                我们致力于将这份美好带给每一位消费者，让每一次用餐都成为一次难忘的味蕾之旅。
                选择<span style="color: #e60012">好心冻</span
                >，就是选择了一个关于爱、关于美好、关于健康的生活方式。
                让我们一起，探索更多美味可能，共享冻品带来的无限惊喜与感动！
              </div>
               </div>
              
              <!-- 客户添加信息 -->
              <div class="enterprise-info-form" v-if="selectedContent !== '加入我们'">
                <h2>{{selectedContent}}基本信息</h2>
                <form @submit.prevent="submitForm">
                  <div>
                    <label for="enterpriseName"
                      >企业名称：
                      <input
                        type="text"
                        id="enterpriseName"
                        v-model="formData.enterpriseName"
                        required />
                    </label>
                  </div>
                  <div>
                    <label for="legalRepresentative"
                      >法人代表：
                      <input
                        type="text"
                        id="legalRepresentative"
                        v-model="formData.legalRepresentative"
                        required />
                    </label>
                  </div>
                  <div>
                    <label for="registeredAddress"
                      >注册地址：
                      <input
                        type="text"
                        id="registeredAddress"
                        v-model="formData.registeredAddress"
                        required />
                    </label>
                  </div>
                  <div>
                    <label for="contactPhone"
                      >联系电话：
                      <input
                        type="text"
                        id="contactPhone"
                        v-model="formData.contactPhone"
                        required />
                    </label>
                  </div>
                  <div>
                    <label for="email"
                      >电子邮箱：
                      <input type="email" id="email" v-model="formData.email" required />
                    </label>
                  </div>
                  <div>
                    <label for="enterpriseNature"
                      >企业性质：
                      <select
                        type="nature"
                        id="enterpriseNature"
                        v-model="formData.enterpriseNature"
                        required>
                        <option value="国有企业">国有企业</option>
                        <option value="私营企业">私营企业</option>
                        <option value="外资企业">外资企业</option>
                        <!-- 可以根据实际需要添加更多选项 -->
                      </select></label
                    >
                  </div>
                  <div>
                    <label for="establishmentDate"
                      >成立日期：
                      <input
                        type="date"
                        id="establishmentDate"
                        v-model="formData.establishmentDate"
                        required />
                    </label>
                  </div>
                  <div>
                    <label for="businessScope"
                      >经营范围：
                      <input
                        type="text"
                        id="businessScope"
                        v-model="formData.businessScope"
                        required />
                    </label>
                  </div>
                  <div style="text-align: center">
                    <button style="text-align: center" type="submit">提交</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import  { defineComponent, ref } from "vue";
import Video from "@/components/Video.vue";
import Bar from "@/components/NavBar.vue";

export default defineComponent({
  name: "JoinUs",
  components: {
    Video,
    Bar,
  },
  setup() {
    const selectedContent = ref("供应商");
    // 选择内容的方法
    function selectContent(content: string) {
      selectedContent.value = content;
      
    }
    return {
      selectedContent,
      selectContent,
    };
  },
  data() {
    return {
      //客户数据
      formData: {
        enterpriseName: "",
        legalRepresentative: "",
        registeredAddress: "",
        contactPhone: "",
        email: "",
        enterpriseNature: "",
        establishmentDate: "",
        businessScope: "",
      },
      //显示
      isShow: false,
    };
  },
  methods: {
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
    //提交表单数据
    submitForm() {
      // 在这里你可以添加逻辑来处理表单提交，比如发送到服务器
      console.log(this.formData);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
});
</script>

<style scoped>
/* 上半部分 */
.container {
  /* width: 100vw; */
  max-width: 100%;
  position: relative;
  width: 100%;
}
.container .main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
/* 图片设置 */
.fx-h .header {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  position: relative;
}
.fx-h .text-overlay {
  position: absolute;
  font-size: 50px;
  top: 230px;
  color: white; /* 根据背景色设置合适的文字颜色 */
  font-weight: 500px;
}
.fx-h .text-overlay2 {
  position: absolute;
  top: 300px;
  color: white; /* 根据背景色设置合适的文字颜色 */
  font-size: 17px;
  font-weight: 500;
}
/* 下半部分 */
.row-b1 {
  width: 100%;
  overflow: hidden;
}
.wp {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
/* 左边 */
.row-b1 .col-l {
  width: 20vw;
  /* 显式地设置左边框 */
  border-left: 8px solid #e60012; /* 左边框宽度为5px，样式为实线，颜色为黑色 */
  /* 显式地设置右边框 */
  border-right: 1px solid #e60012; /* 右边框宽度为2px，样式为实线，颜色为黑色 */
}
.work_list {
  width: 100%;
  margin-bottom: 20px;
  /* 上下边框设置为0px（实际上，如果不设置它们，它们就默认为0px，但这里显式设置以表明意图） */
  border-top: 0px solid transparent; /* 宽度为0，颜色不影响显示 */
  border-bottom: 0px solid transparent; /* 同上 */
  /* 添加一些内边距以便更好地看到边框效果 */
}
.work_list .tit {
  display: flex;
  align-items: center; /* 垂直居中 */
  cursor: pointer;
  line-height: 44px;
  background-size: auto 10px;
  /* border-bottom: 1px solid #f2f2f2; */
  width: 100%;
  float: left;
  font-size: 20px;
}
.work_list .tit .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: #fff;
  display: flex;
  margin-right: 10px;
  margin-left: 30px;
}
.col-l .work_list .tit:hover {
  background-color: #e60012; /* 鼠标悬浮时背景变红 */
  transition: background-color 1s ease-in-out; /* 添加背景颜色变化的过渡效果 */
}

/* 右边 */
.row-b1 .col-r {
  float: none;
  overflow: hidden;
  margin-left: 10px;
  width: 80%;
}
.g-titIns1 {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 44px;
  line-height: 44px;
  font-size: 40px;
  color: #e60012;
  font-weight: normal;
  position: relative;
}
.m-chanpin {
  overflow: hidden;
  margin-left: 30px;
}
.m-chanpin .information {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  color: #e60012;
  font-size: 28px;
}
.m-chanpin .introduce {
  margin-top: 30px;
  padding: 0px 150px 40px 150px;
}

.enterprise-info-form {
  background-color: #fff; /* 假设背景是白色的 */
  border: 1px solid #e60012; /* 假设有一个红色的边框 */
  border-radius: 5px; /* 边框圆角 */
  width: 60%;
  margin: 10px auto; /* 上下外边距20px，左右自动居中 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微的阴影效果 */
  font-family: Arial, sans-serif; /* 字体设置 */
}
.enterprise-info-form h2 {
  text-align: center;
  padding-top: 10px;
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e60012;
}
.enterprise-info-form form {
  margin-top: 50px;
}
.enterprise-info-form label {
  text-align: center; /* 文本在 label 内部居中 */
  display: block;
  margin-bottom: 5px; /* 标签与输入框之间的间距 */
  font-weight: bold; /* 假设标签是粗体的 */
  color: #333; /* 文本颜色 */
}
.enterprise-info-form input[type="text"],
.enterprise-info-form input[type="email"],
.enterprise-info-form select[type="nature"],
.enterprise-info-form input[type="date"] {
  width: 300px; /* 减去左右padding和border的宽度 */
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}
.enterprise-info-form input[type="date"] {
  /* 针对日期输入框，可能不需要特别的样式，但可以根据需要调整 */
}
.enterprise-info-form button {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 150px;
  padding: 10px;
  background-color: #e60012; /* 鲜红色背景 */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* 假设按钮上的文字稍大一些 */
}
.active {  
  background-color: #e60012;
}  
</style>
