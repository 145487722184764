<template>
  <div class="any">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <div class="BI1">
        <div class="BI11 fx-h">
          <h1 class="BI111">产 品</h1>
          <br />
          <h3 class="BI111">冷冻锁住新鲜，美味尽在口中</h3>
        </div>
      </div>
      <div class="BI2 fx-r">
        <div class="BI22 content-area">
          <!-- 根据 selectedContent 显示内容 -->
            <div class="right-title">
              <div class="right-title-border">
                <span class="text-b">冷冻海鲜水产</span>
              </div>
            </div>
            <div class="right-content fx-h">
              <!-- 每一行 -->
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgWater-1"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>八爪鱼</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgWater-2"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>巴沙鱼</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgWater-3"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鲳鱼</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgWater-4"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>带鱼</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgWater-5"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>耗儿鱼</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgWater-6"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>红虾</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgWater-7"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>青虾</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgWater-8"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>青蟹</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgWater-9"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>生蚝</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgWater-10"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>小龙虾</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgWater-11"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鱿鱼</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgWater-12"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鱿鱼须</span>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 50px"></div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";
import Video from "@/components/Video.vue";
import { ref } from "vue";
export default {
  name: "FrozenWater",
  components: {
    Video,
    Bar,
  },
  data() {
    return {
      //显示
      isShow: false,
    };
  },
  methods: {
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
};
</script>

<style scoped>
.any {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.BI1 {
  height: 500px;
  overflow-x: hidden;
  background-image: url("@/assets/产品.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BI11 h1{
  font-size: 50px;
}
.BI11 h3{
  font-size: 17px;
}
.BI111 {
  color: white;
  font-weight: 500px;
}

.content-area {
  width: 78%;
  height: 100%;
  margin-left: 10px;
  padding: 0px;
}
.BI2 {
  width: 100%;
  /* height: 100vh; */
}

.BI22 {
  width: 100%;
  height: 100%;
}
.right-title {
  width: 100%;
  height: 30%;
}
.right-title-border {
  width: 25%;
  height: 50px;
  margin-top: 2%;
  margin-bottom: 1%;
  margin-left: 38%;
  border: 1px solid rgb(181, 180, 180);
  border-radius: 20px 20px 20px 20px;
  display: grid;
  place-items: center;
}
.right-content {
  width: 90%;
  height: 65%;
  margin-left: 6%;
}
.right-content-row {
  width: 100%;
  height: 30%;
  margin-top: 2%;
}
.right-content-row-one {
  width: 20%;
  height: 300px;
  margin-left: 6%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-two {
  width: 20%;
  height: 300px;
  margin-left: 2%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-three {
  width: 20%;
  height: 300px;
  margin-left: 2%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-four {
  width: 20%;
  height: 300px;
  margin-left: 2%;
  margin-right: 6%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-top {
  width: 94%;
  height: 85%;
  margin-left: 3%;
  margin-right: 3%;
}
.right-content-row-bottom {
  width: 90%;
  height: 13%;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
}
.text-b {
  font-size: 30px;
  letter-spacing: 10px;
}
.text-s {
  font-size: 16px;
  letter-spacing: 5px;
  text-align: center;
}
.imgWater-1 {
  background-image: url(@/assets/water01.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWater-2 {
  background-image: url(@/assets/water02.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWater-3 {
  background-image: url(@/assets/water03.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWater-4 {
  background-image: url(@/assets/water04.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWater-5 {
  background-image: url(@/assets/water05.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWater-6 {
  background-image: url(@/assets/water06.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWater-7 {
  background-image: url(@/assets/water07.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWater-8 {
  background-image: url(@/assets/water08.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWater-9 {
  background-image: url(@/assets/water09.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWater-10 {
  background-image: url(@/assets/water10.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWater-11 {
  background-image: url(@/assets/water11.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWater-12 {
  background-image: url(@/assets/water12.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
