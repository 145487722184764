<template>
  <div class="container">
    <el-carousel
      style="position: relative"
      :autoplay="true"
      :interval="2000"
      arrow="always"
      height="100vh"
    >
      <el-carousel-item v-for="(item, index) in items" :key="index">
        <img :src="item.src" :alt="item.alt" class="carousel-image" />
      </el-carousel-item>
    </el-carousel>
    <div
      class="fx-r fx-ac"
      style="
        width: 100%;
        position: absolute;
        bottom: 22px;
        left: calc(50%-350px);
        color: #c0c0c0;
        font-size: 12px;
        z-index: 4;
      "
    >
      素材均采集于网络，如有侵权，请联系我们！电话：028-60625493
      <div class="foot-container" style="position: absolute">
        <!-- <el-divider class="divider"></el-divider> -->
        <div class="foot-content fx-r">
          <div class="copyright">
            Copyright © 2024 haoxindong All Rights Reserved. |
            <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">蜀ICP备2022020133号-1</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      items: [
        { src: require("@/assets/轮播1.png"), alt: "Image " },
        { src: require("@/assets/轮播5.jpg"), alt: "Image " },
        { src: require("@/assets/轮播6.jpg"), alt: "Image " },
        { src: require("@/assets/轮播7.png"), alt: "Image " },
      ],
    };
  },
  methods: {
    handleClick() {
      alert("Button clicked!");
    },
  },
});
</script>
<style>
.el-carousel__indicators {
  margin-bottom: 35px !important;
  z-index: 3 !important;
}
.el-carousel__button {
  width: 40px;
  height: 1px;
  border: none;
  border-radius: 30%;
  background: #ffffff;
  opacity: 0.6;
}

.is-active .el-carousel__button {
  background: #6495ed;
  opacity: 1;
}
</style>

<style scoped>
.container {
  width: 100vw;
}
.carousel-title {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  z-index: 1;
}
.carousel-btn {
  cursor: pointer;
  z-index: 1;
}
.carousel-image {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  z-index: 1;
  /* object-fit: cover; */
}

::v-deep.el-carousel {
  .el-carousel__item {
    background-color: #ccc;
    margin-top: 0px;
  }
}

/*调整走马灯箭头-左箭头 */
::v-deep .el-carousel__arrow--left {
  top: 400px;
  left: 16px;
  font-size: 30px;
  color: #c0c0c0;
  font-weight: bold;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.3);
}

::v-deep .el-carousel__arrow--left:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

/* 右箭头 */
::v-deep .el-carousel__arrow--right {
  top: 400px;
  right: 16px;
  font-size: 30px;
  color: #c0c0c0;
  font-weight: bold;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.3);
}

::v-deep .el-carousel__arrow--right:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.foot-container {
  width: 100vw;
  max-width: 100%;
  margin-top: 10px;
  /* position: absolute;
  z-index: 4; */
}
.foot-content {
  width: 100vw;
  margin-top: 5px;
  /* margin-left: 5vw; */
}

.copyright {
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  color: #c0c0c0;
  margin-left: 10px;
}
.copyright a{
  text-decoration: none;
  font-size: 12px;
  color: #c0c0c0;
}
a:hover{
  color: red;
}
</style>
