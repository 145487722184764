<template>
  <div class="any fx-h">
    <Bar />
    <div class="B1">
      <div class="vgn fx-r">
        <router-link to="/GraphicNews"><a class="text-g">图文</a></router-link>
        <span class="text-I">|</span>
        <span class="text-v">视频</span>
      </div>
    </div>
    <div class="B2">
      <div class="c fx-r">
        <div class="B2l">
          <video
            ref="videoPlayer"
            width="100%"
            height="100%"
            muted
            autoplay
            controls
            class="video-fill"
          >
            <source
              src="http://www.haoxindong.com/media/%E8%B6%8A%E5%8D%97%E9%9D%92%E8%9F%B9.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div class="B2r fx-h">
          <div class="B2r-t fx-r">
            <span class="text-rlt">热门视频</span>
          </div>
          <div class="line"></div>
          <div class="model fx-r" @click="news1">
            <img
              src="@/assets/newsVideo1.png"
              alt=""
              width="60%"
              height="100%"
            />
            <div class="model-right">
              <div>西藏特色产业牦牛产业</div>
              <div class="time">01 : 44</div>
            </div>
          </div>
          <div class="model fx-r" @click="news2">
            <img
              src="@/assets/newsVideo2.png"
              alt=""
              width="60%"
              height="100%"
            />
            <div class="model-right">
              <div>捕捞美国阿拉斯加帝王蟹也有这么多心眼</div>
              <div class="time">02 : 29</div>
            </div>
          </div>
          <div class="model fx-r" @click="news4">
            <img
              src="@/assets/newsVideo4.png"
              alt=""
              width="60%"
              height="100%"
            />
            <div class="model-right">
              <div>泰国农户：中国以外的其他国家不太爱吃榴莲</div>
              <div class="time">02 : 12</div>
            </div>
          </div>
          <div class="model fx-r" @click="news5">
            <img
              src="@/assets/newsVideo5.png"
              alt=""
              width="60%"
              height="100%"
            />
            <div class="model-right">
              <div>藏香猪一头两三万，一年就能赚百万！</div>
              <div class="time">03 : 53</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="B3">
      <div class="c fx-h">
        <div class="B31">
          <br /><br />
          <a class="text-b1">越南青蟹</a>
        </div>
        <div class="B32 fx-r">
          <br />
          <span class="text-b2">来源：微博</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span class="text-b2">2024-07-15&nbsp;&nbsp;07：52</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            :class="[isVisible ? 'toggle-buttonActive' : 'toggle-button']"
            @click="toggle"
          >
            视频简介
            <i class="el-icon-arrow-down" v-if="!isVisible"></i>
            <i class="el-icon-arrow-up" v-else></i>
          </div>
        </div>
        
      </div>
    </div>
    <div class="content" v-show="isVisible">
        <el-divider class="divider"></el-divider>
        <h3>视频简介</h3>
        <p>2024年前两个月，中国大陆、中国香港地区和新加坡均大幅增加从越南进口活蟹。为越南活蟹出口总额增长超过4倍做出了重要贡献。仅2024年2月份，越南活蟹出口额就达到近1000万美元。</p>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";

export default {
  name: "VideoNews3",
  components: {
    Bar,
  },
  data() {
    return {
      isVisible: false, // 控制内容是否显示
    };
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible; // 切换显示隐藏状态
    },
    news1() {
      this.$router.push("/VideoNews1").catch((err) => {});
    },
    news2() {
      this.$router.push("/VideoNews2").catch((err) => {});
    },
    news4() {
      this.$router.push("/VideoNews4").catch((err) => {});
    },
    news5() {
      this.$router.push("/VideoNews5").catch((err) => {});
    },
  },
};
</script>

<style scoped>

.toggle-button {
  cursor: pointer;
  background-color: #151515;
  font-size: 15px;
  color: #f0f0f0;
  transition: color 0.3s, background-color 0.3s;
}

.toggle-button i {
  margin-right: 8px; 
}

.content {
  width: 100%;
  margin-top: 30px;
  color: white;
  background-color: black;
}
.divider {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 0px;
}
.content h3 {
    margin-top: 30px;
    margin-left: 10%;
    margin-right: 10%;
    letter-spacing: 5px;
}
.content p {
    margin-top: 30px;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 40px;
    letter-spacing: 5px;
}
.toggle-buttonActive {
  color: red;
  font-size: 15px;
}

.el-icon-arrow-up {
  color: red;
}


.any {
  background-color: #151515;
}
.B1 {
  width: 100%;
  height: 20%;
  margin-top: 40px;
}
.B2 {
  width: 100%;
  height: 500px;
  margin-top: 30px;
}
.B3 {
  width: 100%;
  height: 20%;
  margin-bottom: 20px;
}
.c {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-right: 10%;
}
.B2l {
  width: 75%;
  height: 100%;
  background-color: rgb(108, 107, 107);
}
.video-fill {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.B31 {
  width: 100%;
  height: 50%;
}
.B32 {
  width: 100%;
  height: 50%;
}
.B2r {
  width: 25%;
  height: 100%;
  background-color: rgb(77, 75, 75);
}
.B2r-t {
  width: 100%;
  height: 10%;
}
.line {
  width: 100%;
  height: 1%;
  background-color: #f0f0f0;
}
.text-rlt {
  font-size: 25px;
  margin-left: 10px;
  margin-top: 2%;
  color: #f0f0f0;
}
.text-rrt {
  font-size: 15px;
  margin-left: 40%;
  margin-top: 3%;
  color: #f0f0f0;
}
.model {
  width: 90%;
  height: 100px;
  margin-left: 5%;
  margin-top: 10px;
  position: relative;
}
.model-right {
  width: 35%;
  height: 100%;
  margin-left: 10px;
  right: 0;
  font-size: 12px;
  color: white;
  position: absolute;
}
.time {
  position: absolute;
  bottom: 0;
}
.vgn {
  width: fit-content;
  height: 16%;
  margin-left: 10%;
  margin-top: 100px;
  padding: 5px;
  text-align: center;
  letter-spacing: 4px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid rgb(77, 75, 75);
  background-color: rgb(77, 75, 75);
}
.text-b1 {
  font-size: 30px;
  margin-top: 50px;
  letter-spacing: 5px;
  color: #f0f0f0;
}
.text-b2 {
  font-size: 15px;
  color: #f0f0f0;
}
.text-g {
  display: inline-block;
  color: #f0f0f0;
}
.text-I {
  color: #f0f0f0;
}
.text-v {
  color: red;
}
</style>
