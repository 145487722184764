<template>
    <div ref="chart" style="width: 100%; height: 500px;"></div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  
  export default {
    name: 'LineChart',
    mounted() {
      this.initChart();
    },
    methods: {
      initChart() {
        const chart = echarts.init(this.$refs.chart);
        const option = {
          xAxis: {
            type: 'category',
            data: ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00']
          },
          yAxis: {
            type: 'value',
            max: 5000
          },
          series: [
            {
              data: [1200, 900, 4500, 1800, 3200, 2400, 4000, 2000, 3500, 1000],
              type: 'line',
              itemStyle: {
                color: '#FF0000'
              },
              lineStyle: {
                color: '#FF0000'
              },
              symbol: 'circle',
              symbolSize: 8,
              showSymbol: true
            }
          ]
        };
        chart.setOption(option);
      }
    }
  }
  </script>
  
  <style scoped>
  </style>
  