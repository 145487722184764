<template>
  <div class="any">
    <Bar />
    <div class="BI1">
      <div class="BI11 fx-h">
        <h1 class="BI111">新 闻</h1>
        <br />
        <a class="BI111 h3">掌握市场动态，领先一步</a>
      </div>
    </div>
    <div class="BI2 fx-h">
      <div class="B21 fx-h">
        <div class="b1 fx-r">
          <div class="imageText fx-r">
            <span class="span-v">视频</span>
            <span class="span-I">|</span>
            <span
              ><router-link to="/GraphicNews" class="span-g"
                >图文</router-link
              ></span
            >
          </div>
          <div class="more">
            <span class="text-m">更多></span>
          </div>
        </div>
        <div class="model1 fx-r">
          <div class="news1-left fx-h">
            <div class="news1-v">
              <router-link to="/VideoNews1"
                ><div><img src="@/assets/play.png" alt="" width="40px" /></div
              ></router-link>
              <div class="videoTime"><div class="time">01:44</div></div>
            </div>
          </div>
          <div class="news1-right fx-h">
            <div class="news1-right-text">
              <h2>西藏特色产业牦牛产业</h2>
              <p>
                生活在西藏高原草地上的精品牦牛，西藏特色产业，带动西藏各产业循环发展。
              </p>
            </div>
            <router-link to="/VideoNews1"
              ><div class="whatch">立即观看 ></div></router-link
            >
          </div>
        </div>
        <div class="model2 fx-r">
          <div class="news2 fx-h" @click="news2">
            <div class="news2-v">
              <div><img src="@/assets/play.png" alt="" width="40px" /></div>
            </div>
            <div class="time1 fx-r">
              <img src="@/assets/clockIcon.png" alt="" class="clockIcon" />02:29
            </div>
            <div class="news-h3">
              <h3>捕捞美国阿拉斯加帝王蟹也有这么多心眼</h3>
            </div>
          </div>
          <div class="news3 fx-h" @click="news3">
            <div class="news3-v">
              <div><img src="@/assets/play.png" alt="" width="40px" /></div>
            </div>
            <div class="time1 fx-r">
              <img src="@/assets/clockIcon.png" alt="" class="clockIcon" />02:14
            </div>
            <div class="news-h3"><h3>震惊！越南活青蟹对华出口暴增</h3></div>
          </div>
          <div class="news4 fx-h" @click="news4">
            <div class="news4-v">
              <div><img src="@/assets/play.png" alt="" width="40px" /></div>
            </div>
            <div class="time1 fx-r">
              <img src="@/assets/clockIcon.png" alt="" class="clockIcon" />02:12
            </div>
            <div class="news-h3">
              <h3>泰国农户：中国以外的其他国家不太爱吃榴莲</h3>
            </div>
          </div>
        </div>
        <div class="model2 fx-r">
          <div class="news5 fx-h" @click="news5">
            <div class="news5-v">
              <div><img src="@/assets/play.png" alt="" width="40px" /></div>
            </div>
            <div class="time1 fx-r">
              <img src="@/assets/clockIcon.png" alt="" class="clockIcon" />03:53
            </div>
            <div class="news-h3">
              <h3>藏香猪一头两三万，一年就能赚百万！</h3>
            </div>
          </div>
          <div class="news6 fx-h" @click="news6">
            <div class="news6-v">
              <div><img src="@/assets/play.png" alt="" width="40px" /></div>
            </div>
            <div class="time1 fx-r">
              <img src="@/assets/clockIcon.png" alt="" class="clockIcon" />04:13
            </div>
            <div class="news-h3">
              <h3>西班牙火腿真讲究！1200亩只养40头黑猪，怪不得这么贵！</h3>
            </div>
          </div>
          <div class="news7 fx-h" @click="news7">
            <div class="news7-v">
              <div><img src="@/assets/play.png" alt="" width="40px" /></div>
            </div>
            <div class="time1 fx-r">
              <img src="@/assets/clockIcon.png" alt="" class="clockIcon" />01:19
            </div>
            <div class="news-h3"><h3>早熟品种：越南超早菠萝蜜</h3></div>
          </div>
        </div>
        <div class="model2 fx-r">
          <div class="news8 fx-h" @click="news8">
            <div class="news8-v">
              <div><img src="@/assets/play.png" alt="" width="40px" /></div>
            </div>
            <div class="time1 fx-r">
              <img src="@/assets/clockIcon.png" alt="" class="clockIcon" />02:32
            </div>
            <div class="news-h3"><h3>美国肥牛，切切切！</h3></div>
          </div>
          <div class="news9 fx-h" @click="news9">
            <div class="news9-v">
              <div><img src="@/assets/play.png" alt="" width="40px" /></div>
            </div>
            <div class="time1 fx-r">
              <img src="@/assets/clockIcon.png" alt="" class="clockIcon" />01:58
            </div>
            <div class="news-h3">
              <h3>林下“跑山鸡” “跑”出乡村振兴致富路</h3>
            </div>
          </div>
          <div class="news10 fx-h" @click="news10">
            <div class="news10-v">
              <div><img src="@/assets/play.png" alt="" width="40px" /></div>
            </div>
            <div class="time1 fx-r">
              <img src="@/assets/clockIcon.png" alt="" class="clockIcon" />01:17
            </div>
            <div class="news-h3"><h3>波士顿龙虾这样挑，肉多又紧实</h3></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";
export default {
  name: "GraphicNews",
  components: {
    Bar,
  },
  methods: {
    data() {
      return;
    },
    news1() {
      this.$router.push("/NewsImageText1").catch((err) => {});
    },
    news2() {
      this.$router.push("/VideoNews2").catch((err) => {});
    },
    news3() {
      this.$router.push("/VideoNews3").catch((err) => {});
    },
    news4() {
      this.$router.push("/VideoNews4").catch((err) => {});
    },
    news5() {
      this.$router.push("/VideoNews5").catch((err) => {});
    },
    news6() {
      this.$router.push("/VideoNews6").catch((err) => {});
    },
    news7() {
      this.$router.push("/VideoNews7").catch((err) => {});
    },
    news8() {
      this.$router.push("/VideoNews8").catch((err) => {});
    },
    news9() {
      this.$router.push("/VideoNews9").catch((err) => {});
    },
    news10() {
      this.$router.push("/VideoNews10").catch((err) => {});
    },
  },
};
</script>

<style scoped>
.BI1 {
  /* width: 100vw; */
  height: 500px;
  overflow-x: hidden;
  background-image: url(@/assets/新闻.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BI111 {
  color: aliceblue;
}
h1 {
  font-size: 50px;
}
.h3 {
  font-size: 17px;
}

.BI2 {
  width: 90%;
  margin-left: 100px;
  margin-bottom: 50px;
}
.B21 {
  width: 100%;
}

.b1 {
  width: 100%;
  height: 39px;
  margin-top: 100px;
  position: relative;
}
.imageText {
  width: fit-content;
  height: 25px;
  padding: 6px 10px;
  letter-spacing: 4px;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  left: 5%;
  position: absolute;
}
.imageText a {
  text-decoration: none;
}
.span-g {
  color: black;
}
.span-v {
  color: red;
}

.text-m {
  width: 80px;
  height: 25px;
  color: rgb(75, 73, 73);
  align-items: center;
  display: inline-block;
  border-radius: 10px;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
}
.more {
  text-align: center;
  height: 25px;
  right: 6%;
  position: absolute;
}

.model1 {
  width: 100%;
  height: 400px;
  margin-top: 60px;
}
.news1-left {
  width: 60%;
  height: 100%;
  margin-left: 5%;
  /* margin-right: 2%; */
  margin-bottom: 5%;
}
.news1-v {
  width: 100%;
  height: 100%;
  background-image: url(@/assets/newsVideo1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  line-height: 400px;
}
.videoTime {
  width: 80px;
  height: 40px;
  background-image: url(@/assets/videoTime.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.time {
  color: white;
  text-align: center;
  line-height: 40px;
}
.news1-right {
  width: 29%;
  height: 100%;
  margin-bottom: 5%;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1);
}
.news1-right-text {
  width: 80%;
  margin-left: 10%;
  margin-top: 20%;
}
.news1-right-text h2 {
  font-size: 28px;
  letter-spacing: 10px;
}
.news1-right-text p {
  letter-spacing: 6px;
  margin-top: 20px;
  color: #616161;
}
.news1-right a {
  text-decoration: none;
}
.whatch {
  width: 80%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: white;
  margin-left: 10%;
  margin-top: 50px;
  background-color: #e60012;
  border-radius: 5px;
}
.time1 {
  width: 120px;
  margin-top: 20px;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #f0616c;
  color: #f0616c;
  padding: 5px 0;
  letter-spacing: 4px;
}
.clockIcon {
  margin-right: 10px;
  margin-left: 10px;
}
.model2 {
  width: 100%;
  height: 600px;
  margin-top: 60px;
}

.news2 {
  width: 28%;
  margin-left: 5%;
  margin-right: 2.5%;
  margin-bottom: 5%;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.news2-v {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/newsVideo2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  line-height: 360px;
}
.news-h3 {
  width: 80%;
  margin-left: 10%;
  letter-spacing: 5px;
  margin-top: 40px;
}
.news3 {
  width: 28%;
  height: 100%;
  margin-right: 2.5%;
  margin-bottom: 5%;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.news3-v {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/newsVideo3.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  line-height: 360px;
}
.news4 {
  width: 28%;
  height: 100%;
  margin-bottom: 5%;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.news4-v {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/newsVideo4.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 360px;
}
.news5 {
  width: 28%;
  height: 100%;
  margin-left: 5%;
  margin-right: 2.5%;
  margin-bottom: 5%;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.news5-v {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/newsVideo5.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 360px;
}
.news6 {
  width: 28%;
  height: 100%;
  margin-right: 2.5%;
  margin-bottom: 5%;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.news6-v {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/newsVideo6.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  line-height: 360px;
}
.news7 {
  width: 28%;
  height: 100%;
  margin-bottom: 5%;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.news7-v {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/newsVideo7.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 360px;
}
.news8 {
  width: 28%;
  height: 100%;
  margin-left: 5%;
  margin-right: 2.5%;
  margin-bottom: 5%;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.news8-v {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/newsVideo8.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 360px;
}
.news9 {
  width: 28%;
  height: 100%;
  margin-right: 2.5%;
  margin-bottom: 5%;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.news9-v {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/newsVideo9.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  line-height: 360px;
}
.news10 {
  width: 28%;
  height: 100%;
  margin-bottom: 5%;
  border: 1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.news10-v {
  width: 100%;
  height: 60%;
  background-image: url(@/assets/newsVideo10.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 360px;
}
</style>
