<template>
  <div class="parent-container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <div class="top-photo">
        <div class="top-text fx-h">
          <h1 class="text">指 数</h1>
          <br />
          <h3 class="text">掌握市场动态，领先一步</h3>
        </div>
      </div>
      <div class="page fx-r">
        <div class="sidebar">
          <ul>
            <li
              @click="selectContent('冻品指数')"
              :class="{ active: selectedContent === '冻品指数' }"
            >
              <div class="fx-r">
                <div class="circle"></div>
                <span>冻品指数</span>
              </div>
            </li>
            <li
              :class="{ active: selectedContent === '农产品指数' }"
            >
              <div class="fx-r" @click="toFarm">
                <div class="circle"></div>
                <span>农产品指数</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="content-area">
          <div class="page-name">
            <h2>{{ selectedContent }}</h2>
          </div>
          <div class="chart fx-h" v-if="selectedContent === '冻品指数'">
            <div class="echart" ref="mychart" id="mychart"></div>
            <div class="echart2" ref="mychart2" id="mychart2"></div>
            <div class="notice">数据来源：中华人民共和国海关总署网《表五 2024年6月份全国出口商品贸易同比指数（HS4分类）》</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";
import Video from "@/components/Video.vue";
import { defineComponent, ref, onMounted } from "vue";
import * as echarts from "echarts";

export default defineComponent({
  name: "TrendView",
  components: {
    Bar,
    Video,
  },
  setup() {
    const selectedContent = ref("冻品指数");
    function selectContent(content) {
      if (content == selectedContent) {
        return;
      }
      selectedContent.value = content;
      setTimeout(() => {
        initEcharts();
      }, 200);
    }
    const mychart = ref(null);
    const mychart2 = ref(null);
    const initEcharts = () => {
      const option = {
        tooltip: {},
        legend: {
          data: ["价格指数"],
        },
        xAxis: {
          axisLabel: {
            interval: 0, //代表显示所有x轴标签显示
            rotate: 20, //代表逆时针旋转45度
          },
          data: [
            "牛",
            "鲜、冷、冻猪肉",
            "	鲜、冷、冻绵羊肉或山羊肉",
            "	鲜、冷、冻马、驴、骡肉",
            "鲜、冷、冻鱼片及其他鱼肉",
            "其他鲜、冷、冻肉及食用杂碎",
          ],
        },
        yAxis: {},
        series: [
          {
            name: "价格指数",
            type: "line", //类型为折线图
            lineStyle: {
              color: "red",
            },
            data: [95.1, 102.5, 84.6, 102.6, 87.7, 97.2],
          },
        ],
      };
      const option2 = {
        tooltip: {},
        legend: {
          data: ["数量指数"],
        },
        xAxis: {
          axisLabel: {
            interval: 0, //代表显示所有x轴标签显示
            rotate: 20, //代表逆时针旋转45度
          },
          data: [
            "牛",
            "鲜、冷、冻猪肉",
            "	鲜、冷、冻绵羊肉或山羊肉",
            "	鲜、冷、冻马、驴、骡肉",
            "鲜、冷、冻鱼片及其他鱼肉",
            "其他鲜、冷、冻肉及食用杂碎",
          ],
        },
        yAxis: {},
        series: [
          {
            name: "数量指数",
            type: "line", //类型为折线图
            lineStyle: {
              color: "red",
            },
            data: [106.2, 94.4, 152.1, 140.3, 127.6, 86.9],
          },
        ],
      };
      const myChart = echarts.init(mychart.value);
      const myChart2 = echarts.init(mychart2.value);
      myChart.clear();
      myChart2.clear();

      myChart.setOption(option);
      myChart2.setOption(option2);
    };
    onMounted(() => {
      initEcharts();
    });
    return {
      mychart,
      mychart2,
      selectedContent,
      selectContent,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
  data() {
    return {
      //显示
      isShow: false,
      selectedContentName: "农产品指数",
    };
  },
  methods: {
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
    toFarm() {
      this.$router.push("/FarmTrend").catch((err) => {});
    }
  },
});
</script>

<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
/* 头部图片 */
.top-photo {
  height: 500px;
  overflow-x: hidden;
  background-image: url(@/assets/指数.png);
  background-size: cover;
}
/* 图片上的文字 */
.top-text {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-text h1 {
  font-size: 50px;
}
.top-text h3 {
  font-size: 17px;
}

/* 图片以下部分 */
.page {
  height: auto;
}

/* 侧边栏的圆圈 */
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: white;
  display: flex;
  margin-top: 9px;
  margin-right: 10px;
  margin-left: 30px;
}
/* 侧边栏 */
.sidebar {
  width: 20vw;
  border-right: 1px solid red;
  border-left: 8px solid red;
  background-color: white;
}
li {
  font-size: 20px;
}
.sidebar ul {
  /* list-style-type: none; 去除圆圈 */
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 10px;
  cursor: pointer;
}

.sidebar ul li:hover {
  background-color: rgb(220, 23, 23);
}
.sidebar ul a {
  text-decoration: none;
  color: black;
}
/* 图表部分 */
.content-area {
  width: 78%;
  overflow: hidden;
  margin-bottom: 50px;
}

/* 所在页面名称 */
.page-name {
  text-align: center;
  margin-top: 20px;
}
/* 图表样式 */
.chart {
  margin-top: 50px;
}
.echart {
  width: 700px;
  height: 400px;
  margin-left: 250px;
  margin-top: 20px;
  border: 1px solid red;
}
.echart2 {
  width: 700px;
  height: 400px;
  margin-top: 20px;
  margin-left: 250px;
  border: 1px solid red;
}
.active {
  background-color: #e60012;
}
.notice{
  margin-left: 400px;
  margin-top: 40px;
  color: grey;
}
</style>
