<template>
  <div class="container fx-r fx-ac fx-bc">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <Swiper />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Video from "@/components/Video.vue";
import Bar from "@/components/NavBar.vue";
import Swiper from "@/components/Swiper.vue";

export default Vue.extend({
  name: "HomeView",
  components: {
    Video,
    Bar,
    Swiper, 
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
});
</script>

<style scoped>
.container {
  /* width: 100vw; */
  max-width: 100%;
  position: relative;
  height: 100vh;
  width: 100%;
}

.main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}

.product-title {
  height: 120px;
  color: #5d5d5d;
  font-size: 33px;
  font-weight: bold;
}

.product-item {
  width: 100px;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  letter-spacing: 4px;
}

.product-item::after {
  content: "";
  position: absolute;
  top: 24px;
  left: 50%;
  width: 0%;
  height: 2px;
  background-color: #333;
  transition: width 0.3s ease, left 0.3s ease;
  /* 定义过渡效果 */
  transform: translateX(-50%);
  /* 居中显示 */
}

.product-item:hover::after {
  width: 70%;
  /* 鼠标经过时展开下划线 */
  left: 50%;
  /* 动画从文字底部中间开始 */
}

.fix {
  width: 100vw;
  max-width: 100%;
  color: white;
  font-size: 40px;
  font-weight: bolder;
  letter-spacing: 5px;
}

.fix1 {
  height: 55vh;
  width: calc(50% - 3px);
  margin-right: 3px;
  background-image: url(../assets/牛肉4.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.fix2 {
  height: 55vh;
  width: calc(50% - 3px);
  margin-left: 3px;
  background-image: url(../assets/牛肉2.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.fix3 {
  height: 55vh;
  width: calc(50% - 3px);
  margin-right: 3px;
  background-image: url(../assets/牛肉3.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.fix4 {
  height: 55vh;
  width: calc(50% - 3px);
  margin-left: 3px;
  background-image: url(../assets/牛肉5.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
