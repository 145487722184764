<template>
  <!-- 这是上半部分主体 -->
  <div class="container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <!-- 图片部分 -->
      <div class="fx-h">
        <div class="header">
          <img
            src="@/assets/行情.jpg"
            alt="1"
            width="100%"
            height="100%"
            class="background-image" />
          <h1 class="text-overlay">
            进 口 数 据
          </h1>
          <br />
          <h3 class="text-overlay2">掌握市场动态，领先一步。</h3>
        </div>
      </div>
      <!-- 这是下半部分 -->
      <div class="row-b1">
        <div class="wp">
          <!-- 左边部分 -->
          <div class="col-l">
            <div class="content-main-left">
              <div class="work_list clearfix">
                <div class="my-custom-collapse">
                  <div
                    class="my-custom-collapse-item"
                    :class="{ 'is-active': isActive['1'] }"
                    @click="toggleActive('1')">
                    <div class="header"  @click="selectContent('进口期货行情')"
                    :class="{ 'active': selectedContent === '进口期货行情' }">
                      <div class="circle"></div>
                      进口期货行情
                      <!-- <i class="bi bi-chevron-down"></i> -->
                    </div>
                    <!-- <div class="content" :style="{ display: isActive['1'] ? 'block' : 'none' }">
                      <div>
                        <div class="item-child">
                          <div class="dot-line">
                            <div class="line"></div>
                            <div class="dot"></div>
                            <div class="line"></div>
                          </div>
                          <span class="hand" @click.prevent="goToFutures">实时</span>
                        </div>
                        <div class="item-child">
                          <div class="dot-line">
                            <div class="line"></div>
                            <div class="dot"></div>
                            <div class="line"></div>
                          </div>
                          <span class="hand" @click.prevent="goToFutures">跌涨</span>
                        </div>
                        <div class="item-child">
                          <div class="dot-line">
                            <div class="line"></div>
                            <div class="dot"></div>
                            <div class="line"></div>
                          </div>
                          <span class="hand" @click.prevent="goToFutures">走势</span>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <!-- 第二个可折叠项 -->
                  <div
                    class="my-custom-collapse-item"
                    :class="{ 'is-active': isActive['2'] }"
                    @click="toggleActive('2')">
                    <div class="header"  @click="selectContent('进口现货行情')"
                    :class="{ 'active': selectedContent === '进口现货行情' }">
                      <div class="circle"></div>
                      进口现货行情
                      <!-- <i class="bi bi-chevron-down"></i> -->
                    </div>
                    <!-- <div class="content" :style="{ display: isActive['2'] ? 'block' : 'none' }">
                      <div>
                        <div class="item-child">
                          <div class="dot-line">
                            <div class="line"></div>
                            <div class="dot"></div>
                            <div class="line"></div>
                          </div>
                          <span class="hand" @click.prevent="goToSpot">实时</span>
                        </div>
                        <div class="item-child">
                          <div class="dot-line">
                            <div class="line"></div>
                            <div class="dot"></div>
                            <div class="line"></div>
                          </div>
                          <span class="hand" @click.prevent="goToSpot">跌涨</span>
                        </div>
                        <div class="item-child">
                          <div class="dot-line">
                            <div class="line"></div>
                            <div class="dot"></div>
                            <div class="line"></div>
                          </div>
                          <span class="hand" @click.prevent="goToSpot">走势</span>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <!-- 第三个可折叠项 -->
                  <div
                    class="my-custom-collapse-item"
                    :class="{ 'is-active': isActive['3'] }"
                    @click="toggleActive('3')">
                    <div class="header" @click="selectContent('国产行情')"
                    :class="{ 'active': selectedContent === '国产行情' }">
                      <div class="circle"></div>
                      国产行情
                      <!-- <i class="bi bi-chevron-down"></i> -->
                    </div>
                    <!-- <div class="content" :style="{ display: isActive['3'] ? 'block' : 'none' }">
                      <div>
                        <div class="item-child">
                          <div class="dot-line">
                            <div class="line"></div>
                            <div class="dot"></div>
                            <div class="line"></div>
                          </div>
                          <span class="hand" @click.prevent="goToDomestic">实时</span>
                        </div>
                        <div class="item-child">
                          <div class="dot-line">
                            <div class="line"></div>
                            <div class="dot"></div>
                            <div class="line"></div>
                          </div>
                          <span class="hand" @click.prevent="goToDomestic">跌涨</span>
                        </div>
                        <div class="item-child">
                          <div class="dot-line">
                            <div class="line"></div>
                            <div class="dot"></div>
                            <div class="line"></div>
                          </div>
                          <span class="hand" @click.prevent="goToDomestic">走势</span>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右边部分 -->
          <div class="col-r">
            <div class="g-titIns1">{{selectedContent}}</div>
            <div class="m-chanpin">
              <div class="supplier-management">
                <!-- 最上面的按钮 -->
                <div class="select-date">
                  <input type="date" v-model="defaultDate" />
                  <button @click="accessManagementSystem">卍 接入供应商管理系统</button>
                </div>
                <!-- 中间的日期 -->
                <div class="calendar-container">
                  <div class="week-days">
                    <div
                      class="week-day"
                      v-for="day in weekDays"
                      :key="day"
                      :class="{ today: day === dayOfWeek }">
                      {{ day }}
                    </div>
                  </div>
                  <div class="dates">
                    <div class="date" v-for="(date, index) in weekDates" :key="index">
                      {{ date.getDate() }}
                    </div>
                  </div>
                </div>
                <!-- 下方的搜索 -->
                <div class="search-filters">
                  <div class="search-country">
                    <div class="filter-item">
                      <span class="filter-label">国家 ▼</span>
                    </div>
                    <input type="text" class="search-input" placeholder="搜索国家" v-model="selectedCountry"/>
                    <i class="el-icon-search search-icon"></i>
                  </div>
                  <div class="search-type">
                    <div class="filter-item">
                      <span class="filter-label">分类 ▼</span>
                    </div>
                    <input type="text" class="search-input" placeholder="搜索分类" v-model="selectedType"/>
                    <i class="el-icon-search search-icon"></i>
                  </div>
                  <!-- <div class="search-position">
                    <div class="filter-item">
                      <span class="filter-label">部位 ▼</span>
                    </div>
                    <input type="text" class="search-input" placeholder="搜索国家分类" />
                    <i class="bi bi-search"></i>
                  </div> -->
                </div>
                <!-- 下部分的表格 -->
                <div id="app" v-if="selectedContent === '进口现货行情'">
                  <line-chart></line-chart>
                </div>
                <!-- 下部分的表格 -->
                <table v-if="selectedContent === '进口期货行情'">
                  <thead>
                    <tr class="tr-th">
                      <th>产地</th>
                      <th>部位</th>
                      <th>价格</th>
                      <th>等级</th>
                      <th>涨幅</th>
                      <th>趋势</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in filteredItems" :key="item.id" class="tr-td">
                      <td>{{ item.company }}</td>
                      <td>{{ item.factory }}</td>
                      <td>{{ item.country }}</td>
                      <td>{{ item.classification }}</td>
                      <td style="color: green;">{{ item.approvedTime }}</td>
                      <td style="color: red;">{{ item.factorystatus }}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- 下部分的表格 -->
                <table v-if="selectedContent === '国产行情'">
                  <thead>
                    <tr class="tr-th">
                      <th>公司</th>
                      <th>工厂</th>
                      <th>国家</th>
                      <th>分类</th>
                      <th>获批时间</th>
                      <th>工厂状态</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in filteredItems1" :key="item.id" class="tr-td">
                      <td>{{ item.company }}</td>
                      <td>{{ item.factory }}</td>
                      <td>{{ item.country }}</td>
                      <td>{{ item.classification }}</td>
                      <td>{{ item.approvedTime }}</td>
                      <td style="color: green;">{{ item.factorystatus }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="notice">数据源自不完全统计，如有错误请联系！</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent,ref} from "vue";
import Video from "@/components/Video.vue";
import Bar from "@/components/NavBar.vue";

import LineChart from "@/components/LineChart.vue";

interface IActiveMap {
  "1"?: boolean;
  "2"?: boolean;
  "3"?: boolean;
  // 可以根据需要添加更多键
}
export default defineComponent({
  name: "Spot",
  components: {
    Video,
    Bar,
    LineChart,
  },
  setup() {
    const selectedContent = ref("进口期货行情");
    // 选择内容的方法
    function selectContent(content: string) {
      selectedContent.value = content;
      
    }
    return {
      selectedContent,
      selectContent,
    };
  },
  computed: {
    // 使用计算属性来获取并格式化当前日期和时间
    weekDates() {
      // 这里只是一个简化的示例，它只返回一个包含当前日期所在周几天的数组
      const now = new Date();
      const startDay = now.getDay(); // 获取当前是周几（0-6）
      const daysInWeek:Date[] = [];
      for (let i = 0; i < 7; i++) {
        const day = new Date(now);
        day.setDate(now.getDate() - startDay + i); // 调整日期到当前周的第一天开始
        daysInWeek.push(day);
      }
      return daysInWeek;
    },
    // 使用计算属性来确定今天是星期几
    dayOfWeek() {
      const weekDays = ["日", "一", "二", "三", "四", "五", "六"];
      const now = new Date();
      return weekDays[now.getDay()];
    },
    filteredItems() {
      return this.items.filter((item) => {
        if (this.selectedCountry) {  
          return item.company.toLowerCase().includes(this.selectedCountry.toLowerCase());  
        }  
        if (this.selectedType) {    
          return item.factory.toLowerCase().includes(this.selectedType.toLowerCase());  
        }
        return true;
      });
    },
    filteredItems1() {
      return this.itemss.filter((item) => {
        if (this.selectedCountry) {  
          return item.country.toLowerCase().includes(this.selectedCountry.toLowerCase());  
        }  
        if (this.selectedType) {    
          return item.classification.toLowerCase().includes(this.selectedType.toLowerCase());  
        }
        return true;
      });
    },
  },
  data() {
    return {
      isActive: {
        // 使用对象来跟踪每个可折叠项的激活状态
        "1": false,
        "2": true,
        "3": false,
      },
      weekDays: ["日", "一", "二", "三", "四", "五", "六"],
      // 顶部日期
      defaultDate: new Date().toISOString().substr(0, 10),
      // 示例数据
      items: [
        {
          id: 1,
          company: "巴西",
          factory: "牛肉",
          country: "5543",
          classification: "进口/高级",
          approvedTime: "+0.21%",
          factorystatus: "↑",
        },
        {
          id: 2,
          company: "美国",
          factory: "牛肉",
          country: "5000",
          classification: "进口/高级",
          approvedTime: "+0.22%",
          factorystatus: "↑",
        },
        {
          id: 3,
          company: "乌拉圭",
          factory: "牛肉",
          country: "5200",
          classification: "进口/高级",
          approvedTime: "+0.22%",
          factorystatus: "↑",
        },
        {
          id: 4,
          company: "法国",
          factory: "牛肉",
          country: "5320",
          classification: "进口/高级",
          approvedTime: "+0.22%",
          factorystatus: "↑",
        },
        {
          id: 5,
          company: "澳大利亚",
          factory: "牛肉",
          country: "5330",
          classification: "进口/高级",
          approvedTime: "+0.25%",
          factorystatus: "↑",
        },
        {
          id: 6,
          company: "英国",
          factory: "牛肉",
          country: "5730",
          classification: "进口/高级",
          approvedTime: "+0.25%",
          factorystatus: "↑",
        },
        {
          id: 7,
          company: "西班牙",
          factory: "牛肉",
          country: "5233",
          classification: "进口/高级",
          approvedTime: "+0.25%",
          factorystatus: "↑",
        },
        {
          id: 8,
          company: "德国",
          factory: "牛肉",
          country: "5030",
          classification: "进口/高级",
          approvedTime: "+0.25%",
          factorystatus: "↑",
        },
      ],
      itemss: [
        {
          id: 1,
          company: "好心冻",
          factory: "2543",
          country: "中国",
          classification: "牛肉",
          approvedTime: "2024-07-22",
          factorystatus: "合格",
        },
        {
          id: 2,
          company: "好心冻",
          factory: "大红门",
          country: "中国",
          classification: "牛副",
          approvedTime: "2024-07-22",
          factorystatus: "合格",
        },
        {
          id: 3,
          company: "好心冻",
          factory: "797",
          country: "中国",
          classification: "牛骨类",
          approvedTime: "2024-07-22",
          factorystatus: "合格",
        },
        {
          id: 4,
          company: "好心冻",
          factory: "4311",
          country: "中国",
          classification: "牛肉",
          approvedTime: "2024-07-22",
          factorystatus: "合格",
        },
        {
          id: 5,
          company: "好心冻",
          factory: "2924",
          country: "中国",
          classification: "牛肉",
          approvedTime: "2024-07-22",
          factorystatus: "合格",
        },
        {
          id: 6,
          company: "好心冻",
          factory: "177",
          country: "中国",
          classification: "牛肉",
          approvedTime: "2024-07-22",
          factorystatus: "合格",
        },
        {
          id: 7,
          company: "好心冻",
          factory: "4425",
          country: "中国",
          classification: "猪肉",
          approvedTime: "2024-07-22",
          factorystatus: "合格",
        },
        {
          id: 8,
          company: "好心冻",
          factory: "新希望",
          country: "中国",
          classification: "猪肉",
          approvedTime: "2024-07-22",
          factorystatus: "合格",
        },
      ],
      selectedCountry: "",
      selectedType: "",
      //显示
      isShow: false,
    };
  },
  methods: {
    //搜索
    toggleActive(name: keyof IActiveMap) {
      // 切换指定名称的可折叠项的激活状态
      this.isActive[name] = !this.isActive[name];
    },
    //接入供应商管理系统
    accessManagementSystem() {
      // 这里可以添加代码来处理接入供应商管理系统的逻辑
      alert("正在接入供应商管理系统...");
    },
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
  
});
</script>

<style scoped>
/* 上半部分 */
.container {
  /* width: 100vw; */
  max-width: 100%;
  position: relative;
  width: 100%;
}
.container .main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
/* 图片设置 */
.fx-h .header {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  position: relative;
}
.fx-h .text-overlay {
  position: absolute;
  top: 230px;
  font-size: 50px;
  color: white; /* 根据背景色设置合适的文字颜色 */
  font-weight: 500px;
}
.fx-h .text-overlay2 {
  position: absolute;
  top: 300px;
  font-size: 17px;
  color: white; /* 根据背景色设置合适的文字颜色 */
  font-weight: 500;
}
/* 下半部分 */
.row-b1 {
  overflow: hidden;
}
.wp {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
/* 左边 */
.row-b1 .col-l {
  width: 20vw;
  /* 显式地设置左边框 */
  border-left: 8px solid #e60012; /* 左边框宽度为5px，样式为实线，颜色为黑色 */
  /* 显式地设置右边框 */
  border-right: 1px solid #e60012; /* 右边框宽度为2px，样式为实线，颜色为黑色 */
}
.work_list {
  width: 100%;
  margin-bottom: 20px;
  /* 上下边框设置为0px（实际上，如果不设置它们，它们就默认为0px，但这里显式设置以表明意图） */
  border-top: 0px solid transparent; /* 宽度为0，颜色不影响显示 */
  border-bottom: 0px solid transparent; /* 同上 */
  /* 添加一些内边距以便更好地看到边框效果 */
}
.work_list .my-custom-collapse {
  float: left;
  align-items: center; /* 垂直居中 */
  cursor: pointer;
  line-height: 46px;
  background-size: auto 10px;
  width: 100%;
  font-size: 20px;
  float: left;
  /* 添加其他样式 */
}
.work_list .my-custom-collapse-item {
}
.work_list .my-custom-collapse-item .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: #fff;
  margin-right: 10px;
  margin-left: 30px;
}
.work_list .my-custom-collapse-item .bi-chevron-down {
  display: flex;
  margin-left: auto; /* 将元素推向右侧 */
  margin-right: 30px;
  cursor: pointer;
}

.work_list .my-custom-collapse-item .header {
  display: flex;
  align-items: center; /* 垂直居中 */
}
.work_list .my-custom-collapse-item .content {
}
.work_list .item-child {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-left: 35px;
  font-size: 14px;
}
.work_list .dot-line {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.work_list .line {
  width: 2px;
  height: 10px;
  background: #e8e8e7;
}
.work_list .dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #e8e8e7;
  margin: 5px 0;
}
.work_list .line {
  width: 2px;
  height: 10px;
  background: #e8e8e7;
}
.work_list .hand:hover {
  color: red;
  transition: background-color 1s ease-in-out; /* 添加背景颜色变化的过渡效果 */
}
.work_list .hand {
  padding-left: 20px;
  font-size: 16px;
}

/* ========================= */

/* 右边 */
.row-b1 .col-r {
  float: none;
  overflow: hidden;
  margin-left: 10px;
  width: 80%;
}
.g-titIns1 {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 44px;
  line-height: 44px;
  font-size: 40px;
  color: black;
  font-weight: normal;
  position: relative;
}
.m-chanpin {
  overflow: hidden;
  margin-left: 30px;
}
.supplier-management {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 40px;
  position: relative;
}
/* 上半部分 */
/* 按钮 和日期选择*/
.select-date {
  display: flex;
  height: 35px;
}
.select-date input {
  cursor: pointer;
  border: 1px solid #f28088;
  border-radius: 50px;
  background-color: #fff;
  padding: 10px 30px 10px 30px;
  margin-right: 30px;
}
.select-date button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f28088;
  border-radius: 50px;
  background-color: #fff;
  padding: 10px 30px 10px 30px;
  margin-right: 30px;
}
/* 日期 */
.calendar-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.week-days {
  display: flex;
  margin-bottom: 10px;
}
.week-day {
  width: 80px;
  margin-top: 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(149, 146, 146);
  font-weight: bold;
  margin-right: 5px;
}
.week-day.today {
  background-color: #ff8888; /* 今天是更深的红色 */
  color: white;
}
.dates {
  display: flex;
  margin-bottom: 10px;
}
.date {
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
/* 搜索框 */
.search-filters {
  padding-top: 20px;
  width: 100%;
  display: flex;
}
.search-filters .search-country {
  display: flex;
  border: 1px solid #f28088;
  border-radius: 10px;
}
.search-filters .search-type {
  display: flex;
  border: 1px solid #f28088;
  margin-left: 50px;
  border-radius: 10px;
}
.search-filters .search-position {
  display: flex;
  border: 1px solid #f28088;
  margin-left: 50px;
  border-radius: 10px;
}
.search-filters .filter-item {
  display: flex;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-filters .filter-label {
  font-size: 14px;
  margin-left: 10px;
  width: 60px;
  height: 100%;
  border-right: 1px solid #f28088;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-filters .search-input {
  width: 150px; /* 宽度占满容器 */
  height: 30px;
  margin-left: 10px;
  border-radius: 10px;
  /* 去掉边框 */
  border: none;
  border-style: none; /* 某些情况下可能需要这个来确保没有边框 */
  /* 去掉获得焦点时的轮廓（outline） */
  outline: none;
}
/* .search-filters .bi {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
} */
 .search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  margin-right: 15px;
}
/* 下半部分表单,表格展示 */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  width: 90%;
  border: 1px solid #f28088;
  margin-bottom: 40px;
}
/* 下半部分表单,表格展示 */
table {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 8px 30px 8px 30px; /* 增加内边距以分隔单元格 */
  text-align: left;
  text-align: center;
  width: 100%;
}
.tr-th {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  margin-top: 20px;
  border-radius: 5px;
}
.tr-td {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  margin-top: 10px;
  border: 1px solid #f28088;
  border-radius: 5px;
}
.active {  
  background-color: #e60012;
} 
.notice{
  color: grey;
  right: 0px;
  position: absolute;
}
</style>
