<template>
  <div class="any">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <div class="header">
        <div class="title fx-h">
          <h1 class="title-text">产 品</h1>
          <br />
          <h3 class="title-text">冷冻锁住新鲜，美味尽在口中</h3>
        </div>
      </div>
      <div class="BI2 fx-r">
        <div class="col-l">
          <div class="work_list">
            <div class="tit" 
              @click="selectContent('鸡产品')" 
              :class="{ active: selectedContent === '鸡产品' }"
            >
              <div class="circle"></div>
              <span>鸡产品</span>
            </div>
            <div class="tit" 
              @click="selectContent('鸭产品')" 
              :class="{ active: selectedContent === '鸭产品' }"
            >
              <div class="circle"></div>
              <span>鸭产品</span>
            </div>
            <div class="tit" 
              @click="selectContent('猪产品')" 
              :class="{ active: selectedContent === '猪产品' }"
            >
              <div class="circle"></div>
              <span>猪产品</span>
            </div>
            <div class="tit" 
              @click="selectContent('牛产品')" 
              :class="{ active: selectedContent === '牛产品' }"
            >
              <div class="circle"></div>
              <span>牛产品</span>
            </div>
            <div class="tit" 
              @click="selectContent('羊产品')" 
              :class="{ active: selectedContent === '羊产品' }"
            >
              <div class="circle"></div>
              <span>羊产品</span>
            </div>
            <div class="tit" 
              @click="selectContent('兔产品')" 
              :class="{ active: selectedContent === '兔产品' }"
            >
              <div class="circle"></div>
              <span>兔产品</span>
            </div>
            <div class="tit" 
              @click="selectContent('鹅产品')" 
              :class="{ active: selectedContent === '鹅产品' }"
            >
              <div class="circle"></div>
              <span>鹅产品</span>
            </div>
            <div class="tit" 
              @click="selectContent('其他肉类')" 
              :class="{ active: selectedContent === '其他肉类' }"
            >
              <div class="circle"></div>
              <span>其他肉类</span>
            </div>
          </div>
        </div>
        <div class="BI22 content-area">
          <!-- 根据 selectedContent 显示内容 -->
          <div v-if="selectedContent === '鸡产品'" class="fx-h">
            <div class="right-title">
              <div class="right-title-border">
                <span class="text-b">冷冻肉类</span>
              </div>
            </div>
            <div class="right-content fx-h">
              <!-- 每一行 -->
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgji-1"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>整鸡</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgji-2"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>琵琶腿</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgji-3"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸡脚</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgji-4"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>翅中</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgji-5"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸡胗</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgji-6"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>翅尖</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgji-7"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>凤爪</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgji-8"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸡全翅</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgji-9"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸡全腿</span>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 50px"></div>
            </div>
          </div>
          <div v-else-if="selectedContent === '鸭产品'" class="fx-h">
            <div class="right-title">
              <div class="right-title-border">
                <span class="text-b">冷冻肉类</span>
              </div>
            </div>
            <div class="right-content fx-h">
              <!-- 每一行 -->
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgya-1"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>整鸭</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgya-2"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸭翅根</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgya-3"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸭舌</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgya-4"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>白条鸭</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgya-5"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸭心</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgya-6"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸭食管</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgya-7"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸭全翅</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgya-8"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸭头</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgya-9"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸭掌</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgya-10"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸭血</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgya-11"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>生鸭肠</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgya-12"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸭边腿</span>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 50px"></div>
            </div>
          </div>
          <div v-else-if="selectedContent === '猪产品'" class="fx-h">
            <div class="right-title">
              <div class="right-title-border">
                <span class="text-b">冷冻肉类</span>
              </div>
            </div>
            <div class="right-content fx-h">
              <!-- 每一行 -->
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgzhu-1"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>猪肋排</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgzhu-2"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>猪耳</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgzhu-3"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>猪脑</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgzhu-4"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>短猪脚</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgzhu-5"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>猪心</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgzhu-6"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>带皮五花肉</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgzhu-7"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>猪大肠</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgzhu-8"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>猪手</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgzhu-9"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>猪圆肚</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgzhu-10"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>猪肘</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgzhu-11"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>猪腰</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgzhu-12"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>猪颈骨</span>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 50px"></div>
            </div>
          </div>
          <div v-else-if="selectedContent === '牛产品'" class="fx-h">
            <div class="right-title">
              <div class="right-title-border">
                <span class="text-b">冷冻肉类</span>
              </div>
            </div>
            <div class="right-content fx-h">
              <!-- 每一行 -->
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgniu-1"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛百叶</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgniu-2"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛板腱</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgniu-3"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛腩</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgniu-4"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛霖</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgniu-5"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛柳</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgniu-6"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛嫩肩</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgniu-7"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛前</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgniu-8"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛金钱肚</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgniu-9"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛肚</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgniu-10"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛尾</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgniu-11"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛舌</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgniu-12"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>牛前腱</span>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 50px"></div>
            </div>
          </div>
          <div v-else-if="selectedContent === '羊产品'" class="fx-h">
            <div class="right-title">
              <div class="right-title-border">
                <span class="text-b">冷冻肉类</span>
              </div>
            </div>
            <div class="right-content fx-h">
              <!-- 每一行 -->
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgyang-1"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>整羊</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgyang-2"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊蹄</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgyang-3"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊头</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgyang-4"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊心</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgyang-5"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊四分体</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgyang-6"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊前腿</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgyang-7"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊后腿</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgyang-8"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊肉串</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgyang-9"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊酮体</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgyang-10"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊肉卷</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgyang-11"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊排</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgyang-12"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>羊尾</span>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 50px"></div>
            </div>
          </div>
          <div v-else-if="selectedContent === '兔产品'" class="fx-h">
            <div class="right-title">
              <div class="right-title-border">
                <span class="text-b">冷冻肉类</span>
              </div>
            </div>
            <div class="right-content fx-h">
              <!-- 每一行 -->
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgtu-1"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>整兔</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgtu-2"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>兔头</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgtu-3"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>兔前腿</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgtu-4"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>兔后腿</span>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 50px"></div>
            </div>
          </div>
          <div v-else-if="selectedContent === '鹅产品'" class="fx-h">
            <div class="right-title">
              <div class="right-title-border">
                <span class="text-b">冷冻肉类</span>
              </div>
            </div>
            <div class="right-content fx-h">
              <!-- 每一行 -->
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imge-1"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>白条鹅</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imge-2"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鹅肝</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imge-3"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鹅头</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imge-4"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鹅胗</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imge-5"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>三节翅</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imge-6"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鹅肠</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imge-7"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鹅翅</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imge-8"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鹅心</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imge-9"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鹅掌</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imge-10"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鹅脖子</span>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 50px"></div>
            </div>
          </div>
          <div v-else-if="selectedContent === '其他肉类'" class="fx-h">
            <div class="right-title">
              <div class="right-title-border">
                <span class="text-b">冷冻肉类</span>
              </div>
            </div>
            <div class="right-content fx-h">
              <!-- 每一行 -->
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgit-1"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>藏香猪</span>
                  </div>
                </div>
                <div class="right-content-row-two fx-h">
                  <div class="right-content-row-top imgit-2"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>驴肉</span>
                  </div>
                </div>
                <div class="right-content-row-three fx-h">
                  <div class="right-content-row-top imgit-3"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>马肉</span>
                  </div>
                </div>
                <div class="right-content-row-four fx-h">
                  <div class="right-content-row-top imgit-4"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>鸵鸟肉</span>
                  </div>
                </div>
              </div>
              <div class="right-content-row fx-r">
                <div class="right-content-row-one fx-h">
                  <div class="right-content-row-top imgit-5"></div>
                  <div class="right-content-row-bottom text-s">
                    <span>天鹅肉</span>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 50px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";
import Video from "@/components/Video.vue";
import { ref } from "vue";
export default {
  name: "MeatFrozen",
  components: {
    Video,
    Bar,
  },
  setup() {
    const selectedContent = ref("鸡产品");
    // 选择内容的方法
    function selectContent(content) {
      selectedContent.value = content;
    }
    return {
      selectedContent,
      selectContent,
    };
  },
  data() {
    return {
      //显示
      isShow: false,
    };
  },
  methods: {
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
};
</script>

<style scoped>
.any {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.header {
  height: 500px;
  overflow-x: hidden;
  background-image: url("@/assets/产品.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title h1{
  font-size: 50px;
}
.title h3{
  font-size: 17px;
}
.title-text {
  color: white;
  font-weight: 500px;
}

.col-l {
  width: 20vw;
  /* 显式地设置左边框 */
  border-left: 8px solid #e60012; /* 左边框宽度为5px，样式为实线，颜色为黑色 */
  /* 显式地设置右边框 */
  border-right: 1px solid #e60012; /* 右边框宽度为2px，样式为实线，颜色为黑色 */
}
.work_list {
  width: 100%;
  margin-bottom: 20px;
  /* 上下边框设置为0px（实际上，如果不设置它们，它们就默认为0px，但这里显式设置以表明意图） */
  border-top: 0px solid transparent; /* 宽度为0，颜色不影响显示 */
  border-bottom: 0px solid transparent; /* 同上 */
  /* 添加一些内边距以便更好地看到边框效果 */
}
.work_list .tit {
  display: flex;
  align-items: center; /* 垂直居中 */
  cursor: pointer;
  line-height: 44px;
  background-size: auto 10px;
  /* border-bottom: 1px solid #f2f2f2; */
  width: 100%;
  float: left;
  font-size: 20px;
}
.work_list .tit .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: #fff;
  display: flex;
  margin-right: 10px;
  margin-left: 30px;
}
.col-l .work_list .tit:hover {
  background-color: #e60012; /* 鼠标悬浮时背景变红 */
  transition: background-color 1s ease-in-out; /* 添加背景颜色变化的过渡效果 */
}


.active {
  background-color: #e60012;
}

.content-area {
  width: 78%;
  height: 100%;
  margin-left: 10px;
  padding: 0px;
}
.BI2 {
  width: 100%;
  overflow: hidden;
}
.BI21 {
  width: 20%;
  /* height: 100%; */
  border-right: 1px solid red;
  border-left: 8px solid red;
}
.BI22 {
  width: 78%;
  height: 100%;
}
.right-title {
  width: 100%;
  height: 30%;
}
.right-title-border {
  width: 18%;
  height: 50px;
  margin-top: 2%;
  margin-bottom: 1%;
  margin-left: 41%;
  border: 1px solid rgb(181, 180, 180);
  border-radius: 20px 20px 20px 20px;
  display: grid;
  place-items: center;
}
.right-content {
  width: 100%;
  height: 65%;
}
.right-content-row {
  width: 100%;
  height: 30%;
  margin-top: 2%;
}
.right-content-row-one {
  width: 20%;
  height: 300px;
  margin-left: 6%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-two {
  width: 20%;
  height: 300px;
  margin-left: 2%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-three {
  width: 20%;
  height: 300px;
  margin-left: 2%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-four {
  width: 20%;
  height: 300px;
  margin-left: 2%;
  margin-right: 6%;
  border: 1px solid red;
  border-radius: 5px 5px 5px 5px;
}
.right-content-row-top {
  width: 94%;
  height: 85%;
  margin-left: 3%;
  margin-right: 3%;
}
.right-content-row-bottom {
  width: 90%;
  height: 13%;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
}
.text-b {
  font-size: 30px;
  letter-spacing: 10px;
}
.text-s {
  font-size: 16px;
  letter-spacing: 5px;
  text-align: center;
}
.imgji-1 {
  background-image: url(@/assets/整鸡.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgji-2 {
  background-image: url(@/assets/琵琶腿.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgji-3 {
  background-image: url(@/assets/鸡脚.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgji-4 {
  background-image: url(@/assets/翅中.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgji-5 {
  background-image: url(@/assets/鸡胗.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgji-6 {
  background-image: url(@/assets/翅尖.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgji-7 {
  background-image: url(@/assets/凤爪.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgji-8 {
  background-image: url(@/assets/鸡全翅.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgji-9 {
  background-image: url(@/assets/鸡全腿.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-1 {
  background-image: url(@/assets/整鸭.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-2 {
  background-image: url(@/assets/鸭翅根.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-3 {
  background-image: url(@/assets/鸭舌.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-4 {
  background-image: url(@/assets/白条鸭.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-5 {
  background-image: url(@/assets/鸭心.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-6 {
  background-image: url(@/assets/鸭食管.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-7 {
  background-image: url(@/assets/鸭全翅.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-8 {
  background-image: url(@/assets/鸭头.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-9 {
  background-image: url(@/assets/鸭掌.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-10 {
  background-image: url(@/assets/鸭血.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-11 {
  background-image: url(@/assets/生鸭肠.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgya-12 {
  background-image: url(@/assets/鸭边腿.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-1 {
  background-image: url(@/assets/猪肋排.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-2 {
  background-image: url(@/assets/猪耳.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-3 {
  background-image: url(@/assets/猪脑.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-4 {
  background-image: url(@/assets/短猪脚.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-5 {
  background-image: url(@/assets/猪心.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-6 {
  background-image: url(@/assets/带皮五花肉.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-7 {
  background-image: url(@/assets/猪大肠.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-8 {
  background-image: url(@/assets/猪手.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-9 {
  background-image: url(@/assets/猪圆肚.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-10 {
  background-image: url(@/assets/猪肘.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-11 {
  background-image: url(@/assets/猪腰.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgzhu-12 {
  background-image: url(@/assets/猪颈骨.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-1 {
  background-image: url(@/assets/牛百叶.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-2 {
  background-image: url(@/assets/牛前腱.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-3 {
  background-image: url(@/assets/牛腩.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-4 {
  background-image: url(@/assets/牛霖.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-5 {
  background-image: url(@/assets/牛柳.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-6 {
  background-image: url(@/assets/牛嫰肩.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-7 {
  background-image: url(@/assets/牛前.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-8 {
  background-image: url(@/assets/牛金钱肚.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-9 {
  background-image: url(@/assets/牛肚.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-10 {
  background-image: url(@/assets/牛尾.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-11 {
  background-image: url(@/assets/牛舌.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgniu-12 {
  background-image: url(@/assets/牛前腱.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-1 {
  background-image: url(@/assets/整羊.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-2 {
  background-image: url(@/assets/羊蹄.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-3 {
  background-image: url(@/assets/羊头.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-4 {
  background-image: url(@/assets/羊心.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-5 {
  background-image: url(@/assets/羊四分体.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-6 {
  background-image: url(@/assets/羊前腿.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-7 {
  background-image: url(@/assets/羊后腿.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-8 {
  background-image: url(@/assets/羊肉串.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-9 {
  background-image: url(@/assets/羊酮体.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-10 {
  background-image: url(@/assets/羊肉卷.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-11 {
  background-image: url(@/assets/肉类羊排.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgyang-12 {
  background-image: url(@/assets/羊尾.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgtu-1 {
  background-image: url(@/assets/整兔.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgtu-2 {
  background-image: url(@/assets/兔头.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgtu-3 {
  background-image: url(@/assets/兔前腿.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgtu-4 {
  background-image: url(@/assets/兔后腿.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imge-1 {
  background-image: url(@/assets/白条鹅.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imge-2 {
  background-image: url(@/assets/鹅肝.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imge-3 {
  background-image: url(@/assets/鹅头.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imge-4 {
  background-image: url(@/assets/鹅胗.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imge-5 {
  background-image: url(@/assets/三节翅.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imge-6 {
  background-image: url(@/assets/鹅肠.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imge-7 {
  background-image: url(@/assets/鹅翅.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imge-8 {
  background-image: url(@/assets/鹅心.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imge-9 {
  background-image: url(@/assets/鹅掌.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imge-10 {
  background-image: url(@/assets/鹅脖子.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgit-1 {
  background-image: url(@/assets/藏香猪.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgit-2 {
  background-image: url(@/assets/驴肉.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgit-3 {
  background-image: url(@/assets/马肉.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgit-4 {
  background-image: url(@/assets/鸵鸟肉.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgit-5 {
  background-image: url(@/assets/天鹅肉.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
