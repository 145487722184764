var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parent-container"},[_c('Bar'),_vm._m(0),_c('div',{staticClass:"NIT-content fx-r"},[_vm._m(1),_c('div',{staticClass:"NIT-content-right"},[_c('div',{staticClass:"NIT-right-title fx-r"},[_c('div',{staticClass:"recent"},[_vm._v("近期最热文章")]),_c('div',{staticClass:"imageText fx-r"},[_c('span',{staticClass:"span-g"},[_vm._v("图文")]),_c('span',{staticClass:"span-I"},[_vm._v("|")]),_c('span',[_c('router-link',{staticClass:"span-v",attrs:{"to":"/VideoNews"}},[_vm._v("视频")])],1)])]),_c('div',{staticClass:"recommend fx-h"},[_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news2}},[_c('img',{attrs:{"src":require("@/assets/new2.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(2)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news7}},[_c('img',{attrs:{"src":require("@/assets/new7.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(3)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news4}},[_c('img',{attrs:{"src":require("@/assets/new4.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(4)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news5}},[_c('img',{attrs:{"src":require("@/assets/new5.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(5)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news6}},[_c('img',{attrs:{"src":require("@/assets/new6.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(6)]),_c('div',{staticClass:"see-more",on:{"click":_vm.news}},[_vm._v(" 查看更多 "),_c('img',{attrs:{"src":require("@/assets/MoreIcon.png"),"alt":""}})])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI1"},[_c('div',{staticClass:"BI11 fx-h"},[_c('h1',{staticClass:"BI111"},[_vm._v("新闻")]),_c('br'),_c('a',{staticClass:"BI111 h3"},[_vm._v("掌握市场动态，领先一步")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NIT-content-left fx-h"},[_c('div',{staticClass:"NIT-title"},[_c('h1',[_vm._v("海关总署：上半年我国跨境电商进出口1.22万亿元 同比增长10.5%")]),_c('span',[_vm._v("2024-07-30")]),_c('span',[_vm._v("12：30")]),_c('span',[_vm._v("来源 ：中国新闻网")])]),_c('div',{staticClass:"NIT-model1 fx-h"},[_c('div',{staticClass:"NIT-model1-photo fx-r"},[_c('img',{attrs:{"src":require("@/assets/new1-01.png"),"alt":"","width":"100%","height":"400px"}})]),_c('p',{staticClass:"note"},[_vm._v("（图片来源：互联网）")]),_c('p',{staticClass:"text"},[_vm._v(" 中新网7月30日电 国务院新闻办公室30日举行“推动高质量发展”系列主题新闻发布会，海关总署新闻发言人、统计分析司司长吕大良在会上透露，据海关统计，2023年我国跨境电商进出口较2018年增长了1.2倍；初步测算，今年上半年进出口1.22万亿元，同比增长10.5%。 ")]),_c('div',{staticClass:"NIT-model1-photo2 fx-r"},[_c('img',{attrs:{"src":require("@/assets/new1-02.png"),"alt":"","width":"100%","height":"400px"}})]),_c('p',{staticClass:"note"},[_vm._v("（图片来源：互联网）")]),_c('p',{staticClass:"text"},[_vm._v(" 吕大良表示，跨境电商的蓬勃发展，给海关监管服务带来了全新挑战。现在海关每年监管的跨境电商、跨境邮件快件包裹量达70多亿件，平均每天有2000万件左右。 ")]),_c('p',{staticClass:"text"},[_vm._v(" 对此，海关也不断创新监管方式，开发应用跨境电商通关服务系统，着力提升跨境电商通关效率。同时，也采取切实措施，确保通得快、管得住。比如，开展了跨境电商进口食品风险监测、消费品抽样监测行动，开展跨境电商寄递“异宠”综合治理专项行动等，保障了跨境电商商品质量安全，也防范违禁品入境。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("了解中国对农业发展的政策扶持")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("1月至3月阿根廷国内牛肉消费同比下降17.6% 出口仍保持增长")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("北海：2023年对越南外贸进出口总值59.3亿元")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("泰国将2024年大米出口预测上调至820万吨")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("4750亿美元！中国跌至第三，不再是美国第一大进口国")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
}]

export { render, staticRenderFns }