import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
// 加入我们
import JoinUs from "../views/join-us/JoinUs.vue";
import Customer from "../views/join-us/Customer.vue";
import Supplier from "../views/join-us/Supplier.vue";
import Business from "../views/join-us/Business.vue";
import Management from "../views/join-us/Management.vue";
import Coldchain from "../views/join-us/Coldchain.vue";
import Frozenstorage from "../views/join-us/Frozenstorage.vue";
import Freightforwarder from "../views/join-us/Freightforwarder.vue";
// 行情
import Quotation from "../views/quotation/Quotation.vue";
import Futures from "../views/quotation/Futures.vue";
import Spot from "../views/quotation/Spot.vue";
import Domestic from "../views/quotation/Domestic.vue";
// 进口数据
import Factoryadmission from "../views/importdata/Factoryadmission.vue";
import Importquantity from "../views/importdata/Importquantity.vue";
import ImportEV from "../views/importdata/ImportEV.vue";
// 渠道
import OnlineChannels from "@/views/channels/OnlineChannels.vue";
import OfflineChannels from "@/views/channels/OfflineChannels.vue";
import ModernChannels from "@/views/channels/ModernChannels.vue";
import CateringChannels from "@/views/channels/CateringChannels.vue";
import ExclusiveShop from "@/views/channels/ExclusiveShop.vue";
import KeyAccount from "@/views/channels/KeyAccount.vue";
// 食品研究院
import FoodResearch from "@/views/food/FoodResearch.vue";
import FoodTesting from "@/views/food/FoodTesting.vue";
import PesticideTesting from "@/views/food/PesticideTesting.vue";
import OrganicFood from "@/views/food/OrganicFood.vue";
import ComponentTesting from "@/views/food/ComponentTesting.vue";
import FoodTechnology from "@/views/food/FoodTechnology.vue";
import CateringFormat from "@/views/food/CateringFormat.vue";
// 产品
import ProductIntroduction from "@/views/product/ProductIntroduction.vue";
import MeatFrozen from "@/views/product/MeatFrozen.vue";
import FrozenWater from "@/views/product/FrozenWater.vue";
import FrozenSnack from "@/views/product/FrozenSnack.vue";
import FrozenDishes from "@/views/product/FrozenDishes.vue";
import FrozenConditioning from "@/views/product/FrozenConditioning.vue";
import FrozenVegetable from "@/views/product/FrozenVegetable.vue";
import RefrigeratedFruits from "@/views/product/RefrigeratedFruits.vue";
import HotpotProducts from "@/views/product/HotpotProducts.vue";
// 新闻
import GraphicNews from "@/views/new/GraphicNews.vue";
import VideoNews from "@/views/new/VideoNews.vue";
import VideoNews1 from "@/views/new/VideoNews1.vue";
import VideoNews2 from "@/views/new/VideoNews2.vue";
import VideoNews3 from "@/views/new/VideoNews3.vue";
import VideoNews4 from "@/views/new/VideoNews4.vue";
import VideoNews5 from "@/views/new/VideoNews5.vue";
import VideoNews6 from "@/views/new/VideoNews6.vue";
import VideoNews7 from "@/views/new/VideoNews7.vue";
import VideoNews8 from "@/views/new/VideoNews8.vue";
import VideoNews9 from "@/views/new/VideoNews9.vue";
import VideoNews10 from "@/views/new/VideoNews10.vue";
import NewsImageText1 from "@/views/new/NewsImageText1.vue";
import NewsImageText2 from "@/views/new/NewsImageText2.vue";
import NewsImageText3 from "@/views/new/NewsImageText3.vue";
import NewsImageText4 from "@/views/new/NewsImageText4.vue";
import NewsImageText5 from "@/views/new/NewsImageText5.vue";
import NewsImageText6 from "@/views/new/NewsImageText6.vue";
import NewsImageText7 from "@/views/new/NewsImageText7.vue";
import NewsImageText8 from "@/views/new/NewsImageText8.vue";
import NewsImageText9 from "@/views/new/NewsImageText9.vue";
import NewsImageText10 from "@/views/new/NewsImageText10.vue";
import NewsImageText11 from "@/views/new/NewsImageText11.vue";
import NewsImageText12 from "@/views/new/NewsImageText12.vue";
// import FoodEnd from "../views/FoodEnd.vue"
import { component } from "vue/types/umd";
//公司
import IntroductionView from "../views/company/IntroductionView.vue";
import AboutUs from "../views/company/AboutUs.vue";
import CompanyStructure from "@/views/company/CompanyStructure.vue";
import LegalAffairs from "@/views/company/LegalAffairs.vue";
import MediaView from "@/views/company/MediaView.vue";
import CompanyView from "@/views/company/CompanyView.vue";
import InvestorRelations from "@/views/company/InvestorRelations.vue";
import ContactUs from "@/views/company/ContactUs.vue";
//指数
import TrendView from "@/views/trend/TrendView.vue";
import FarmTrend from "@/views/trend/FarmTrend.vue";
import NewsImageText from "@/views/NewsImageText.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // 加入我们
  {
    path: "/JoinUs",
    name: "JoinUs",
    component: JoinUs,
  },
  // 加入我们--客户
  {
    path: "/JoinUs/Customer",
    name: "Customer",
    component: Customer,
  },
  // 加入我们--供应商
  {
    path: "/JoinUs/Supplier",
    name: "Supplier",
    component: Supplier,
  },
  // 加入我们--商家
  {
    path: "/JoinUs/Business",
    name: "Business",
    component: Business,
  },
  // 加入我们--资方
  {
    path: "/JoinUs/Management",
    name: "Management",
    component: Management,
  },
  // 加入我们--冷链物流
  {
    path: "/JoinUs/Coldchain",
    name: "Coldchain",
    component: Coldchain,
  },
  // 加入我们--冷冻仓储
  {
    path: "/JoinUs/Frozenstorage",
    name: "Frozenstorage",
    component: Frozenstorage,
  },
  // 加入我们--货代报关
  {
    path: "/JoinUs/Freightforwarder",
    name: "Freightforwarder",
    component: Freightforwarder,
  },

  // 进口数据--准入工厂
  {
    path: "/Importdata/Factoryadmission",
    name: "Factoryadmission",
    component: Factoryadmission,
  },
  // 进口数据--进口数量
  {
    path: "/Importdata/Importquantity",
    name: "Importquantity",
    component: Importquantity,
  },
  // 进口数据--进口水果蔬菜
  {
    path: "/Importdata/ImportEV",
    name: "ImportEV",
    component: ImportEV,
  },
  // 行情
  // {
  //   path: "/Quotation",
  //   name: "Quotation",
  //   component: Quotation,
  // },
  // 行情---进口期货行情
  {
    path: "/Quotation/Futures",
    name: "Futures",
    component: Futures,
  },
  // 行情---进口现货行情
  {
    path: "/Quotation/Spot",
    name: "Spot",
    component: Spot,
  },
  // 行情---国产
  {
    path: "/Quotation/Domestic",
    name: "Domestic",
    component: Domestic,
  },
  // 线上渠道
  {
    path: "/OnlineChannels",
    name: "online",
    component: OnlineChannels,
  },
  // 线下渠道-传统
  {
    path: "/OfflineChannels",
    name: "offline",
    component: OfflineChannels,
  },
  // 现代渠道
  {
    path: "/ModernChannels",
    name: "modern",
    component: ModernChannels,
  },
  // 餐饮渠道CateringChannels
  {
    path: "/CateringChannels",
    name: "catering",
    component: CateringChannels,
  },
  // 专卖店ExclusiveShop
  {
    path: "/ExclusiveShop",
    name: "exclusive",
    component: ExclusiveShop,
  },
  // 大客户KeyAccount
  {
    path: "/KeyAccount",
    name: "key",
    component: KeyAccount,
  },
  // 食品安全
  {
    path: "/FoodResearch",
    name: "food",
    component: FoodResearch,
  },
  // 食品检测
  {
    path: "/FoodTesting",
    name: "foodtest",
    component: FoodTesting,
  },
  // 农残检测
  {
    path: "/PesticideTesting",
    name: "pest",
    component: PesticideTesting,
  },
  // 有机食品
  {
    path: "/OrganicFood",
    name: "organic",
    component: OrganicFood,
  },
  // 成分检测
  {
    path: "/ComponentTesting",
    name: "component",
    component: ComponentTesting,
  },
  // 食品科技
  {
    path: "/FoodTechnology",
    name: "technology",
    component: FoodTechnology,
  },
  // 餐饮业态
  {
    path: "/CateringFormat",
    name: "catering",
    component: CateringFormat,
  },
  // 产品
  {
    path: "/ProductIntroduction",
    name: "product",
    component: ProductIntroduction,
  },
  // 肉
  {
    path: "/MeatFrozen",
    name: "meat",
    component: MeatFrozen,
  },
  //冷冻水产品
  {
    path: "/FrozenWater",
    name: "FrozenWater",
    component: FrozenWater,
  },
  //冷冻小吃面点
  {
    path: "/FrozenSnack",
    name: "FrozenSnack",
    component: FrozenSnack,
  },
  //冷冻预制菜
  {
    path: "/FrozenDishes",
    name: "FrozenDishes",
    component: FrozenDishes,
  },
  //冷冻调理品
  {
    path: "/FrozenConditioning",
    name: "FrozenConditioning",
    component: FrozenConditioning,
  },
  //冷冻果蔬菌类
  {
    path: "/FrozenVegetable",
    name: "FrozenVegetable",
    component: FrozenVegetable,
  },
  //冷藏进口shg
  {
    path: "/RefrigeratedFruits",
    name: "RefrigeratedFruits",
    component: RefrigeratedFruits,
  },
  //火锅产品
  {
    path: "/HotpotProducts",
    name: "HotpotProducts",
    component: HotpotProducts,
  },
  // 新闻
  {
    path: "/GraphicNews",
    name: "graphic",
    component: GraphicNews,
  },
  //图文1
  {
    path: "/NewsImageText1",
    name: "NewsImageText1",
    component: NewsImageText1,
  },
  //图文2
  {
    path: "/NewsImageText2",
    name: "NewsImageText2",
    component: NewsImageText2,
  },
  //图文3
  {
    path: "/NewsImageText3",
    name: "NewsImageText3",
    component: NewsImageText3,
  },
  //图文4
  {
    path: "/NewsImageText4",
    name: "NewsImageText4",
    component: NewsImageText4,
  },
  //图文5
  {
    path: "/NewsImageText5",
    name: "NewsImageText5",
    component: NewsImageText5,
  },
  //图文6
  {
    path: "/NewsImageText6",
    name: "NewsImageText6",
    component: NewsImageText6,
  },
  //图文7
  {
    path: "/NewsImageText7",
    name: "NewsImageText7",
    component: NewsImageText7,
  },
  //图文8
  {
    path: "/NewsImageText8",
    name: "NewsImageText8",
    component: NewsImageText8,
  },
  //图文9
  {
    path: "/NewsImageText9",
    name: "NewsImageText9",
    component: NewsImageText9,
  },
  //图文10
  {
    path: "/NewsImageText10",
    name: "NewsImageText10",
    component: NewsImageText10,
  },
  //图文11
  {
    path: "/NewsImageText11",
    name: "NewsImageText11",
    component: NewsImageText11,
  },
  //图文12
  {
    path: "/NewsImageText12",
    name: "NewsImageText12",
    component: NewsImageText12,
  },
  // 视频新闻
  {
    path: "/VideoNews",
    name: "video",
    component: VideoNews,
  },
  // 视频新闻1
  {
    path: "/VideoNews1",
    name: "video1",
    component: VideoNews1,
  },
  // 视频新闻2
  {
    path: "/VideoNews2",
    name: "video2",
    component: VideoNews2,
  },
  // 视频新闻3
  {
    path: "/VideoNews3",
    name: "video3",
    component: VideoNews3,
  },
  // 视频新闻4
  {
    path: "/VideoNews4",
    name: "video4",
    component: VideoNews4,
  },
  // 视频新闻5
  {
    path: "/VideoNews5",
    name: "video5",
    component: VideoNews5,
  },
  // 视频新闻6
  {
    path: "/VideoNews6",
    name: "video6",
    component: VideoNews6,
  },
  // 视频新闻7
  {
    path: "/VideoNews7",
    name: "video7",
    component: VideoNews7,
  },
  // 视频新闻8
  {
    path: "/VideoNews8",
    name: "video8",
    component: VideoNews8,
  },
  // 视频新闻9
  {
    path: "/VideoNews9",
    name: "video9",
    component: VideoNews9,
  },
  // 视频新闻10
  {
    path: "/VideoNews10",
    name: "video10",
    component: VideoNews10,
  },
  //公司
  {
    path: "/introduction",
    name: "Introduction",
    component: IntroductionView,
  },
  {
    path: "/about",
    name: "About",
    component: AboutUs,
  },
  {
    path: "/structure",
    name: "Structure",
    component: CompanyStructure,
  },
  {
    path: "/legal",
    name: "Legal",
    component: LegalAffairs,
  },
  {
    path: "/media",
    name: "Media",
    component: MediaView,
  },
  {
    path: "/company",
    name: "Company",
    component: CompanyView,
  },
  {
    path: "/investor",
    name: "Investor",
    component: InvestorRelations,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactUs,
  },
  //指数
  {
    path: "/trend",
    name: "Trend",
    component: TrendView,
  },
  {
    path: "/FarmTrend",
    name: "FarmTrend",
    component: FarmTrend,
  },
  {
    path: "/NewsImageText",
    name: "NewsImageText",
    component: NewsImageText,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
