<template>
  <div class="parent-container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <div class="header">
        <div>
          <img src="@/assets/logo.png" alt="" class="icon-overlay" />
          <p class="header-text">好 心 冻 科 技 有 限 公 司</p>
        </div>
      </div>
      <div class="content fx-r">
        <div class="sidebar">
          <ul>
            <router-link to="/introduction"
              ><li @click="selectContent('品牌介绍')">
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>品牌介绍</span>
                </div>
              </li></router-link
            >
            <router-link to="/about"
              ><li @click="selectContent('关于我们')">
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>关于我们</span>
                </div>
              </li></router-link
            >
            <router-link to="/structure"
              ><li
                @click="selectContent('公司架构')"
                style="background-color: #e60012"
              >
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>公司架构</span>
                </div>
              </li></router-link
            >
            <router-link to="/legal"
              ><li @click="selectContent('法务')">
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>法务</span>
                </div>
              </li></router-link
            >
            <router-link to="/media"
              ><li @click="selectContent('媒体')">
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>媒体</span>
                </div>
              </li></router-link
            >
            <router-link to="/investor"
              ><li @click="selectContent('投资者关系')">
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>投资者关系</span>
                </div>
              </li></router-link
            >
            <router-link to="/contact"
              ><li @click="selectContent('联系我们')">
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>联系我们</span>
                </div>
              </li></router-link
            >
          </ul>
        </div>
        <div class="content-area">
          <!-- 根据 selectedContent 显示内容 -->
          <div v-if="selectedContent === '品牌介绍'">
            <div class="page-name">
              <h2 class="name-text">品牌介绍</h2>
              <a class="name-En">Brand&nbsp;Introduction</a>
            </div>
            <div class="model-B1 fx-r">
              <div class="model-B1-article">
                好心动科技有限公司，一家专注于提供高品质冻品的企业，致力于为消费者带来安全、美味、便捷的食品体验。
              </div>
              <div class="BI01"></div>
            </div>
            <div class="model-B2 fx-r">
              <div class="BI02"></div>
              <div class="model-B2-article">
                我们的品牌理念是"品质至上，服务第一"。公司拥有严格的质量控制体系，从源头抓起，确保每一件产品都符合严格的质量标准
              </div>
            </div>
            <div class="model-B1 fx-r">
              <div class="model-B1-article">
                我们与众多优质供应商建立了长期合作关系，精选新鲜、优质的食材，采用先进的冷冻技术，保留食材的原汁原味和营养成分。
              </div>
              <div class="BI03"></div>
            </div>
            <div class="model-B2 fx-r">
              <div class="BI04"></div>
              <div class="model-B2-article">
                我们的产品种类丰富多样，涵盖了肉类、海鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是餐饮行业，我们都能满足您的需求。我们的产品不仅品质优良，而且价格合理，具有很高的性价比。
              </div>
            </div>
            <div class="model-B1 fx-r">
              <div class="model-B1-article">
                除了产品品质，我们还注重服务质量。我们拥有一支专业的销售团队和客服团队，能够为客户提供及时、周到的服务。
              </div>
              <div class="BI05"></div>
            </div>
            <div class="model-B2 fx-r">
              <div class="BI06"></div>
              <div class="model-B2-article">
                我们还提供定制化的解决方案，根据客户的需求提供个性化的产品和服务。
              </div>
            </div>
          </div>
          <div v-else-if="selectedContent === '公司架构'">
            <div class="page-name">
              <h2 class="name-text">公司架构</h2>
              <a class="name-En">Corporate&nbsp;Structure</a>
            </div>
            <div class="model-C1 fx-r">
              <div class="model-C1-left fx-h">
                <div class="title fx-r">
                  <div class="number">01</div>
                  <div class="article">总体结构</div>
                </div>
                <div class="model-C1-text fx-h">
                  <p>
                    我们的公司采用扁平化管理模式，旨在提高沟通效率和决策速度。公司主要分为以下几个部门：
                  </p>
                  <ul>
                    <li>管理层</li>
                    <li>生产部</li>
                    <li>质量控制部</li>
                    <li>销售与市场部</li>
                    <li>采购部</li>
                    <li>物流与仓储部</li>
                    <li>人力资源部</li>
                    <li>财务部</li>
                  </ul>
                </div>
              </div>
              <div class="logo fx-h">
                <img src="@/assets/C01.png" alt="" class="logo-bgi" />
                <img src="@/assets/logo.png" alt="" class="logo-overlay" />
              </div>
            </div>
            <div class="model-C2 fx-r">
              <div class="model-C2-left fx-h">
                <div class="title fx-r">
                  <div class="number">02</div>
                  <div class="article">各部门职责</div>
                </div>
                <div class="model-C2-text fx-h">
                  <h4>(1)管理层</h4>
                  <p>负责公司整体战略规划、决策和资源配置。</p>
                  <p>监督各部门的运营情况，确保公司目标的实现。</p>
                  <h4>(2)生产部</h4>
                  <p>负责冻品的生产和加工，确保生产流程的顺利进行。</p>
                  <p>维护生产设备，优化生产效率，降低生产成本。</p>
                  <h4>(3)质量控制部</h4>
                  <p>
                    负责产品质量的检测与控制，确保所有产品符合国家和行业标准。
                  </p>
                  <p>定期进行质量审核和改进措施的实施。</p>
                  <h4>(4)销售与市场部</h4>
                  <p>负责市场调研、销售策略的制定和实施，提升品牌知名度。</p>
                  <p>维护客户关系，处理客户反馈，提供优质的售后服务。</p>
                  <h4>(5)采购部</h4>
                  <p>负责原材料的采购，确保供应链的稳定和原材料的质量。</p>
                  <p>与供应商建立良好的合作关系，进行价格谈判。</p>
                  <h4>(6)物流与仓储部</h4>
                  <p>负责产品的仓储管理和物流配送，确保产品及时送达客户。</p>
                  <p>维护仓库的安全和整洁，优化库存管理。</p>
                  <h4>(7)人力资源部</h4>
                  <p>负责公司的人才招聘、培训和员工关系管理。</p>
                  <p>制定员工绩效考核和激励机制，提升员工满意度。</p>
                  <h4>(8)财务部</h4>
                  <p>负责公司的财务管理、预算编制和财务报表的制作。</p>
                  <p>进行成本控制和财务分析，确保公司财务健康。</p>
                </div>
              </div>
              <div class="C02"></div>
            </div>
          </div>
          <div v-else-if="selectedContent === '法务'">
            <div class="L-title fx-h">
              <h2>好心动科技有限公司法务管理制度</h2>
              <p>
                <span>文件编号 ：FZ-2023-001</span>
                <span>版本 ：1.0</span>
                <span>生效日期 ：2023年10月01日</span>
                <span>编制单位 ：法务部</span>
              </p>
            </div>
            <div class="model-L1 fx-h">
              <div class="title fx-r">
                <div class="number">01</div>
                <div class="article">引言</div>
              </div>
              <div class="model-L1-text">
                <p>
                  为确保冻品公司的合法合规经营，维护公司的合法权益，特制定本法务管理制度。
                </p>
              </div>
            </div>
            <div class="model-L2 fx-h">
              <div class="title fx-r">
                <div class="number">02</div>
                <div class="article">法律合规</div>
              </div>
              <div class="model-L2-text fx-h">
                <h4>1.法律法规概述</h4>
                <p>
                  （1）本公司需遵守《食品安全法》、《反不正当竞争法》、《合同法》等相关法律法规。
                </p>
                <h4>2.合规要求</h4>
                <p>（1）所有产品必须符合国家食品安全标准，定期进行质量检测。</p>
                <p>（2）进出口业务需遵循相关海关法规，确保报关手续的合法性。</p>
              </div>
            </div>
            <div class="model-L2 fx-h">
              <div class="title fx-r">
                <div class="number">03</div>
                <div class="article">合同管理</div>
              </div>
              <div class="model-L2-text fx-h">
                <h4>1.合同审核流程</h4>
                <p>
                  （1）合同由业务部门起草，法务部进行审核，确保合同条款合法合规。
                </p>
                <p>（2）合同审核需在签署前完成，审核时间不少于3个工作日。</p>
                <h4>2.合同模板</h4>
                <p>
                  （1）提供标准采购合同、销售合同、运输合同等模板，供各部门使用。
                </p>
                <h4>3.违约处理</h4>
                <p>
                  （1）合同一方违约时，另一方有权要求赔偿损失，具体赔偿金额根据合同约定执行。
                </p>
              </div>
            </div>
            <div class="model-L2 fx-h">
              <div class="title fx-r">
                <div class="number">04</div>
                <div class="article">知识产权保护</div>
              </div>
              <div class="model-L2-text fx-h">
                <h4>1.商标注册</h4>
                <p>（1）公司商标应及时注册，确保商标的合法性和独占性。</p>
                <h4>2.专利申请</h4>
                <p>
                  （1）对于新产品、新技术，及时申请专利，保护公司的创新成果。
                </p>
                <h4>3.版权保护</h4>
                <p>
                  （1）公司宣传材料、产品说明书等需进行版权登记，防止侵权行为。
                </p>
              </div>
            </div>
            <div class="model-L2 fx-h">
              <div class="title fx-r">
                <div class="number">05</div>
                <div class="article">风险控制</div>
              </div>
              <div class="model-L2-text fx-h">
                <h4>1.风险识别</h4>
                <p>（1）定期评估法律风险，包括产品责任、合同纠纷等。</p>
                <h4>2.风险应对措施</h4>
                <p>
                  （1）针对识别出的风险，制定相应的应对策略，如投保商业保险等。
                </p>
              </div>
            </div>
            <div class="model-L2 fx-h">
              <div class="title fx-r">
                <div class="number">06</div>
                <div class="article">争议解决</div>
              </div>
              <div class="model-L2-text fx-h">
                <h4>1.争议处理流程</h4>
                <p>
                  （1）内部争议应首先通过调解解决，若无法解决，可向法律顾问寻求帮助。
                </p>
                <h4>2.法律顾问</h4>
                <p>（1）指定法律顾问为张律师，联系方式：123-4567-8901。</p>
              </div>
            </div>
            <div class="model-L2 fx-h">
              <div class="title fx-r">
                <div class="number">07</div>
                <div class="article">附录</div>
              </div>
              <div class="model-L2-text fx-h">
                <h4>1.相关法律法规清单</h4>
                <p>（1）《食品安全法》.docx</p>
                <p>（2）《反不正当竞争法》.docx</p>
                <p>（3）《合同法》.docx</p>
                <h4>2.常用法律术语解释</h4>
                <p>（1）违约：合同一方未按照约定履行义务的行为。</p>
                <p>（2）赔偿：因违约或侵权造成损失时，依法要求赔偿的行为。</p>
              </div>
            </div>
          </div>
          <div v-else-if="selectedContent === '媒体'">
            <div class="page-name">
              <h2 class="name-text">媒体</h2>
              <a class="name-En">Media&nbsp;</a>
            </div>
            <div class="model-M1 fx-h">
              <div class="title fx-r">
                <div class="number">01</div>
                <div class="article">部门概述</div>
              </div>
              <div class="model-M1-text fx-h">
                <img
                  src="@/assets/M01.png"
                  alt=""
                  width="100%"
                  height="500px"
                />
                <p>
                  好心冻科技有限公司的媒体部门是公司品牌传播和公关管理的重要组成部分，致力于提升公司的市场知名度和行业影响力。该部门的主要职责包括媒体关系管理、新闻发布、市场推广、危机管理和内容创作等，旨在通过有效的沟通策略，增强公司与媒体及公众的互动。
                </p>
              </div>
            </div>
            <div class="model-M2 fx-h">
              <div class="title fx-r">
                <div class="number">02</div>
                <div class="article">职能与职责</div>
              </div>
              <div class="model-M2-text fx-h">
                <img src="@/assets/M02.png" alt="" width="100%" />
                <h4>1.媒体关系管理</h4>
                <p>
                  媒体关系管理是媒体部门的核心职能之一。部门团队与各类媒体保持密切联系，定期组织媒体见面会和产品发布会，确保公司最新动态和产品信息能够及时传达给目标受众。通过与行业媒体的合作，媒体部门能够有效地将公司的品牌形象和产品优势传播出去，提升公众的认知度。
                </p>
                <h4>2.新闻发布</h4>
                <p>
                  新闻发布是媒体部门的重要工作。部门负责撰写和发布新闻稿，涵盖公司重大事件、产品上市、行业动态等信息。通过精准的新闻传播，媒体部门能够引导舆论，塑造积极的公司形象。同时，部门还会定期进行媒体监测，分析报道效果，及时调整传播策略。
                </p>
                <h4>3.市场推广</h4>
                <p>
                  市场推广方面，媒体部门积极策划和执行各类宣传活动，包括线上线下的推广活动、社交媒体营销等。通过多渠道的宣传，媒体部门不仅提升了品牌的曝光率，还吸引了更多消费者的关注。此外，部门还注重内容创作，制作与冻品相关的行业报告、食谱和健康知识等，丰富媒体报道的内容，增强公众的参与感。
                </p>
                <h4>4.危机管理</h4>
                <p>
                  危机管理也是媒体部门的重要职责之一。在公司面临负面舆论或危机事件时，媒体部门会迅速制定应对策略，与媒体进行有效沟通，维护公司的公众形象，防止事态进一步恶化。
                </p>
                <h4>5.内容创作</h4>
                <p>
                  媒体部门由一支专业团队组成，团队成员具备丰富的媒体经验和专业知识，能够灵活应对各种媒体挑战。未来，媒体部门将继续拓展媒体合作渠道，提升品牌影响力，并加强数字媒体的运用，以更好地与年轻消费者建立联系。
                </p>
              </div>
            </div>
          </div>
          <div v-else-if="selectedContent === '投资者关系'">
            <div class="page-name">
              <h2 class="name-text">投资者关系</h2>
              <a class="name-En">Investor&nbsp;Relations</a>
            </div>
            <div class="model-I1 fx-h">
              <div class="model-I1-top fx-">
                <div class="I-title fx-r">
                  <div class="I-number">01</div>
                  <div class="I-article">财务报告</div>
                  <div class="download fx-r">
                    <img
                      src="@/assets/DownloadIcon.png"
                      alt=""
                      width="20px"
                      height="20px"
                    />
                    <span>下载报告</span>
                  </div>
                </div>
              </div>
              <div class="model-I1-content fx-h">
                <img
                  src="@/assets/Online2.png"
                  alt=""
                  width="100%"
                  height="600px"
                />
                <p>
                  本财务报告旨在总结公司2023年度的财务状况及经营成果。截止2023年12月31日，公司总资产达到5000万元，较上年增长10%。其中流动资产为3000万元，固定资产为2000万元。
                </p>
                <p>
                  2023年度，公司实现营业收入8000万元，同比增长15%。主要收入来源于产品销售和服务收入。净利润为1200万元，较上年增长20%，利润率为15%。公司在成本控制方面表现良好，销售成本为6000万元，管理费用为800万元。
                </p>
                <p>
                  现金流方面，经营活动产生的现金流量净额为1500万元，保持良好的现金流状况。公司在研发方面投入500万元，致力于提升产品竞争力。
                </p>
                <p>
                  展望未来，公司将继续优化产品结构，拓展市场份额，提升盈利能力。同时，保持稳健的财务策略，确保可持续发展。感谢各位股东和员工的支持与努力。
                </p>
              </div>
            </div>
            <div class="model-I2 fx-h">
              <div class="model-I2-top fx-h">
                <div class="I-title fx-r">
                  <div class="I-number">02</div>
                  <div class="I-article">投资者</div>
                </div>
              </div>
              <div class="model-I2-content">
                <img
                  src="@/assets/Group18.png"
                  alt=""
                  width="500px"
                  height="auto"
                />
                <img
                  src="@/assets/Group18.png"
                  alt=""
                  width="500px"
                  height="auto"
                />
                <img
                  src="@/assets/Group18.png"
                  alt=""
                  width="500px"
                  height="auto"
                />
                <img
                  src="@/assets/Group18.png"
                  alt=""
                  width="500px"
                  height="auto"
                />
                <img
                  src="@/assets/Group18.png"
                  alt=""
                  width="500px"
                  height="auto"
                />
                <img
                  src="@/assets/Group18.png"
                  alt=""
                  width="500px"
                  height="auto"
                />
                <img
                  src="@/assets/Group18.png"
                  alt=""
                  width="500px"
                  height="auto"
                />
                <img
                  src="@/assets/Group18.png"
                  alt=""
                  width="500px"
                  height="auto"
                />
              </div>
            </div>
          </div>
          <div v-else-if="selectedContent === '联系我们'">
            <div class="page-name">
              <h2 class="name-text">联系我们</h2>
              <a class="name-En">Contract&nbsp;Us</a>
            </div>
            <div class="contact-text">
              <p class="contact-one">
                我们始终倾听您的声音，期待与您建立联系。
              </p>
              <ul>
                <li>电话：400-123-4567</li>
                <li>邮箱：service@example.com</li>
                <li>地址：XX市XX区XX路XX号</li>
                <li>联系人：杨先生</li>
              </ul>
              <p class="contact-two">
                或者，您可以直接填写以下表单，我们将尽快回复您的消息：
              </p>
            </div>
            <div class="contact-form">
              <h2>联系我们</h2>
              <form @submit.prevent="submitForm">
                <div>
                  <label for="Name"
                    >姓名：
                    <input
                      type="text"
                      id="Name"
                      v-model="formData.Name"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="Email"
                    >邮箱：
                    <input
                      type="email"
                      id="Email"
                      v-model="formData.Email"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="Phone"
                    >电话：
                    <input
                      type="text"
                      id="Phone"
                      v-model="formData.Phone"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="leaveMessage"
                    >留言：
                    <input
                      type="textarea"
                      id="contactPhone"
                      v-model="formData.contactPhone"
                      required
                    />
                  </label>
                </div>
                <div style="text-align: center">
                  <button style="text-align: center" type="submit">提交</button>
                </div>
              </form>
            </div>
          </div>
          <div v-else-if="selectedContent === '关于我们'">
            <div class="page-name">
              <h2 class="name-text">关于我们</h2>
              <a class="name-En">About&nbsp;Us</a>
            </div>
            <div class="model-1 fx-r">
              <div class="model-1-left fx-h">
                <div class="title fx-r">
                  <div class="number">01</div>
                  <div class="article">公司简介</div>
                </div>
                <div class="model-1-text">
                  我们是一家专注于冻品生产和销售的公司，致力于为客户提供高品质、安全、健康的冷冻食品。我们的产品涵盖了海鲜、肉类、蔬菜和水果等多个品类，满足不同消费者的需求。
                </div>
              </div>
              <div class="A01"></div>
            </div>
            <div class="model-2 fx-r">
              <div class="A02"></div>
              <div class="model-2-right fx-h">
                <div class="title fx-r">
                  <div class="number">02</div>
                  <div class="article">发展历程</div>
                </div>
                <div class="model-2-text">
                  自成立以来，我们始终坚持以客户为中心，经过多年的发展，已经成为行业内的领先企业。我们拥有现代化的生产设施和严格的质量管理体系，确保每一件产品都符合国家标准和国际认证。
                </div>
              </div>
            </div>
            <div class="model-1 fx-r">
              <div class="model-1-left fx-h">
                <div class="title fx-r">
                  <div class="number">03</div>
                  <div class="article">使命与愿景</div>
                </div>
                <div class="model-1-text">
                  我们的使命是为全球消费者提供新鲜、美味的冻品，让每一餐都充满健康与活力。我们的愿景是成为全球冻品行业的领军者，以创新和可持续发展为驱动力，推动行业的进步与发展。
                </div>
              </div>
              <div class="A03"></div>
            </div>
            <div class="model-4 fx-r">
              <div class="A04"></div>
              <div class="model-4-right fx-h">
                <div class="title fx-r">
                  <div class="number">04</div>
                  <div class="article">核心价值观</div>
                </div>
                <div class="model-4-text">
                  <ul>
                    <li>
                      品质第一
                      ：我们承诺提供最高质量的产品，确保消费者的健康和安全。
                    </li>
                    <li>
                      创新驱动
                      ：我们不断研发新产品，提升生产工艺，以适应市场变化。
                    </li>
                    <li>
                      客户至上
                      ：我们重视客户的反馈与需求，努力提供优质的服务体验。
                    </li>
                    <li>
                      可持续发展
                      ：我们关注环境保护，致力于可持续的生产方式，减少对自然资源的影响。
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="model-1 fx-r">
              <div class="model-1-left fx-h">
                <div class="title fx-r">
                  <div class="number">05</div>
                  <div class="article">公司团队</div>
                </div>
                <div class="model-1-text">
                  我们的团队由一群经验丰富、充满激情的专业人士组成，他们在冻品行业拥有丰富的知识和技能。我们相信，团队的力量是公司成功的关键。
                </div>
              </div>
              <div class="A05"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";
import Video from "@/components/Video.vue";
import { ref } from "vue";

export default {
  name: "CompanyStructure",
  components: {
    Video,
    Bar,
  },
  setup() {
    // 当前选中的内容
    const selectedContent = ref("公司架构");

    // 选择内容的方法
    function selectContent(content) {
      selectedContent.value = content;
    }
    return {
      selectedContent,
      selectContent,
    };
  },
  data() {
    return {
      formData: {
        Name: "",
        Email: "",
        Phone: "",
        leaveMessage: "",
      },
      menuItems: [
        {
          title: "联系我们",
          subMenus: [{ title: "咨询" }, { title: "反馈" }, { title: "建议" }],
          isOpen: false,
        },
      ],
      //显示
      isShow: false,
    };
  },
  methods: {
    //提交
    submitForm() {
      // 在这里你可以添加逻辑来处理表单提交，比如发送到服务器
      console.log(this.formData);
    },
    toggleSubMenu(item) {
      item.isOpen = !item.isOpen;
    },
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
};
</script>

<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
/* 大图片 */
.header {
  height: 500px;
  overflow: hidden;
  background-image: url(@/assets/公司.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/* 图片中间的内容 */
.header-text {
  color: white;
  font-size: 40px; /* 字体大小 */
  margin-top: 10px;
}
/* 公司logo */
.icon-overlay {
  width: 300px;
  height: auto;
  display: block;
  margin: 0 auto;
}
/* 图片中间文字 */
.header-text-right {
  width: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}
.text-overlay {
  font-size: 24px;
  letter-spacing: 5px;
  color: white;
}
.text-overlay2 {
  color: white;
  font-size: 16px;
}

/* 图片以下部分 */
.content {
  width: 100%;
  height: auto;
  overflow: hidden;
}
/* 侧边栏圆圈 */
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: white;
  display: flex;
  margin-top: 9px;
  margin-right: 10px;
  margin-left: 30px;
}
.sidebar {
  width: 20vw;
  border-right: 1px solid red;
  border-left: 8px solid red;
  background-color: white;
  cursor: pointer;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 10px;
  font-size: 20px;
}

.sidebar ul li:hover {
  background-color: rgb(220, 23, 23);
}
.sidebar a {
  color: black;
  text-decoration: none;
}
/* 侧边栏右边内容 */
.content-area {
  width: 78%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 50px;
}

.model-B1 {
  height: 200px;
  width: 90%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
.model-B1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid red;
}
.model-B2 {
  height: 200px;
  width: 90%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
.model-B2::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-top: 20px solid red;
}
.model-B1-article {
  width: 55%;
  height: 100%;
  margin-right: 5%;
  font-size: 16px;
  text-indent: 2em;
  display: flex;
  align-items: flex-end;
}
.model-B2-article {
  width: 55%;
  height: 100%;
  margin-left: 5%;
  font-size: 16px;
  text-indent: 2em;
  display: flex;
  align-items: flex-end;
}

.BI01 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/Group35.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-left: 50px; */
}
.BI02 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/图1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI03 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/图2.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI04 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/图3.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI05 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/图4.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI06 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/图5.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
/* 所在页面名称（通用） */
.page-name {
  width: 200px;
  height: 80px;
  border: 1px solid red;
  border-radius: 20px 20px 20px 20px;
  background-image: radial-gradient(ellipse at bottom left, white, #f06a75);
  margin-top: 50px;
  margin-left: 100px;
  text-align: left 20px;
}
.name-text {
  margin-top: 10px;
  margin-left: 20px;
}
.name-En {
  margin-top: 10px;
  font-size: 10dm;
  margin-left: 20px;
}
/* 关于我们文字在左边的模块 */
.model-1 {
  height: 200px;
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
/* 红色数字标题 */
.title {
  font-size: 30px;
}
.number {
  color: red;
  margin-right: 15px;
}
.model-1-text {
  width: 600px;
  height: 100%;
  margin-right: 50px;
  font-size: 16px;
  text-indent: 2em;
  display: flex;
  align-items: flex-end;
}

/* 关于我们文字在右边的模块 */
.model-2 {
  height: 200px;
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
.model-2-right {
  width: 500px;
  height: 100%;
  margin-left: 55.5px;
}
.model-2-text {
  width: 500px;
  height: 100%;
  font-size: 16px;
  text-indent: 2em;
  display: flex;
  align-items: flex-end;
}

/* 关于我们04模块 */
.model-4 {
  height: 500px;
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
.model-4-right {
  width: 500px;
  height: 100%;
  margin-left: 55.5px;
}
.model-4-text {
  width: 500px;
  height: 100%;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
}
.model-4-text li {
  margin-top: 50px;
}
/* 关于我们图片 */
.A01 {
  width: 400px;
  height: 100%;
  background-image: url(@/assets/Group35.png);
  background-size: 400px 200px;
  background-repeat: no-repeat;
  /* margin-left: 50px; */
}
.A02 {
  width: 500px;
  height: 100%;
  background-image: url(@/assets/A01.png);
  background-size: 500px 200px;
  background-repeat: no-repeat;
}
.A03 {
  width: 400px;
  height: 100%;
  background-image: url(@/assets/A02.png);
  background-size: 400px 200px;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.A04 {
  width: 500px;
  height: 500px;
  background-image: url(@/assets/A03.png);
  background-size: 500px 500px;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.A05 {
  width: 400px;
  height: 100%;
  background-image: url(@/assets/A04.png);
  background-size: 400px 200px;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}

/* 公司架构模块1 */
.model-C1 {
  height: 400px;
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
.model-C1-left {
  width: 600px;
  height: 100%;
  margin-right: 55.5px;
}
.model-C1-text {
  width: 100%;
  height: 100%;
}
.model-C1-text p {
  margin-top: 30px;
  font-size: 16px;
}
.model-C1-text ul {
  margin-top: 20px;
}
.model-C1-text li {
  margin-top: 10px;
  margin-left: 25px;
}
/* 公司架构模块2 */
.model-C2 {
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
.model-C2-left {
  width: 600px;
  height: 100%;
  margin-right: 55.5px;
}
.model-C2-text {
  width: 100%;
  height: 100%;
  font-size: 16px;
  margin-top: 20px;
}
.model-C2-text h4 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 400;
}
.model-C2-text p {
  margin-bottom: 5px;
}
/* 公司架构logo图 */
.logo {
  width: 400px;
  height: 100%;
  position: relative;
}
.logo-bgi {
  width: 100%;
  height: 100%;
  display: block;
}
.logo-overlay {
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%); /* 相对于自身尺寸居中 */
  width: 300px; /* 根据图标大小设置 */
  height: auto;
}
/* 公司架构模块2图 */
.C02 {
  width: 400px;
  height: 820px;
  background-image: url(@/assets/C02.png);
  background-size: 400px 820px;
  background-repeat: no-repeat;
}
/* 法务标题 */
.L-title {
  margin-top: 40px;
}
.L-title h2 {
  font-size: 35px;
  letter-spacing: 5px;
  text-align: center;
}
.L-title p {
  font-size: 14px;
  margin-top: 20px;
  margin-left: 100px;
  color: #6c6c6c;
}
.L-title p span {
  margin-right: 130px;
}
/* 法务模块1 */
.model-L1 {
  height: 100px;
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 60px;
}

.title {
  font-size: 30px;
}
.number {
  color: red;
  margin-right: 15px;
}
/* 法务模块1文字 */
.model-L1-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 5px;
}
.model-L1-text p {
  font-size: 16px;
  text-indent: 2em;
  margin-top: 30px;
}

/* 法务模块2 */
.model-L2 {
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
/* 法务模块2文字 */
.model-L2-text {
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
  text-indent: 2em;
  letter-spacing: 5px;
}
.model-L2-text h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 400;
}
.model-L2-text p {
  margin-bottom: 10px;
}

/* 红色数字标题 */
.I-title {
  width: 100%;
  height: 100%;
}
.I-number {
  color: red;
  margin-right: 15px;
  font-size: 30px;
}
.I-article {
  font-size: 30px;
}
/* 投资者关系模块1 */
.model-I1 {
  height: 980px;
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
.download {
  margin-left: 775px;
  margin-top: 6px;
}
.download img {
  margin-top: 5px;
}
.download span {
  margin-left: 5px;
  font-size: 20px;
  color: #e70c1c;
}
.model-I1-top {
  width: 100%;
  height: 40px;
}
.model-I1-content {
  width: 100%;
}
.model-I1-content img {
  margin-top: 20px;
}
.model-I1-content p {
  font-size: 16px;
  text-indent: 2em;
  margin-top: 20px;
  letter-spacing: 5px;
}
.model-I2 {
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
.model-I2-content {
  margin-top: 30px;
}
/* 联系我们文字部分 */
.contact-text {
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
.contact-text p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 5px;
}
.contact-one {
  margin-top: 20px;
}
.contact-two {
  margin-top: 60px;
}
.contact-text ul {
  font-size: 16px;
  font-weight: 400;
  margin-top: 18px;
  margin-left: 20px;
}
/* 联系我们表单部分 */
.contact-form {
  background-color: #fff; /* 假设背景是白色的 */
  border: 1px solid #e60012; /* 假设有一个红色的边框 */
  border-radius: 5px; /* 边框圆角 */
  width: 70%;
  margin: 10px 100px; /* 上下外边距20px，左右100px */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微的阴影效果 */
  font-family: Arial, sans-serif; /* 字体设置 */
}
.contact-form h2 {
  text-align: center;
  padding-top: 10px;
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e60012;
}
.contact-form form {
  margin-top: 10px;
}

.contact-form label {
  text-align: center; /* 文本在 label 内部居中 */
  display: block;
  font-size: 18px;
  margin-bottom: 5px; /* 标签与输入框之间的间距 */
  font-weight: bold; /* 标签是粗体的 */
  color: #333; /* 文本颜色 */
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="textarea"] {
  width: 600px;
  height: 30px; /* 减去左右padding和border的宽度 */
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 15px;
}
.contact-form input[type="textarea"] {
  height: 300px;
}

.contact-form button {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 150px;
  padding: 10px;
  background-color: #e60012; /* 鲜红色背景 */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* 假设按钮上的文字稍大一些 */
}
/* 媒体模块1 */
.model-M1 {
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 60px;
}
.model-M1-text {
  width: 100%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 5px;
}
.model-M1-text p {
  font-size: 16px;
  text-indent: 2em;
  margin-top: 30px;
}
.model-M1-text img {
  margin-top: 20px;
}
/* 媒体模块2 */
.model-M2 {
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 40px;
}
.model-M2-text {
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
  text-indent: 2em;
  letter-spacing: 5px;
}
.model-M2-text h4 {
  margin-top: 30px;
  font-weight: 400;
}
.model-M2-text p {
  margin-top: 10px;
}
</style>
