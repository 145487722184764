<template>
  <div class="parent-container">
    <Bar />
    <div class="BI1">
      <div class="BI11 fx-h">
        <h1 class="BI111">新闻</h1>
        <br />
        <a class="BI111 h3">掌握市场动态，领先一步</a>
      </div>
    </div>
    <div class="NIT-content fx-r">
      <div class="NIT-content-left fx-h">
        <div class="NIT-title">
          <h1>农业农村部召开高层次青年人才培养工作座谈会强调 建强农业农村系统青年人才队伍 为加快建设农业强国提供有力人才支撑</h1>
          <span>2024.10.25</span><span>15：30</span
          ><span>来源 ：农业农村部新闻办公室</span>
        </div>
        <div class="NIT-model1 fx-h">
          <div class="NIT-model1-photo fx-r">
            <img
              src="@/assets/news11.png"
              alt=""
              width="100%"
              height="500px"
            />
          </div>
          <p class="note">（图片来源：互联网）</p>
          <p class="text">
            本网讯 10月24日，农业农村部召开高层次青年人才培养工作座谈会，部党组书记、部长韩俊主持会议并讲话。他强调，要认真学习领会习近平总书记关于加强青年科技人才培养和使用的一系列重要讲话精神，落实中央人才工作会议部署，一体推进农业科技、人才、教育工作，坚持“引育留用”协同发力，以高层次青年人才为引领，建强农业农村系统青年人才队伍，加快形成生态良好、英才荟萃、本领过硬、人尽其才的生动局面，为推进乡村全面振兴、加快建设农业强国提供有力人才支撑。
          </p>
          <div class="NIT-model1-photo2 fx-r fx-ac">
            <img
              src="@/assets/news11-02.png"
              alt=""
              width="100%"
              height="500px"
            />
          </div>
          <p class="note">（图片来源：互联网）</p>
          <p class="text">
            会上，15位农业农村系统高层次青年人才介绍个人成长经历，提出青年人才工作建议，部系统5家用人单位主要负责人交流青年人才培养有关做法。韩俊强调，人才是第一资源，青年人才是人才队伍中最富创新活力的生力军，建设农业强国、推进乡村全面振兴，离不开大批有理想、敢担当、肯奋斗的青年人才。近年来，部系统青年人才队伍不断壮大，在重大农业科技攻关、重大政策研究、新闻出版、工程规划等领域发挥了重要作用。但对照农业强国建设要求，在领军人才数量、人才队伍结构、培养激励配套措施等方面还存在短板弱项，必须高度重视，进一步强化举措、完善制度，谋划务实管用的抓手载体，下大力气予以解决。
          </p>
          <div class="NIT-model1-photo2 fx-r fx-ac">
            <img
              src="@/assets/news11-03.png"
              alt=""
              width="100%"
              height="500px"
            />
          </div>
          <p class="note">（图片来源：互联网）</p>
          <p class="text">
            韩俊强调，要建立健全有利于青年人才快速成长、脱颖而出的制度机制，稳定增加基础性支持项目，加大竞争性项目倾斜，解决好部系统青年科技人才职业生涯早期缺乏稳定支持的问题，加快建立以创新能力、质量、实效、贡献为导向的人才评价体系，有意识、有计划地让青年人才担任团队骨干、挑大梁，畅通青年人才成长通道。要放手使用多压担子，让青年人才在农业科技攻关中当主角，在服务基层一线中担重任，在乡村振兴主战场历练成长。要向用人主体充分授权，为青年人才松绑赋能，科学设置青年人才的评价考核周期，推动青年人才主要时间用于科研和业务活动。
          </p>
          <p class="text">
            韩俊要求，要用心用情用力做好青年人才的服务保障工作，在思想政治上多关心信任，在学习交流上多创造条件，在日常生活上多关怀鼓励，为青年人才排忧解难。要进一步加强部系统年轻干部人才队伍建设，坚持选、育、管、用全链条一体谋划，持续优化干部人才队伍年龄和专业结构。要坚持事业为上、以事择人，大力选拔政治过硬、作风扎实、敢于担当、实绩突出、清正廉洁的年轻干部人才，把“忠专实”“勤正廉”的要求体现到选人用人的实际工作中。希望青年人才胸怀“国之大者”，增强理想信念，练就过硬本领，保持扎实作风，自觉践行和弘扬科学家精神，聚焦国家战略需求和产业急需，找准研究方向重点，敢于承担职责使命，真正把论文写在大地上。
          </p>
          <p class="text">
            农业农村部党组成员、副部长张兴旺，中国农业科学院党组书记杨振海，农业农村部国家首席兽医师（官）陶怀颖、总农艺师潘文博出席会议。
          </p>
        </div>
      </div>
      <div class="NIT-content-right">
        <div class="NIT-right-title fx-r">
          <div class="recent">近期最热文章</div>
          <div class="imageText fx-r">
            <span class="span-g">图文</span>
            <span class="span-I">|</span>
            <span
              ><router-link to="/VideoNews" class="span-v"
                >视频</router-link
              ></span
            >
          </div>
        </div>
        <div class="recommend fx-h">
          <div class="re-model fx-r" @click="news2">
            <img src="@/assets/new2.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>了解中国对农业发展的政策扶持</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news7">
            <img src="@/assets/new7.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>1月至3月阿根廷国内牛肉消费同比下降17.6% 出口仍保持增长</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news4">
            <img src="@/assets/new4.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>北海：2023年对越南外贸进出口总值59.3亿元</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news5">
            <img src="@/assets/new5.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>泰国将2024年大米出口预测上调至820万吨</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news6">
            <img src="@/assets/new6.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>4750亿美元！中国跌至第三，不再是美国第一大进口国</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="see-more" @click="news">
            查看更多&nbsp;<img src="@/assets/MoreIcon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bar from "@/components/NavBar.vue";

export default {
  name: "NewsImageText1",
  components: {
    Bar,
  },
  methods: {
    news() {
      this.$router.push("/GraphicNews").catch((err) => {});
    },
    news2() {
      this.$router.push("/NewsImageText2").catch((err) => {});
    },
    news7() {
      this.$router.push("/NewsImageText7").catch((err) => {});
    },
    news4() {
      this.$router.push("/NewsImageText4").catch((err) => {});
    },
    news5() {
      this.$router.push("/NewsImageText5").catch((err) => {});
    },
    news6() {
      this.$router.push("/NewsImageText6").catch((err) => {});
    },
  },
};
</script>
<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
}
.BI1 {
  /* width: 100vw; */
  height: 500px;
  overflow-x: hidden;
  background-image: url(@/assets/新闻.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BI111 {
  color: aliceblue;
  font-size: 50px;
  letter-spacing: 30px;
}

.h3 {
  font-size: 30px;
  letter-spacing: 10px;
}

.NIT-content {
  width: 80%;
  margin-left: 10%;
  margin-top: 80px;
  margin-bottom: 50px;
}
.NIT-content-left {
  width: 55%;
  height: 100%;
}

.NIT-title h1 {
  margin-bottom: 5px;
}
.NIT-title span {
  font-size: 14px;
  color: #757575;
  margin-right: 20px;
}
.NIT-model1 {
  width: 100%;
  /* height: 500px; */
  margin-top: 40px;
}
.note {
  font-size: 14px;
  color: #757575;
  text-align: center;
}

.text {
  /* font-weight: 500; */
  font-size: 16px;
  letter-spacing: 5px;
  font-size: 16px;
  text-indent: 2em;
  margin-top: 30px;
}
.NIT-model1-photo2 {
  margin-top: 50px;
}
/* 右边 */
.NIT-content-right {
  width: 35%;
  height: 60%;
  margin-left: 10%;
}
.NIT-right-title {
  width: 100%;
  height: 39px;
  position: relative;
}
.recent {
  width: 100px;
  height: 25px;
  padding: 6px;
  border: 1px solid red;
  border-radius: 5px;
  position: absolute;
}
.imageText {
  width: fit-content;
  height: 25px;
  padding: 6px 10px;
  letter-spacing: 4px;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
}
.imageText a {
  text-decoration: none;
}
.span-g {
  color: red;
}
.span-v {
  color: black;
}
.recommend {
  width: 100%;
  height: 80%;
  margin-top: 30px;
}
.re-model {
  width: 100%;
  height: 120px;
  margin-top: 30px;
}
.re-right {
  width: 48%;
  margin-left: 4%;
  height: 100%;
}

.re-right-bottom {
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #757575;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.read {
  left: 0;
  position: absolute;
}
.time {
  position: absolute;
  right: 0;
}
.see-more {
  margin-top: 20px;
  border: 1px solid red;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  padding: 10px;
  letter-spacing: 5px;
}
</style>
