var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"any"},[_c('Bar'),_vm._m(0),_c('div',{staticClass:"BI2 fx-h"},[_c('div',{staticClass:"B21 fx-h"},[_c('div',{staticClass:"b1 fx-r"},[_c('div',{staticClass:"imageText fx-r"},[_c('span',{staticClass:"span-v"},[_vm._v("视频")]),_c('span',{staticClass:"span-I"},[_vm._v("|")]),_c('span',[_c('router-link',{staticClass:"span-g",attrs:{"to":"/GraphicNews"}},[_vm._v("图文")])],1)]),_vm._m(1)]),_c('div',{staticClass:"model1 fx-r"},[_c('div',{staticClass:"news1-left fx-h"},[_c('div',{staticClass:"news1-v"},[_c('router-link',{attrs:{"to":"/VideoNews1"}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/play.png"),"alt":"","width":"40px"}})])]),_vm._m(2)],1)]),_c('div',{staticClass:"news1-right fx-h"},[_vm._m(3),_c('router-link',{attrs:{"to":"/VideoNews1"}},[_c('div',{staticClass:"whatch"},[_vm._v("立即观看 >")])])],1)]),_c('div',{staticClass:"model2 fx-r"},[_c('div',{staticClass:"news2 fx-h",on:{"click":_vm.news2}},[_vm._m(4),_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"news3 fx-h",on:{"click":_vm.news3}},[_vm._m(7),_vm._m(8),_vm._m(9)]),_c('div',{staticClass:"news4 fx-h",on:{"click":_vm.news4}},[_vm._m(10),_vm._m(11),_vm._m(12)])]),_c('div',{staticClass:"model2 fx-r"},[_c('div',{staticClass:"news5 fx-h",on:{"click":_vm.news5}},[_vm._m(13),_vm._m(14),_vm._m(15)]),_c('div',{staticClass:"news6 fx-h",on:{"click":_vm.news6}},[_vm._m(16),_vm._m(17),_vm._m(18)]),_c('div',{staticClass:"news7 fx-h",on:{"click":_vm.news7}},[_vm._m(19),_vm._m(20),_vm._m(21)])]),_c('div',{staticClass:"model2 fx-r"},[_c('div',{staticClass:"news8 fx-h",on:{"click":_vm.news8}},[_vm._m(22),_vm._m(23),_vm._m(24)]),_c('div',{staticClass:"news9 fx-h",on:{"click":_vm.news9}},[_vm._m(25),_vm._m(26),_vm._m(27)]),_c('div',{staticClass:"news10 fx-h",on:{"click":_vm.news10}},[_vm._m(28),_vm._m(29),_vm._m(30)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI1"},[_c('div',{staticClass:"BI11 fx-h"},[_c('h1',{staticClass:"BI111"},[_vm._v("新 闻")]),_c('br'),_c('a',{staticClass:"BI111 h3"},[_vm._v("掌握市场动态，领先一步")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more"},[_c('span',{staticClass:"text-m"},[_vm._v("更多>")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"videoTime"},[_c('div',{staticClass:"time"},[_vm._v("01:44")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news1-right-text"},[_c('h2',[_vm._v("西藏特色产业牦牛产业")]),_c('p',[_vm._v(" 生活在西藏高原草地上的精品牦牛，西藏特色产业，带动西藏各产业循环发展。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news2-v"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/play.png"),"alt":"","width":"40px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time1 fx-r"},[_c('img',{staticClass:"clockIcon",attrs:{"src":require("@/assets/clockIcon.png"),"alt":""}}),_vm._v("02:29 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-h3"},[_c('h3',[_vm._v("捕捞美国阿拉斯加帝王蟹也有这么多心眼")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news3-v"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/play.png"),"alt":"","width":"40px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time1 fx-r"},[_c('img',{staticClass:"clockIcon",attrs:{"src":require("@/assets/clockIcon.png"),"alt":""}}),_vm._v("02:14 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-h3"},[_c('h3',[_vm._v("震惊！越南活青蟹对华出口暴增")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news4-v"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/play.png"),"alt":"","width":"40px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time1 fx-r"},[_c('img',{staticClass:"clockIcon",attrs:{"src":require("@/assets/clockIcon.png"),"alt":""}}),_vm._v("02:12 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-h3"},[_c('h3',[_vm._v("泰国农户：中国以外的其他国家不太爱吃榴莲")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news5-v"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/play.png"),"alt":"","width":"40px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time1 fx-r"},[_c('img',{staticClass:"clockIcon",attrs:{"src":require("@/assets/clockIcon.png"),"alt":""}}),_vm._v("03:53 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-h3"},[_c('h3',[_vm._v("藏香猪一头两三万，一年就能赚百万！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news6-v"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/play.png"),"alt":"","width":"40px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time1 fx-r"},[_c('img',{staticClass:"clockIcon",attrs:{"src":require("@/assets/clockIcon.png"),"alt":""}}),_vm._v("04:13 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-h3"},[_c('h3',[_vm._v("西班牙火腿真讲究！1200亩只养40头黑猪，怪不得这么贵！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news7-v"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/play.png"),"alt":"","width":"40px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time1 fx-r"},[_c('img',{staticClass:"clockIcon",attrs:{"src":require("@/assets/clockIcon.png"),"alt":""}}),_vm._v("01:19 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-h3"},[_c('h3',[_vm._v("早熟品种：越南超早菠萝蜜")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news8-v"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/play.png"),"alt":"","width":"40px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time1 fx-r"},[_c('img',{staticClass:"clockIcon",attrs:{"src":require("@/assets/clockIcon.png"),"alt":""}}),_vm._v("02:32 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-h3"},[_c('h3',[_vm._v("美国肥牛，切切切！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news9-v"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/play.png"),"alt":"","width":"40px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time1 fx-r"},[_c('img',{staticClass:"clockIcon",attrs:{"src":require("@/assets/clockIcon.png"),"alt":""}}),_vm._v("01:58 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-h3"},[_c('h3',[_vm._v("林下“跑山鸡” “跑”出乡村振兴致富路")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news10-v"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/play.png"),"alt":"","width":"40px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time1 fx-r"},[_c('img',{staticClass:"clockIcon",attrs:{"src":require("@/assets/clockIcon.png"),"alt":""}}),_vm._v("01:17 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-h3"},[_c('h3',[_vm._v("波士顿龙虾这样挑，肉多又紧实")])])
}]

export { render, staticRenderFns }