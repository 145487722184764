<template>
  <!-- 这是上半部分主体 -->
  <div class="container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <!-- 图片部分 -->
      <div class="fx-h">
        <div class="header">
          <img
            src="@/assets/进口数据.png"
            alt="1"
            width="100%"
            height="100%"
            class="background-image"
          />
          <h1 class="text-overlay">进 口 数 据 查 询</h1>
          <br />
          <h3 class="text-overlay2">掌握市场动态，领先一步。</h3>
        </div>
      </div>
      <!-- 这是下半部分 -->
      <div class="row-b1">
        <div class="wp">
          <!-- 左边部分 -->
          <div class="col-l">
            <div class="work_list clearfix">
              <div
                class="tit"
                @click="selectContent('准入工厂查询')"
                :class="{ active: selectedContent === '准入工厂查询' }"
              >
                <div class="circle"></div>
                <div>准入工厂查询</div>
              </div>
              <div
                class="tit"
                @click="selectContent('进口数量统计')"
                :class="{ active: selectedContent === '进口数量统计' }"
              >
                <div class="circle"></div>
                <div>进口数量统计</div>
              </div>
              <!-- <div class="tit" @click.prevent="goToImportEV">
                <div class="circle"></div>
                <div>进口水果蔬菜</div>
              </div> -->
            </div>
          </div>
          <!-- 右边部分 -->
          <div class="col-r">
            <div class="g-titIns1">{{ selectedContent }}</div>
            <div class="m-chanpin">
              <div
                class="supplier-management"
                v-if="selectedContent === '进口数量统计'"
              >
                <!-- 搜索框 -->
                <div class="search-component">
                  <!-- 下拉菜单 -->
                  <select v-model="selectedCountry" @change="onOptionChange1">
                    <option disabled value="">国家</option>
                    <option
                      v-for="option1 in options1"
                      :key="option1"
                      :value="option1"
                    >
                      {{ option1 }}
                    </option>
                  </select>
                  <select v-model="selectedCode" @change="onOptionChange3">
                    <option disabled value="">编码</option>
                    <option
                      v-for="option3 in codes"
                      :key="option3"
                      :value="option3"
                    >
                      {{ option3 }}
                    </option>
                  </select>
                  <select v-model="selectedType" @change="onOptionChange2">
                    <option disabled value="">品类</option>
                    <option
                      v-for="option2 in options2"
                      :key="option2"
                      :value="option2"
                    >
                      {{ option2 }}
                    </option>
                  </select>

                  <input
                    type="month"
                    id="month"
                    name="month"
                    v-model="selectedTime"
                    placeholder="输入年月,格式:yyyy-mm"
                  />
                  
                  <button @click="clearSelection1">
                    <span>重置</span>
                  </button>
                  <!-- 搜索按钮 -->
                  <button @click="performSearch">
                    <i class="el-icon-search"></i>
                  </button>
                </div>
                <!-- 下部分的表格 -->
                <table>
                  <thead>
                    <tr class="tr-th">
                      <th>国家</th>
                      <th>编码</th>
                      <th>产品名</th>
                      <th>月份</th>
                      <th>数量/KG</th>
                      <th>人民币</th>
                      <th>美元</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in filteredItems1"
                      :key="item.id"
                      class="tr-td"
                    >
                      <td>{{ item.country }}</td>
                      <td>{{ item.code }}</td>
                      <td>{{ item.productName }}</td>
                      <td>{{ item.month }}</td>
                      <td>{{ item.count }}</td>
                      <td>{{ item.rmb }}</td>
                      <td>{{ item.dollar }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="notice">数据来源：中华人民共和国海关总署网</div>
              </div>
              <div
                class="supplier-management"
                v-if="selectedContent === '准入工厂查询'"
              >
                <!-- 搜索框 -->
                <div class="search-component">
                  <!-- 下拉菜单 -->
                  <select v-model="selectedOption1" @change="onOptionChange1">
                    <option disabled value="">国家</option>
                    <option
                      v-for="option1 in countries"
                      :key="option1"
                      :value="option1"
                    >
                      {{ option1 }}
                    </option>
                  </select>
                  <!-- <select v-model="selectedOption2" @change="onOptionChange2">
                    <option disabled value="">工厂</option>
                    <option
                      v-for="option2 in factories"
                      :key="option2"
                      :value="option2"
                    >
                      {{ option2 }}
                    </option>
                  </select> -->
                  <input
                      type="text"
                      placeholder="工厂名称"
                      v-model="selectedOption2" 
                      @change="onOptionChange2"
                  />
                  <select v-model="selectedOption3" @change="onOptionChange3">
                    <option disabled value="">产品类别</option>
                    <option
                      v-for="option3 in Function"
                      :key="option3"
                      :value="option3"
                    >
                      {{ option3 }}
                    </option>
                  </select>
                  
                  <input
                    type="date"
                    id="time"
                    name="time"
                    v-model="selectedTime"
                  />

                  <button @click="clearSelection">
                    <span>重置</span>
                  </button>
                  <!-- 搜索按钮 -->
                  <button @click="performSearch">
                    <i class="el-icon-search"></i>
                  </button>
                </div>
                <!-- 下部分的表格 -->
                <table>
                  <thead>
                    <tr class="tr-th">
                      <th>国家</th>
                      <th>工厂</th>
                      <th>注册编号</th>
                      <th>产品类别</th>
                      <th>获批时间</th>
                      <th>工厂状态</th>
                    </tr>
                  </thead>
                  <tbody class="table1">
                    <tr
                      v-for="item in filteredItems"
                      :key="item.id"
                      class="tr-td"
                    >
                      <td class="fx-h fx-ac fx-bc">{{ item.country }}</td>
                      <td class="fx-h fx-ac fx-bc">{{ item.factory }}</td>
                      <td class="fx-h fx-ac fx-bc">{{ item.factoryNo }}</td>
                      <td class="fx-h fx-ac fx-bc">{{ item.admissionFunction }}</td>
                      <td class="fx-h fx-ac fx-bc">{{ item.approvedTime }}</td>
                      <td class="fx-h fx-ac fx-bc" style="color: green">{{ item.factorystatus }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="notice">数据来源：中华人民共和国海关总署网</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import Video from "@/components/Video.vue";
import Bar from "@/components/NavBar.vue";

export default defineComponent({
  name: "Importquantity",
  components: {
    Video,
    Bar,
  },
  setup() {
    const selectedContent = ref("进口数量统计");
    // 选择内容的方法
    function selectContent(content) {
      selectedContent.value = content;
    }
    return {
      selectedContent,
      selectContent,
    };
  },
  data() {
    return {
      selectedOption1: "", // 用户选中的选项
      selectedOption2: "", // 用户选中的选项
      selectedOption3: "", // 用户选中的选项
      options1: [
        // 下拉菜单的选项
        "新西兰",
        "美国",
        "澳大利亚",
        "阿根廷",
        "乌拉圭",
        "智利",
        "荷兰",
        "加拿大",
        "泰国",
      ],
      codes: [
        "2012000",
        "2013000",
        "2022000",
        "2064900",
        "3038910"
      ],
      options2: [
        "鲜或冷的带骨牛肉",
        "冻带骨牛肉",
        "其他冻猪肉",
        "冻整头及半头绵羊肉",
        "冻带骨绵羊肉",
        "鲜、冷、冻山羊肉",
        "冻牛肝",
        "其他冻牛杂碎",
        "冻鸡翼（不包括翼尖）",
        "冻带鱼",
      ],
      
      countries: [
        "澳大利亚",
        "美国",
        "白俄罗斯",
        "巴西",
        "加拿大",
        "阿根廷",
        "印度尼西亚",
        "越南",
      ],
      factories: [
        "AUSTRALIAN DAIRY PARK PTY LTD",
        "Nestlé Deutschland AG",
        "Arla Foods amba Arinco",
        "INDUSTRIAS LACTEAS ASTURIANAS SA",
        "SAS LAITERIE DE MONTAIGU",
        "Abbott Ireland",
        "LOTTE WELLFOOD CO.,LTD. Pasteur Plant",
        "Mead Johnson B.V.",
      ],
      Function: [
        "肉与肉制品/牛肉产品",
        "水产品/水产品",
      ],
      
      // 示例数据
      items1: [
        {
          id: 1,
          country: "美国",
          code: "2012000",
          productName: "鲜或冷的带骨牛肉",
          month: "2024-01",
          count: "300",
          rmb: "56619",
          dollar: "7979",
        },
        {
          id: 2,
          country: "澳大利亚",
          code: "2012000",
          productName: "鲜或冷的带骨牛肉",
          month: "2024-01",
          count: "15301",
          rmb: "1694394",
          dollar: "240913",
        },
        {
          id: 3,
          country: "新西兰",
          code: "2012000",
          productName: "鲜或冷的带骨牛肉",
          month: "2024-01",
          count: "869",
          rmb: "87243",
          dollar: "12159",
        },
        {
          id: 4,
          country: "阿根廷",
          code: "2013000",
          productName: "鲜或冷的去骨牛肉",
          month: "2024-01",
          count: "63539",
          rmb: "2610480",
          dollar: "363819",
        },
        {
          id: 5,
          country: "乌拉圭",
          code: "2012000",
          productName: "鲜或冷的带骨牛肉",
          month: "2024-01",
          count: "64928",
          rmb: "2994888",
          dollar: "422015",
        },
        {
          id: 6,
          country: "智利",
          code: "2022000",
          productName: "冻带骨牛肉",
          month: "2024-01",
          count: "100591",
          rmb: "2753109",
          dollar: "387948",
        },
        {
          id: 7,
          country: "荷兰",
          code: "2064900",
          productName: "其他冻猪杂碎",
          month: "2024-03",
          count: "982141",
          rmb: "13119686",
          dollar: "1847063",
        },
        {
          id: 8,
          country: "加拿大",
          code: "2064900",
          productName: "其他冻猪杂碎",
          month: "2024-03",
          count: "51186",
          rmb: "915634",
          dollar: "128901",
        },
        {
          id: 9,
          country: "泰国",
          code: "3038910",
          productName: "冻带鱼",
          month: "2024-05",
          count: "129301",
          rmb: "213320",
          dollar: "1515103",
        },
      ],
      items: [
        {
          id: 1,
          country: "美国",
          factory: "NOCS South Atlantic Cold Storage and Warehouse, LLC",
          factoryNo: "V19494",
          admissionFunction: "肉与肉制品/牛肉产品",
          approvedTime: "2019-11-22",
          factorystatus: "正常",
        },
        {
          id: 2,
          country: "美国",
          factory: "Americold Logistics, Inc.",
          factoryNo: "V19489",
          admissionFunction: "肉与肉制品/牛肉产品",
          approvedTime: "2020-03-17",
          factorystatus: "正常",
        },
        {
          id: 3,
          country: "巴西",
          factory: "FRIGOL S.A.",
          factoryNo: "SIF2960",
          admissionFunction: "肉与肉制品/牛肉产品",
          approvedTime: "2018-08-01",
          factorystatus: "正常",
        },
        {
          id: 4,
          country: "澳大利亚",
          factory: "AMERICOLD LOGISTICS LIMITED",
          factoryNo: "1013",
          admissionFunction: "肉与肉制品/牛肉产品",
          approvedTime: "2018-08-01",
          factorystatus: "正常",
        },
        {
          id: 5,
          country: "白俄罗斯",
          factory: 'OJSC "Brest meat processing plant"',
          factoryNo: "BY 01-03-06",
          admissionFunction: "肉与肉制品/牛肉产品",
          approvedTime: "2018-08-01",
          factorystatus: "正常",
        },
        {
          id: 6,
          country: "巴西",
          factory: "PRIMA FOODS S.A",
          factoryNo: "SIF177",
          admissionFunction: "肉与肉制品/牛肉产品",
          approvedTime: "2018-08-01",
          factorystatus: "正常",
        },
        {
          id: 7,
          country: "加拿大",
          factory: "St.Helen's Meat Packers",
          factoryNo: "11",
          admissionFunction: "肉与肉制品/牛肉产品",
          approvedTime: "2018-08-01",
          factorystatus: "正常",
        },
        {
          id: 8,
          country: "阿根廷",
          factory: "Catesur S.A.",
          factoryNo: "4366",
          admissionFunction: "水产品/水产品",
          approvedTime: "2018-08-01",
          factorystatus: "正常",
        },
        {
          id: 9,
          country: "印度尼西亚",
          factory: "PT. ANUGERAH SAMUDERA HINDIA",
          factoryNo: "CR 001 - 02",
          admissionFunction: "水产品/水产品",
          approvedTime: "2018-02-01",
          factorystatus: "正常",
        },
        {
          id: 10,
          country: "越南",
          factory: "Anh Nhan Trading Production Co.,Ltd (ANPRO)",
          factoryNo: "79-002-DG",
          admissionFunction: "水产品/水产品",
          approvedTime: "2018-07-01",
          factorystatus: "正常",
        },
      ],
      selectedCountry: "",
      selectedType: "",
      selectedCode: "",
      selectedTime: "",
      //显示
      isShow: false,
      value1: "",
    };
  },
  methods: {
    //搜索
    onOptionChange1() {
      console.log("Selected option changed to:", this.selectedOption1);
    },
    onOptionChange2() {
      console.log("Selected option changed to:", this.selectedOption2);
    },
    onOptionChange3() {
      console.log("Selected option changed to:", this.selectedOption3);
    },
    onOptionChange4() {
      console.log("Selected option changed to:", this.selectedOption4);
    },
    onOptionChange5() {
      console.log("Selected option changed to:", this.selectedOption5);
    },
    onOptionChange6() {
      console.log("Selected option changed to:", this.selectedOption6);
    },
    // 执行搜索的方法
    performSearch() {
      console.log("全部搜索");
    },
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
    clearSelection() {
      // 清除选择框的内容
      this.selectedOption1 = "";
      this.selectedOption2 = "";
      this.selectedOption3 = "";
      this.selectedTime = "";
    },
    clearSelection1() {
      // 清除选择框的内容
      this.selectedCountry = "";
      this.selectedType = "";
      this.selectedCode = "";
      this.selectedTime = "";
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
  computed: {
    filteredItems1() {
      return this.items1.filter((item) => {
        // 根据selectedCountry筛选
        if (this.selectedCountry && item.country !== this.selectedCountry) {
          return false;
        }
        if (this.selectedType && item.productName !== this.selectedType) {
          return false;
        }
        if (this.selectedCode && item.code !== this.selectedCode) {
          return false;
        }
        if (this.selectedTime && item.month !== this.selectedTime) {
          return false;
        }
        return true;
      });
    },
    filteredItems() {
      return this.items.filter((item) => {
        if (this.selectedOption1 && item.country !== this.selectedOption1) {
          return false;
        }
        if (this.selectedOption2) {
          return item.factory.toLowerCase().includes(this.selectedOption2.toLowerCase());
        }
        if (this.selectedOption3 && item.admissionFunction !== this.selectedOption3) {
          return false;
        }
        if (this.selectedTime && item.approvedTime !== this.selectedTime) {
          return false;
        }
        return true;
      });
    },
  },
});
</script>

<style scoped>
/* 上半部分 */
.container {
  /* width: 100vw; */
  max-width: 100%;
  position: relative;
  width: 100%;
}
.container .main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
/* 图片设置 */
.fx-h .header {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  position: relative;
}
.fx-h .text-overlay {
  position: absolute;
  font-size: 50px;
  top: 230px;
  color: white; /* 根据背景色设置合适的文字颜色 */
  font-weight: 500px;
}
.fx-h .text-overlay2 {
  position: absolute;
  top: 300px;
  color: white; /* 根据背景色设置合适的文字颜色 */
  font-size: 17px;
  font-weight: 500;
}
/* 下半部分 */
.row-b1 {
  overflow: hidden;
}
.wp {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
/* 左边 */
.row-b1 .col-l {
  width: 20vw;
  /* 显式地设置左边框 */
  border-left: 8px solid #e60012; /* 左边框宽度为5px，样式为实线，颜色为黑色 */
  /* 显式地设置右边框 */
  border-right: 1px solid #e60012; /* 右边框宽度为2px，样式为实线，颜色为黑色 */
}
.work_list {
  width: 100%;
  /* padding-top: 12px; */
  margin-bottom: 20px;
  /* 上下边框设置为0px（实际上，如果不设置它们，它们就默认为0px，但这里显式设置以表明意图） */
  border-top: 0px solid transparent; /* 宽度为0，颜色不影响显示 */
  border-bottom: 0px solid transparent; /* 同上 */
  /* 添加一些内边距以便更好地看到边框效果 */
}
.work_list .tit {
  display: flex;
  align-items: center; /* 垂直居中 */
  cursor: pointer;
  line-height: 44px;
  background-size: auto 10px;
  /* border-bottom: 1px solid #f2f2f2; */
  width: 100%;
  float: left;
  font-size: 20px;
}
.work_list .tit .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: #fff;
  display: flex;
  margin-right: 10px;
  margin-left: 30px;
}
.col-l .work_list .tit:hover {
  background-color: #e60012; /* 鼠标悬浮时背景变红 */
  transition: background-color 1s ease-in-out; /* 添加背景颜色变化的过渡效果 */
}

/* 右边 */
.row-b1 .col-r {
  float: none;
  overflow: hidden;
  margin-left: 10px;
  width: 80%;
  margin-bottom: 50px;
  position: relative;
}
.g-titIns1 {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 44px;
  line-height: 44px;
  font-size: 40px;
  color: black;
  font-weight: normal;
  position: relative;
}
.m-chanpin {
  overflow: hidden;
  margin-left: 30px;
}
.supplier-management {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 40px;
}
/* 搜索栏 */
.search-component {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.search-component select {
  width: 150px;
  height: 30px;
  border: 1px solid #f28088;
  border-radius: 10px;
  padding-left: 10px;
}

input[type="month"] {
  width: fit-content;
  height: 30px;
  color: #333;
  border: 1px solid #f28088;
  border-radius: 10px;
  padding-left: 10px;
}
input[type="date"] {
  width: 150px;
  height: 30px;
  color: #333;
  border: 1px solid #f28088;
  border-radius: 10px;
  padding-left: 10px;
}
input[type="text"] {
  width: 150px;
  height: 30px;
  color: #333;
  border: 1px solid #f28088;
  border-radius: 10px;
  padding-left: 10px;
}
.search-component button {
  width: 50px;
  border: 1px solid #f28088;
  border-radius: 10px;
  background-color: #fff;
}
/* .search-component .bi {
  color: red;
} */
.search-component i {
  color: red;
}
/* 下半部分表单,表格展示 */
table {
  margin-top: 30px;
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  /* padding: 8px 30px 8px 30px; 增加内边距以分隔单元格 */
  text-align: center;
  width: 100%;
}
.tr-th {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-color: #f28088;
  border: 1px solid #f28088;
  margin-bottom: 20px;
  border-radius: 5px;
  height: 40px;
}
.tr-td {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  margin-top: 10px;
  border: 1px solid #f28088;
  border-radius: 5px;
  height: 80px;
}
.active {
  background-color: #e60012;
}
.notice{
  color: grey;
  position: absolute;
  right: 10%;
  bottom: 0px;
}
.table1 td{
  height: 80px;
  padding: 0;
}
</style>
