<template>
  <div class="parent-container">
    <Bar />
    <div class="BI1">
      <div class="BI11 fx-h">
        <h1 class="BI111">新闻</h1>
        <br />
        <a class="BI111 h3">掌握市场动态，领先一步</a>
      </div>
    </div>
    <div class="NIT-content fx-r">
      <div class="NIT-content-left fx-h">
        <div class="NIT-title">
          <h1>深圳海关助企迎战“双十一”</h1>
          <span>2024.11.08</span><span>14：55</span
          ><span>来源 ：海关总署 </span>
        </div>
        <div class="NIT-model1 fx-h">
          <div class="NIT-model1-photo fx-r">
            <img
              src="@/assets/news12.png"
              alt=""
              width="100%"
              height="500px"
            />
          </div>
          <p class="note">（图片来源：互联网）</p>
          <div class="NIT-model1-photo2 fx-r fx-ac">
            <img
              src="@/assets/news12-02.png"
              alt=""
              width="100%"
              height="500px"
            />
          </div>
          <p class="note">（图片来源：互联网）</p>
          <p class="text">
            一年一度的电商“双十一”促销大季来到，品种多样的跨境电商商品令人目不暇接。当消费者足不出户在手机、平板电脑上轻点付款时，寄往全球的包裹正整装待发。11月7日，4500余件进口洗发水、润唇膏等“海淘”跨境包裹在深圳国际快件运营中心快速办理完海关通关手续后，即将运往消费者手中。
          </p>
          <p class="text">
            深圳海关针对大促期间包裹数量多、跨境时效要求高等特点，提前调研菜鸟、拼多多等13家跨境电商企业，详细了解“双十一”期间企业备货量、预计出货量等情况。同时，该关引导企业错峰申报，提供“7×24”小时便利通关服务，保障深圳地区的跨境电商商品可以实现当日运抵即入库、当日下单即出区。
          </p>
          <p class="text">
            “每年的‘双十一’对我们来说都不亚于一场大考，每次深圳海关都会提前了解我们的业务高峰节点和通关诉求，保障大促平稳运行。”顺丰速运有限公司关务经理赵生贵介绍。
          </p>
          <p class="text">
            深圳海关着力打造高效通关“硬基础”，“双十一”活动前夕提前做好业务系统、传输平台、网络等设施巡检、系统优化，业务高峰日安排专人24小时值守，实行定时巡检。另外，改变传统货到申报模式，通过提前申报和智能分流，设置智能预警指标，实现进出境货物物流异常的自动预警，提升监管作业智能化水平，货物通关效率大幅提高。
          </p>
          <p class="text">
            深圳邮局海关快邮件监管六科副科长张俊介绍：“海关以大数据、图像识别等前沿技术为支撑，将监管嵌入到物流企业作业流水线中，通过应用智能分拣系统、CT机、智能审图、同屏比对等科技手段，海量包裹实现自动化分拣，包裹平均22秒自动快速放行。”
          </p>
          <p class="text">
            菜鸟网络科技有限公司深圳口岸经理王宗芳说：“从上线分拣到包裹放行，深圳海关实现了全流程智能化通关，切实为我们降低经营成本、提供运营便利。今年‘双十一’大促期间，我们的业务量预计可以超过166万单。”（关轩/文）
          </p>
        </div>
      </div>
      <div class="NIT-content-right">
        <div class="NIT-right-title fx-r">
          <div class="recent">近期最热文章</div>
          <div class="imageText fx-r">
            <span class="span-g">图文</span>
            <span class="span-I">|</span>
            <span
              ><router-link to="/VideoNews" class="span-v"
                >视频</router-link
              ></span
            >
          </div>
        </div>
        <div class="recommend fx-h">
          <div class="re-model fx-r" @click="news2">
            <img src="@/assets/new2.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>了解中国对农业发展的政策扶持</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news7">
            <img src="@/assets/new7.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>1月至3月阿根廷国内牛肉消费同比下降17.6% 出口仍保持增长</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news4">
            <img src="@/assets/new4.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>北海：2023年对越南外贸进出口总值59.3亿元</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news5">
            <img src="@/assets/new5.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>泰国将2024年大米出口预测上调至820万吨</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news6">
            <img src="@/assets/new6.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>4750亿美元！中国跌至第三，不再是美国第一大进口国</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="see-more" @click="news">
            查看更多&nbsp;<img src="@/assets/MoreIcon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bar from "@/components/NavBar.vue";

export default {
  name: "NewsImageText1",
  components: {
    Bar,
  },
  methods: {
    news() {
      this.$router.push("/GraphicNews").catch((err) => {});
    },
    news2() {
      this.$router.push("/NewsImageText2").catch((err) => {});
    },
    news7() {
      this.$router.push("/NewsImageText7").catch((err) => {});
    },
    news4() {
      this.$router.push("/NewsImageText4").catch((err) => {});
    },
    news5() {
      this.$router.push("/NewsImageText5").catch((err) => {});
    },
    news6() {
      this.$router.push("/NewsImageText6").catch((err) => {});
    },
  },
};
</script>
<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
}
.BI1 {
  /* width: 100vw; */
  height: 500px;
  overflow-x: hidden;
  background-image: url(@/assets/新闻.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BI111 {
  color: aliceblue;
  font-size: 50px;
  letter-spacing: 30px;
}

.h3 {
  font-size: 30px;
  letter-spacing: 10px;
}

.NIT-content {
  width: 80%;
  margin-left: 10%;
  margin-top: 80px;
  margin-bottom: 50px;
}
.NIT-content-left {
  width: 55%;
  height: 100%;
}

.NIT-title h1 {
  margin-bottom: 5px;
}
.NIT-title span {
  font-size: 14px;
  color: #757575;
  margin-right: 20px;
}
.NIT-model1 {
  width: 100%;
  /* height: 500px; */
  margin-top: 40px;
}
.note {
  font-size: 14px;
  color: #757575;
  text-align: center;
}

.text {
  /* font-weight: 500; */
  font-size: 16px;
  letter-spacing: 5px;
  font-size: 16px;
  text-indent: 2em;
  margin-top: 30px;
}
.NIT-model1-photo2 {
  margin-top: 30px;
}
/* 右边 */
.NIT-content-right {
  width: 35%;
  height: 60%;
  margin-left: 10%;
}
.NIT-right-title {
  width: 100%;
  height: 39px;
  position: relative;
}
.recent {
  width: 100px;
  height: 25px;
  padding: 6px;
  border: 1px solid red;
  border-radius: 5px;
  position: absolute;
}
.imageText {
  width: fit-content;
  height: 25px;
  padding: 6px 10px;
  letter-spacing: 4px;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
}
.imageText a {
  text-decoration: none;
}
.span-g {
  color: red;
}
.span-v {
  color: black;
}
.recommend {
  width: 100%;
  height: 80%;
  margin-top: 30px;
}
.re-model {
  width: 100%;
  height: 120px;
  margin-top: 30px;
}
.re-right {
  width: 48%;
  margin-left: 4%;
  height: 100%;
}

.re-right-bottom {
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #757575;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.read {
  left: 0;
  position: absolute;
}
.time {
  position: absolute;
  right: 0;
}
.see-more {
  margin-top: 20px;
  border: 1px solid red;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  padding: 10px;
  letter-spacing: 5px;
}
</style>
