var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parent-container"},[_c('Bar'),_vm._m(0),_c('div',{staticClass:"NIT-content fx-r"},[_vm._m(1),_c('div',{staticClass:"NIT-content-right"},[_c('div',{staticClass:"NIT-right-title fx-r"},[_c('div',{staticClass:"recent"},[_vm._v("近期最热文章")]),_c('div',{staticClass:"imageText fx-r"},[_c('span',{staticClass:"span-g"},[_vm._v("图文")]),_c('span',{staticClass:"span-I"},[_vm._v("|")]),_c('span',[_c('router-link',{staticClass:"span-v",attrs:{"to":"/VideoNews"}},[_vm._v("视频")])],1)])]),_c('div',{staticClass:"recommend fx-h"},[_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news1}},[_c('img',{attrs:{"src":require("@/assets/news1.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(2)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news3}},[_c('img',{attrs:{"src":require("@/assets/new3.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(3)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news9}},[_c('img',{attrs:{"src":require("@/assets/new9.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(4)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news5}},[_c('img',{attrs:{"src":require("@/assets/new5.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(5)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news6}},[_c('img',{attrs:{"src":require("@/assets/new6.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(6)]),_c('div',{staticClass:"see-more",on:{"click":_vm.news}},[_vm._v(" 查看更多 "),_c('img',{attrs:{"src":require("@/assets/MoreIcon.png"),"alt":""}})])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI1"},[_c('div',{staticClass:"BI11 fx-h"},[_c('h1',{staticClass:"BI111"},[_vm._v("新闻")]),_c('br'),_c('a',{staticClass:"BI111 h3"},[_vm._v("掌握市场动态，领先一步")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NIT-content-left fx-h"},[_c('div',{staticClass:"NIT-title"},[_c('h1',[_vm._v("了解中国对农业发展的政策扶持")]),_c('span',[_vm._v("2024-07-29")]),_c('span',[_vm._v("12：30")]),_c('span',[_vm._v("来源 ：今日头条")])]),_c('div',{staticClass:"NIT-model1 fx-h"},[_c('div',{staticClass:"NIT-model1-photo fx-r"},[_c('img',{attrs:{"src":require("@/assets/new2-01.png"),"alt":"","width":"100%","height":"400px"}})]),_c('p',{staticClass:"note"},[_vm._v("（图片来源：互联网）")]),_c('p',{staticClass:"text"},[_vm._v(" 中国自始至终地致力于积极推进行业农业农村的现代化进程，在近几年，我国政府已出台了一系列旨在支持农业发展的优惠政策，以期能推动该产业取得更加良好的突破性进展。 ")]),_c('div',{staticClass:"NIT-model1-photo2 fx-r"},[_c('img',{attrs:{"src":require("@/assets/new2-02.png"),"alt":"","width":"100%","height":"400px"}})]),_c('p',{staticClass:"note"},[_vm._v("（图片来源：互联网）")]),_c('p',{staticClass:"text"},[_vm._v(" 为了更好地推动农业的发展，我国政府在若干关键环节上已经推出了许多具有针对性的措施，其中涉及到农业科技创新、农业机械化进程的推动、农业保险的贴心扶持、农村金融扶持以及农业土地流转等各个方面的问题。特别是在此过程中，诸如适度规模经营补贴、农机购置补贴等具体的补贴措施都有相应提及和落实。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("海关总署：上半年我国跨境电商进出口1.22万亿元 同比增长10.5%")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("优势互补、同向发力 农业农村部全力推进高水平农业科技自立自强")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("乳山：跑山鸡“跑出”集体致富新希望")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("泰国将2024年大米出口预测上调至820万吨")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("4750亿美元！中国跌至第三，不再是美国第一大进口国")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
}]

export { render, staticRenderFns }