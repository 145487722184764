<template>
  <div class="parent-container">
    <Bar />
    <div class="BI1">
      <div class="BI11 fx-h">
        <h1 class="BI111">新闻</h1>
        <br />
        <a class="BI111 h3">掌握市场动态，领先一步</a>
      </div>
    </div>
    <div class="NIT-content fx-r">
      <div class="NIT-content-left fx-h">
        <div class="NIT-title">
          <h1>好心冻科技有限公司助力越南慈善事业</h1>
          <span>2024.09.10</span><span>16：00</span
          ><span>来源 ：公司内网</span>
        </div>
        <div class="NIT-model1 fx-h">
          <div class="NIT-model1-photo fx-r">
            <img
              src="@/assets/new10-01.png"
              alt=""
              width="100%"
              height="400px"
            />
          </div>
          <p class="note">（图片来源：互联网）</p>
          <p class="text">
            2024年9月10日，好心冻科技有限公司向越南知名慈善团体Saigon Children's Charity捐赠1031万越南盾，以支持其在越南的弱势儿童教育项目。
          </p>
          <div class="NIT-model1-photo2 fx-r fx-ac">
            <img
              src="@/assets/new10-02.png"
              alt=""
              width="70%"
              height="800px"
            />
          </div>
          <p class="note">（图片来源：互联网）</p>
          <p class="text">
            Saigon Children's Charity（西贡儿童慈善机构）成立于1992年，致力于为越南的贫困、残疾及偏远地区儿童提供教育机会和帮助，通过设立奖学金、建设教育设施和提供职业培训等方式，给予这些儿童更好的学习环境和发展机会。
          </p>
          <p class="text">
            此次捐赠彰显了好心冻科技有限公司对社会责任的积极承担和对教育事业的深切关注。
          </p>
        </div>
      </div>
      <div class="NIT-content-right">
        <div class="NIT-right-title fx-r">
          <div class="recent">近期最热文章</div>
          <div class="imageText fx-r">
            <span class="span-g">图文</span>
            <span class="span-I">|</span>
            <span
              ><router-link to="/VideoNews" class="span-v"
                >视频</router-link
              ></span
            >
          </div>
        </div>
        <div class="recommend fx-h">
          <div class="re-model fx-r" @click="news2">
            <img src="@/assets/new2.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>了解中国对农业发展的政策扶持</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news7">
            <img src="@/assets/new7.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>1月至3月阿根廷国内牛肉消费同比下降17.6% 出口仍保持增长</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news4">
            <img src="@/assets/new4.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>北海：2023年对越南外贸进出口总值59.3亿元</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news5">
            <img src="@/assets/new5.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>泰国将2024年大米出口预测上调至820万吨</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="re-model fx-r" @click="news6">
            <img src="@/assets/new6.png" alt="" width="48%" height="100%" />
            <div class="re-right fx-h">
              <p>4750亿美元！中国跌至第三，不再是美国第一大进口国</p>
              <div class="re-right-bottom">
                <span class="read">20次阅读</span
                ><span class="time">10分钟前</span>
              </div>
            </div>
          </div>
          <div class="see-more" @click="news">
            查看更多&nbsp;<img src="@/assets/MoreIcon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bar from "@/components/NavBar.vue";

export default {
  name: "NewsImageText1",
  components: {
    Bar,
  },
  methods: {
    news() {
      this.$router.push("/GraphicNews").catch((err) => {});
    },
    news2() {
      this.$router.push("/NewsImageText2").catch((err) => {});
    },
    news7() {
      this.$router.push("/NewsImageText7").catch((err) => {});
    },
    news4() {
      this.$router.push("/NewsImageText4").catch((err) => {});
    },
    news5() {
      this.$router.push("/NewsImageText5").catch((err) => {});
    },
    news6() {
      this.$router.push("/NewsImageText6").catch((err) => {});
    },
  },
};
</script>
<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
}
.BI1 {
  /* width: 100vw; */
  height: 500px;
  overflow-x: hidden;
  background-image: url(@/assets/新闻.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BI111 {
  color: aliceblue;
  font-size: 50px;
  letter-spacing: 30px;
}

.h3 {
  font-size: 30px;
  letter-spacing: 10px;
}

.NIT-content {
  width: 80%;
  margin-left: 10%;
  margin-top: 80px;
  margin-bottom: 50px;
}
.NIT-content-left {
  width: 55%;
  height: 100%;
}

.NIT-title h1 {
  margin-bottom: 5px;
}
.NIT-title span {
  font-size: 14px;
  color: #757575;
  margin-right: 20px;
}
.NIT-model1 {
  width: 100%;
  /* height: 500px; */
  margin-top: 40px;
}
.note {
  font-size: 14px;
  color: #757575;
  text-align: center;
}

.text {
  /* font-weight: 500; */
  font-size: 16px;
  letter-spacing: 5px;
  font-size: 16px;
  text-indent: 2em;
  margin-top: 30px;
}
.NIT-model1-photo2 {
  margin-top: 50px;
}
/* 右边 */
.NIT-content-right {
  width: 35%;
  height: 60%;
  margin-left: 10%;
}
.NIT-right-title {
  width: 100%;
  height: 39px;
  position: relative;
}
.recent {
  width: 100px;
  height: 25px;
  padding: 6px;
  border: 1px solid red;
  border-radius: 5px;
  position: absolute;
}
.imageText {
  width: fit-content;
  height: 25px;
  padding: 6px 10px;
  letter-spacing: 4px;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
}
.imageText a {
  text-decoration: none;
}
.span-g {
  color: red;
}
.span-v {
  color: black;
}
.recommend {
  width: 100%;
  height: 80%;
  margin-top: 30px;
}
.re-model {
  width: 100%;
  height: 120px;
  margin-top: 30px;
}
.re-right {
  width: 48%;
  margin-left: 4%;
  height: 100%;
}

.re-right-bottom {
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #757575;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.read {
  left: 0;
  position: absolute;
}
.time {
  position: absolute;
  right: 0;
}
.see-more {
  margin-top: 20px;
  border: 1px solid red;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  padding: 10px;
  letter-spacing: 5px;
}
</style>
