import { render, staticRenderFns } from "./Importquantity.vue?vue&type=template&id=467d513f&scoped=true"
import script from "./Importquantity.vue?vue&type=script&lang=js"
export * from "./Importquantity.vue?vue&type=script&lang=js"
import style0 from "./Importquantity.vue?vue&type=style&index=0&id=467d513f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "467d513f",
  null
  
)

export default component.exports