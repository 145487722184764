var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parent-container"},[_c('Bar'),_vm._m(0),_c('div',{staticClass:"NIT-content fx-r"},[_vm._m(1),_c('div',{staticClass:"NIT-content-right"},[_c('div',{staticClass:"NIT-right-title fx-r"},[_c('div',{staticClass:"recent"},[_vm._v("近期最热文章")]),_c('div',{staticClass:"imageText fx-r"},[_c('span',{staticClass:"span-g"},[_vm._v("图文")]),_c('span',{staticClass:"span-I"},[_vm._v("|")]),_c('span',[_c('router-link',{staticClass:"span-v",attrs:{"to":"/VideoNews"}},[_vm._v("视频")])],1)])]),_c('div',{staticClass:"recommend fx-h"},[_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news7}},[_c('img',{attrs:{"src":require("@/assets/new7.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(2)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news3}},[_c('img',{attrs:{"src":require("@/assets/new3.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(3)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news4}},[_c('img',{attrs:{"src":require("@/assets/new4.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(4)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news5}},[_c('img',{attrs:{"src":require("@/assets/new5.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(5)]),_c('div',{staticClass:"re-model fx-r",on:{"click":_vm.news6}},[_c('img',{attrs:{"src":require("@/assets/new6.png"),"alt":"","width":"48%","height":"100%"}}),_vm._m(6)]),_c('div',{staticClass:"see-more",on:{"click":_vm.news}},[_vm._v(" 查看更多 "),_c('img',{attrs:{"src":require("@/assets/MoreIcon.png"),"alt":""}})])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BI1"},[_c('div',{staticClass:"BI11 fx-h"},[_c('h1',{staticClass:"BI111"},[_vm._v("新闻")]),_c('br'),_c('a',{staticClass:"BI111 h3"},[_vm._v("掌握市场动态，领先一步")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NIT-content-left fx-h"},[_c('div',{staticClass:"NIT-title"},[_c('h1',[_vm._v("比普通牛肉贵一倍的牦牛肉，很多餐厅却抢着买")]),_c('span',[_vm._v("2024-07-20")]),_c('span',[_vm._v("12：30")]),_c('span',[_vm._v("来源 ：央广网")])]),_c('div',{staticClass:"NIT-model1 fx-h"},[_c('div',{staticClass:"NIT-model1-photo fx-r"},[_c('img',{attrs:{"src":require("@/assets/new8-01.png"),"alt":"","width":"100%","height":"450px"}})]),_c('p',{staticClass:"note"},[_vm._v("（图片来源：互联网）")]),_c('p',{staticClass:"text"},[_vm._v(" 牦牛主要分布在我国的喜马拉雅山脉和青藏高原地区，虽然新疆、广西也有，但相对于青藏高原地区，产量较小。 ")]),_c('p',{staticClass:"text"},[_vm._v(" 目前，全世界现有牦牛近1600万头，其中中国有1500余万头，是世界上拥有牦牛数量和品种类群最多的国家，且大多集中在青藏高原地区。 ")]),_c('p',{staticClass:"text"},[_vm._v(" 生活在雪域高原的牦牛，可以承受零下20度的寒冷气温，所以它们的毛发比一般的牛更厚更长，体型也更壮硕，非常好辨认。 ")]),_c('div',{staticClass:"NIT-model1-photo2 fx-r"},[_c('img',{attrs:{"src":require("@/assets/new8-02.png"),"alt":"","width":"100%","height":"450px"}})]),_c('p',{staticClass:"note"},[_vm._v("（图片来源：互联网）")]),_c('p',{staticClass:"text"},[_vm._v(" 据2022年3月惠农网牛肉行情走势图显示，黄牛牛肉的价格一斤27-38元，而牦牛牛肉的价格则卖到1斤40-60元，比普通的黄牛肉整整贵出了1倍。 ")]),_c('p',{staticClass:"text"},[_vm._v(" 产量低、出栏时间久、运输成本高等因素，导致了牦牛肉价格居高不下的情况。但也因为其产量稀缺，导致很多人都没法购买到真正的牦牛肉。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("1月至3月阿根廷国内牛肉消费同比下降17.6% 出口仍保持增长")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("优势互补、同向发力 农业农村部全力推进高水平农业科技自立自强")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("北海：2023年对越南外贸进出口总值59.3亿元")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("泰国将2024年大米出口预测上调至820万吨")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-right fx-h"},[_c('p',[_vm._v("4750亿美元！中国跌至第三，不再是美国第一大进口国")]),_c('div',{staticClass:"re-right-bottom"},[_c('span',{staticClass:"read"},[_vm._v("20次阅读")]),_c('span',{staticClass:"time"},[_vm._v("10分钟前")])])])
}]

export { render, staticRenderFns }