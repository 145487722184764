<template>
  <!-- 这是上半部分主体 -->
  <div class="container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <!-- 图片部分 -->
      <div class="fx-h">
        <div class="header">
          <img
            src="@/assets/销售网络.jpg"
            alt="1"
            width="100%"
            height="100%"
            class="background-image"
          />
          <h1 class="text-overlay">销 售 渠 道</h1>
          <br />
          <h3 class="text-overlay2">掌握市场动态，领先一步。</h3>
        </div>
      </div>
      <!-- 这是下半部分 -->
      <div class="row-b1">
        <div class="wp">
          <!-- 左边部分 -->
          <div class="col-l">
            <div class="work_list">
              <div
                class="tit"
                @click="selectContent('线上渠道')"
                :class="{ active: selectedContent === '线上渠道' }"
              >
                <div class="circle"></div>
                <div>线上渠道</div>
              </div>
              <div
                class="tit"
                @click="selectContent('线下渠道')"
                :class="{ active: selectedContent === '线下渠道' }"
              >
                <div class="circle"></div>
                <div>线下渠道</div>
              </div>
            </div>
          </div>
          <!-- 右边部分 -->
          <div class="col-r">
            <div class="m-chanpin">
              <div class="online fx-h" v-if="selectedContent === '线上渠道'">
                <div class="page-name">
                  <h2 class="name-text">线上渠道</h2>
                </div>
                <div class="model-1 fx-r">
                  <div class="model-1-left fx-h">
                    <img src="@/assets/brand01.png" alt="" width="100%" />
                    <div class="model-1-text">
                      好心冻科技有限公司不仅覆盖了线上交易的各个形式，包括但不限于APP，网页，小程序等，而且还为行业供应链提供了免费的在线实时全球领先的安全大数据支持。
                    </div>
                    <div class="model-1-text">
                      这些免费的实时在线支持包括但不限于：全产业链的高科技互联网信息支持，食品领域终端销售网络智能实时支持，全国冻品批发市场支持，全国食品安全监测检测支持，冷链物流的动态监控支持，全球屠宰工厂实时支持，提供餐饮业态的全方位解决方案等。
                    </div>
                  </div>
                </div>
              </div>
              <div class="offline fx-h" v-if="selectedContent === '线下渠道'">
                <div class="page-name">
                  <h2 class="name-text">线下渠道</h2>
                </div>
                <div class="model-1 fx-h">
                  <img src="@/assets/brand01.png" alt="" width="100%" />
                  <div class="model-1-text">
                    好心冻科技有限公司正在致力于建立覆盖中国省会城市的大宗批发网络，覆盖中国地级市传统冻品市场的批发销售网络，覆盖中国每一个县域的专卖店网络，覆盖中国每一个乡镇村的实体支持和配送体系。该配送系统将采用全球领先和符合欧洲食品标准的冷链配送车负责点对点的配送任务，以保证每一位客户拿到手里的冷冻食品是安全的。
                  </div>
                  <div class="model-1-text">
                    公司致力于打造中国食品配送领域的安全标准和国家层面的食品配送的基础工程。让食品成为食品，而不是任意的东西，被随意的像垃圾一样到处乱扔在不符合食品标准的脏乱差的地方。公司努力让食品安全和食品配送安全成为全球的标杆，成为食品安全标准的制定者。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import Video from "@/components/Video.vue";
import Bar from "@/components/NavBar.vue";

export default defineComponent({
  name: "JoinUs",
  components: {
    Video,
    Bar,
  },
  setup() {
    const selectedContent = ref("线下渠道");
    // 选择内容的方法
    function selectContent(content: string) {
      selectedContent.value = content;
    }
    return {
      selectedContent,
      selectContent,
    };
  },
  data() {
    return {
      //显示
      isShow: false,
    };
  },
  methods: {
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
});
</script>

<style scoped>
/* 上半部分 */
.container {
  /* width: 100vw; */
  max-width: 100%;
  position: relative;
  width: 100%;
}
.container .main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
/* 图片设置 */
.fx-h .header {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  position: relative;
}
.fx-h .text-overlay {
  position: absolute;
  font-size: 50px;
  top: 230px;
  color: white; /* 根据背景色设置合适的文字颜色 */
  font-weight: 500px;
}
.fx-h .text-overlay2 {
  position: absolute;
  top: 300px;
  color: white; /* 根据背景色设置合适的文字颜色 */
  font-size: 17px;
  font-weight: 500;
}
/* 下半部分 */
.row-b1 {
  width: 100%;
  overflow: hidden;
}
.wp {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
/* 左边 */
.row-b1 .col-l {
  width: 20vw;
  /* 显式地设置左边框 */
  border-left: 8px solid #e60012; /* 左边框宽度为5px，样式为实线，颜色为黑色 */
  /* 显式地设置右边框 */
  border-right: 1px solid #e60012; /* 右边框宽度为2px，样式为实线，颜色为黑色 */
}
.work_list {
  width: 100%;
  margin-bottom: 20px;
  /* 上下边框设置为0px（实际上，如果不设置它们，它们就默认为0px，但这里显式设置以表明意图） */
  border-top: 0px solid transparent; /* 宽度为0，颜色不影响显示 */
  border-bottom: 0px solid transparent; /* 同上 */
  /* 添加一些内边距以便更好地看到边框效果 */
}
.work_list .tit {
  display: flex;
  align-items: center; /* 垂直居中 */
  cursor: pointer;
  line-height: 44px;
  background-size: auto 10px;
  /* border-bottom: 1px solid #f2f2f2; */
  width: 100%;
  float: left;
  font-size: 20px;
}
.work_list .tit .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: #fff;
  display: flex;
  margin-right: 10px;
  margin-left: 30px;
}
.col-l .work_list .tit:hover {
  background-color: #e60012; /* 鼠标悬浮时背景变红 */
  transition: background-color 1s ease-in-out; /* 添加背景颜色变化的过渡效果 */
}

/* 右边 */
.row-b1 .col-r {
  float: none;
  overflow: hidden;
  width: 78%;
}
.m-chanpin {
  width: 100%;
  overflow: hidden;
}

.active {
  background-color: #e60012;
}
.online {
  width: 100%;
  margin-bottom: 50px;
}

/* 所在页面名称（通用） */
.page-name {
  width: 150px;
  height: 60px;
  border: 1px solid white;
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1),
              0 -8px 10px -5px rgba(0, 0, 0, 0.1);
  border-radius: 15px 15px 0px 15px;
  margin-top: 50px;
  margin-left: 100px;
  text-align: center;
  line-height: 60px;
}
.name-text {
  color: #E60012;
  letter-spacing: 2px;
}
/* 线上渠道文字在左边的模块 */

.online .model-1 {
  width: 80%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 60px;
}

.online .model-1-text {
  width: 100%;
  margin-top: 30px;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: 5px;
  text-indent: 2em;
}

.offline {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 50px;
}


/* 线下渠道01模块 */
.offline .model-1 {
  width: 80%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 60px;
}

.offline .model-1-text {
  width: 100%;
  margin-top: 30px;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: 5px;
  text-indent: 2em;
}
</style>
