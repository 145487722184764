<template>
  <div class="parent-container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <div class="BI1">
        <div class="BI11 fx-h">
          <h1 class="BI111">食品研究院</h1>
          <br />
          <h3 class="BI111">掌握市场动态，领先一步</h3>
        </div>
      </div>
      <div class="BI2 fx-r">
        <div class="sidebar">
          <ul>
            <li
              @click="selectContent('食品成份检测')"
              :class="{ active: selectedContent === '食品成份检测' }"
            >
              <div class="fx-r">
                <div class="circle"></div>
                <span>食品成份检测</span>
              </div>
            </li>
            <li
              @click="selectContent('食品有毒有害检测')"
              :class="{ active: selectedContent === '食品有毒有害检测' }"
            >
              <div class="fx-r">
                <div class="circle"></div>
                <span>食品有毒有害检测</span>
              </div>
            </li>
            <li
              @click="selectContent('农残检测')"
              :class="{ active: selectedContent === '农残检测' }"
            >
              <div class="fx-r">
                <div class="circle"></div>
                <span>农残检测</span>
              </div>
            </li>
            <li
              @click="selectContent('有机食品认证')"
              :class="{ active: selectedContent === '有机食品认证' }"
            >
              <div class="fx-r">
                <div class="circle"></div>
                <span>有机食品认证</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="BI22 content-area">
          <!-- 根据 selectedContent 显示内容 -->
          <div v-if="selectedContent === '食品成份检测'">
            <div class="L-title fx-h">
              <h2>好心冻科技有限公司食品检测</h2>
            </div>
            <div class="enterprise-info-form">
              <h2>食品成份检测信息</h2>
              <form @submit.prevent="submitForm">
                <div>
                  <label for="enterpriseName"
                    >企业名称：
                    <input
                      type="text"
                      id="enterpriseName"
                      v-model="formData.enterpriseName"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="legalRepresentative"
                    >法人代表：
                    <input
                      type="text"
                      id="legalRepresentative"
                      v-model="formData.legalRepresentative"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="registeredAddress"
                    >注册地址：
                    <input
                      type="text"
                      id="registeredAddress"
                      v-model="formData.registeredAddress"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="contactPhone"
                    >联系电话：
                    <input
                      type="text"
                      id="contactPhone"
                      v-model="formData.contactPhone"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="email"
                    >电子邮箱：
                    <input
                      type="email"
                      id="email"
                      v-model="formData.email"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="enterpriseNature"
                    >企业性质：
                    <select
                      type="nature"
                      id="enterpriseNature"
                      v-model="formData.enterpriseNature"
                      required
                    >
                      <option value="国有企业">国有企业</option>
                      <option value="私营企业">私营企业</option>
                      <option value="外资企业">外资企业</option>
                      <!-- 可以根据实际需要添加更多选项 -->
                    </select></label
                  >
                </div>
                <div>
                  <label for="establishmentDate"
                    >成立日期：
                    <input
                      type="date"
                      id="establishmentDate"
                      v-model="formData.establishmentDate"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="MainBusiness"
                    >主营业务：
                    <input
                      type="text"
                      id="MainBusiness"
                      v-model="formData.MainBusiness"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="textarea"
                    >需求描述：
                    <input
                      type="textarea"
                      id="textarea"
                      :rows="2"
                      style="height: 50px"
                      v-model="formData.textarea"
                      required
                    />
                  </label>
                </div>
                <div style="text-align: center">
                  <button style="text-align: center" type="submit">提交</button>
                </div>
              </form>
            </div>
          </div>
          <div v-else-if="selectedContent === '食品有毒有害检测'">
            <div class="L-title fx-h">
              <h2>好心冻科技有限公司食品检测</h2>
            </div>
            <div class="enterprise-info-form">
              <h2>食品有毒有害检测信息</h2>
              <form @submit.prevent="submitForm">
                <div>
                  <label for="enterpriseName"
                    >企业名称：
                    <input
                      type="text"
                      id="enterpriseName"
                      v-model="formData.enterpriseName"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="legalRepresentative"
                    >法人代表：
                    <input
                      type="text"
                      id="legalRepresentative"
                      v-model="formData.legalRepresentative"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="registeredAddress"
                    >注册地址：
                    <input
                      type="text"
                      id="registeredAddress"
                      v-model="formData.registeredAddress"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="contactPhone"
                    >联系电话：
                    <input
                      type="text"
                      id="contactPhone"
                      v-model="formData.contactPhone"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="email"
                    >电子邮箱：
                    <input
                      type="email"
                      id="email"
                      v-model="formData.email"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="enterpriseNature"
                    >企业性质：
                    <select
                      type="nature"
                      id="enterpriseNature"
                      v-model="formData.enterpriseNature"
                      required
                    >
                      <option value="国有企业">国有企业</option>
                      <option value="私营企业">私营企业</option>
                      <option value="外资企业">外资企业</option>
                      <!-- 可以根据实际需要添加更多选项 -->
                    </select></label
                  >
                </div>
                <div>
                  <label for="establishmentDate"
                    >成立日期：
                    <input
                      type="date"
                      id="establishmentDate"
                      v-model="formData.establishmentDate"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="MainBusiness"
                    >主营业务：
                    <input
                      type="text"
                      id="MainBusiness"
                      v-model="formData.MainBusiness"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="textarea"
                    >需求描述：
                    <input
                      type="textarea"
                      id="textarea"
                      :rows="2"
                      style="height: 50px"
                      v-model="formData.textarea"
                      required
                    />
                  </label>
                </div>
                <div style="text-align: center">
                  <button style="text-align: center" type="submit">提交</button>
                </div>
              </form>
            </div>
          </div>
          <div v-else-if="selectedContent === '农残检测'">
            <div class="L-title fx-h">
              <h2>好心冻科技有限公司食品检测</h2>
            </div>
            <div class="enterprise-info-form">
              <h2>农残检测信息</h2>
              <form @submit.prevent="submitForm">
                <div>
                  <label for="enterpriseName"
                    >企业名称：
                    <input
                      type="text"
                      id="enterpriseName"
                      v-model="formData.enterpriseName"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="legalRepresentative"
                    >法人代表：
                    <input
                      type="text"
                      id="legalRepresentative"
                      v-model="formData.legalRepresentative"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="registeredAddress"
                    >注册地址：
                    <input
                      type="text"
                      id="registeredAddress"
                      v-model="formData.registeredAddress"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="contactPhone"
                    >联系电话：
                    <input
                      type="text"
                      id="contactPhone"
                      v-model="formData.contactPhone"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="email"
                    >电子邮箱：
                    <input
                      type="email"
                      id="email"
                      v-model="formData.email"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="enterpriseNature"
                    >企业性质：
                    <select
                      type="nature"
                      id="enterpriseNature"
                      v-model="formData.enterpriseNature"
                      required
                    >
                      <option value="国有企业">国有企业</option>
                      <option value="私营企业">私营企业</option>
                      <option value="外资企业">外资企业</option>
                      <!-- 可以根据实际需要添加更多选项 -->
                    </select></label
                  >
                </div>
                <div>
                  <label for="establishmentDate"
                    >成立日期：
                    <input
                      type="date"
                      id="establishmentDate"
                      v-model="formData.establishmentDate"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="MainBusiness"
                    >主营业务：
                    <input
                      type="text"
                      id="MainBusiness"
                      v-model="formData.MainBusiness"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="textarea"
                    >需求描述：
                    <input
                      type="textarea"
                      id="textarea"
                      :rows="2"
                      style="height: 50px"
                      v-model="formData.textarea"
                      required
                    />
                  </label>
                </div>
                <div style="text-align: center">
                  <button style="text-align: center" type="submit">提交</button>
                </div>
              </form>
            </div>
          </div>
          <div v-else-if="selectedContent === '有机食品认证'">
            <div class="L-title fx-h">
              <h2>好心冻科技有限公司食品检测</h2>
            </div>
            <div class="enterprise-info-form">
              <h2>有机食品认证信息</h2>
              <form @submit.prevent="submitForm">
                <div>
                  <label for="enterpriseName"
                    >企业名称：
                    <input
                      type="text"
                      id="enterpriseName"
                      v-model="formData.enterpriseName"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="legalRepresentative"
                    >法人代表：
                    <input
                      type="text"
                      id="legalRepresentative"
                      v-model="formData.legalRepresentative"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="registeredAddress"
                    >注册地址：
                    <input
                      type="text"
                      id="registeredAddress"
                      v-model="formData.registeredAddress"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="contactPhone"
                    >联系电话：
                    <input
                      type="text"
                      id="contactPhone"
                      v-model="formData.contactPhone"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="email"
                    >电子邮箱：
                    <input
                      type="email"
                      id="email"
                      v-model="formData.email"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="enterpriseNature"
                    >企业性质：
                    <select
                      type="nature"
                      id="enterpriseNature"
                      v-model="formData.enterpriseNature"
                      required
                    >
                      <option value="国有企业">国有企业</option>
                      <option value="私营企业">私营企业</option>
                      <option value="外资企业">外资企业</option>
                      <!-- 可以根据实际需要添加更多选项 -->
                    </select></label
                  >
                </div>
                <div>
                  <label for="establishmentDate"
                    >成立日期：
                    <input
                      type="date"
                      id="establishmentDate"
                      v-model="formData.establishmentDate"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="MainBusiness"
                    >主营业务：
                    <input
                      type="text"
                      id="MainBusiness"
                      v-model="formData.MainBusiness"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="textarea"
                    >需求描述：
                    <input
                      type="textarea"
                      id="textarea"
                      :rows="2"
                      style="height: 50px"
                      v-model="formData.textarea"
                      required
                    />
                  </label>
                </div>
                <div style="text-align: center">
                  <button style="text-align: center" type="submit">提交</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";
import Video from "@/components/Video.vue";
import { ref } from "vue";

export default {
  name: "FoodResearch",
  components: {
    Bar,
    Video,
  },
  setup() {
    const selectedContent = ref("农残检测");
    // 选择内容的方法
    function selectContent(content) {
      selectedContent.value = content;
    }
    return {
      selectedContent,
      selectContent,
    };
  },
  data() {
    return {
      //显示
      isShow: false,
      //客户数据
      formData: {
        enterpriseName: "",
        legalRepresentative: "",
        registeredAddress: "",
        contactPhone: "",
        email: "",
        enterpriseNature: "",
        establishmentDate: "",
        businessScope: "",
        MainBusiness: "",
        textarea: "",
      },
    };
  },
  methods: {
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
};
</script>

<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.BI1 {
  height: 500px;
  overflow-x: hidden;
  background-image: url(@/assets/食品研究院.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BI111 {
  color: aliceblue;
}
.ul1 {
  font-size: 20px;
  text-decoration: none;
  color: black;
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: #ffffff;
  display: flex;
  margin-top: 9px;
  margin-right: 10px;
  margin-left: 30px;
}
.sidebar {
  width: 20vw;
  border-right: 1px solid red;
  border-left: 8px solid red;
  background-color: white;
  cursor: pointer;
}
li {
  font-size: 20px;
}
.sidebar ul {
  /* list-style-type: none; 去除圆圈 */
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 10px;
  cursor: pointer;
}

.sidebar ul li:hover {
  background-color: rgb(220, 23, 23);
}

.content-area {
  width: 78%;
  height: 100%;
  margin-left: 10px;
  padding: 0px;
  margin-bottom: 50px;
}
.BI2 {
  width: 100%;
  /* height: 1500px; */
  height: auto;
  overflow: hidden;
}

.BI22 {
  width: 75%;
  height: 100%;
}
.BI2211 {
  width: 200px;
  height: 80px;
  border: 1px solid red;
  border-radius: 20px 20px 20px 20px;
  background-color: lightpink;
  margin-top: 50px;
  margin-left: 100px;
  text-align: left 20px;
}
.BI2212 {
  margin-top: 10px;
  margin-left: 20px;
}
.BI2213 {
  margin-top: 10px;
  font-size: 10dm;
  margin-left: 20px;
}
.BI2221 {
  height: 200px;
  width: 90%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
.BI2221::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid red;
}
.BI22211 {
  height: 200px;
  width: 90%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
.BI22211::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-top: 20px solid red;
}
.BI2222 {
  width: 49%;
  height: 100%;
  margin-left: 10px;
  margin-top: 100px;
  margin-right: 100px;
}
.BI2223 {
  width: 50%;
  height: 100%;
  margin-left: 50px;
  margin-top: 100px;
}
.BI22221 {
  letter-spacing: 4px;
}
.BI01 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/Frame\ 18.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-left: 50px; */
}
.BI02 {
  width: 43%;
  height: 100%;
  background-image: url(@/assets/Rectangle\ 11.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI03 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/Frame\ 1.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI04 {
  width: 43%;
  height: 100%;
  background-image: url(@/assets/线上渠道4.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI05 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/线上渠道5.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI06 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/线上渠道6.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}

/* 法务标题 */
.L-title {
  margin-top: 40px;
}
.L-title h2 {
  font-size: 35px;
  letter-spacing: 5px;
  text-align: center;
}
.L-title p {
  font-size: 14px;
  margin-top: 20px;
  margin-left: 100px;
  color: #6c6c6c;
}
.L-title p span {
  margin-right: 130px;
}
/* 法务模块1 */
.model-L1 {
  height: 100px;
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 60px;
}

.title {
  font-size: 30px;
}
.number {
  color: red;
  margin-right: 15px;
}
/* 法务模块1文字 */
.model-L1-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 5px;
}
.model-L1-text p {
  font-size: 16px;
  text-indent: 2em;
  margin-top: 30px;
}

/* 法务模块2 */
.model-L2 {
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
/* 法务模块2文字 */
.model-L2-text {
  width: 100%;
  height: 100%;
  font-size: 16px;
  margin-top: 30px;
  text-indent: 2em;
  letter-spacing: 5px;
}
.model-L2-text h4 {
  margin-bottom: 10px;
  font-weight: 400;
}
.model-L2-text p {
  margin-bottom: 10px;
}
.enterprise-info-form {
  background-color: #fff; /* 假设背景是白色的 */
  border: 1px solid #e60012; /* 假设有一个红色的边框 */
  border-radius: 5px; /* 边框圆角 */
  width: 60%;
  margin: 10px auto; /* 上下外边距20px，左右自动居中 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微的阴影效果 */
  font-family: Arial, sans-serif; /* 字体设置 */
}
.enterprise-info-form h2 {
  text-align: center;
  padding-top: 10px;
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e60012;
}
.enterprise-info-form form {
  margin-top: 50px;
}
.enterprise-info-form label {
  text-align: center; /* 文本在 label 内部居中 */
  display: block;
  margin-bottom: 5px; /* 标签与输入框之间的间距 */
  font-weight: bold; /* 假设标签是粗体的 */
  color: #333; /* 文本颜色 */
}
.enterprise-info-form input[type="text"],
.enterprise-info-form input[type="email"],
.enterprise-info-form input[type="textarea"],
.enterprise-info-form select[type="nature"],
.enterprise-info-form input[type="date"] {
  width: 300px; /* 减去左右padding和border的宽度 */
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}
.enterprise-info-form el-input {
  width: 300px; /* 减去左右padding和border的宽度 */
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}
.enterprise-info-form button {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 150px;
  padding: 10px;
  background-color: #e60012; /* 鲜红色背景 */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* 假设按钮上的文字稍大一些 */
}
.active {
  background-color: #e60012;
}
</style>
