<template>
  <div class="parent-container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <div class="header">
        <div>
          <img src="@/assets/logo.png" alt="" class="icon-overlay" />
          <p class="header-text">好 心 冻 科 技 有 限 公 司</p>
        </div>
      </div>
      <div class="content fx-r">
        <div class="sidebar">
          <ul>
            <router-link to="/introduction"
              ><li
                @click="selectContent('品牌介绍')"
                :class="{ active: selectedContent === '品牌介绍' }"
              >
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>品牌介绍</span>
                </div>
              </li></router-link
            >
            <router-link to="/introduction"
              ><li
                @click="selectContent('关于我们')"
                :class="{ active: selectedContent === '关于我们' }"
              >
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>关于我们</span>
                </div>
              </li></router-link
            >
            <router-link to="/introduction"
              ><li
                @click="selectContent('法务团队')"
                :class="{ active: selectedContent === '法务团队' }"
              >
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>法务团队</span>
                </div>
              </li></router-link
            >
            <router-link to="/introduction"
              ><li
                @click="selectContent('媒体宣传')"
                :class="{ active: selectedContent === '媒体宣传' }"
              >
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>媒体宣传</span>
                </div>
              </li></router-link
            >
            <router-link to="/introduction"
              ><li
                @click="selectContent('投资者关系')"
                :class="{ active: selectedContent === '投资者关系' }"
              >
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>投资者关系</span>
                </div>
              </li></router-link
            >
            <router-link to="/introduction"
              ><li
                @click="selectContent('联系我们')"
                :class="{ active: selectedContent === '联系我们' }"
              >
                <div class="fx-r">
                  <div class="circle"></div>
                  <span>联系我们</span>
                </div>
              </li></router-link
            >
          </ul>
        </div>
        <div class="content-area">
          <!-- 根据 selectedContent 显示内容 -->
          <div v-if="selectedContent === '品牌介绍'">
            <div class="page-name">
              <h2 class="name-text">品牌介绍</h2>
            </div>
            <div class="model-BI1">
              <img src="@/assets/brand01.png" alt="" width="100%" />
            </div>
            <div class="model-BI2">
              <span>好心冻科技有限公司拥有以下</span
              ><span class="span1">四个平台品牌:</span>
              <div class="BI2-text">
                <span class="span1">1、好心冻</span
                ><span>——全球冷冻食品独角兽：冷冻冷藏食品交易平台</span>
              </div>
              <div class="BI2-text">
                <span class="span1">2、冷小盟</span
                ><span>——全国冷链物流联盟：冷冻冷藏食品冷链运输平台</span>
              </div>
              <div class="BI2-text">
                <span class="span1">3、有姬</span
                ><span>——全球有机食品集合地：全球有机食品交易平台</span>
              </div>
              <div class="BI2-text">
                <span class="span1">4、冷小宝</span
                ><span>——服务全球农业的资本：专业服务食品的农业资本平台</span>
              </div>
            </div>
          </div>
          <div v-else-if="selectedContent === '关于我们'">
            <div class="page-name">
              <h2 class="name-text">关于我们</h2>
            </div>
            <div class="model-AU fx-h">
              <div class="model-AU1">
                <img src="@/assets/brand01.png" alt="" width="100%" />
              </div>
              <div class="model-AU1-text1">
                好心冻科技有限公司是一家垂直产业互联网+的高科技用户导向性的S2B2C平台公司。公司由四个平台组成，分别是好心冻——全球冷冻冷藏食品交易平台，冷小盟——冷链物流运输平台，冷小宝——冷冻冷藏食品金融平台，有姬——全球有机食品交易平台构成。公司为保证每一个流通环节能为消费者带来极致的体验和买到的食品没有任何食品安全隐患，公司的所有平台都采用自营模式。
              </div>
              <div class="model-AU1-text1">
                好心冻科技有限公司有四个中心，一个研究院，一套食品追溯体系，和对食品严谨的监督监测态度。公司拥有品牌孵化中心，网络科技研发中心，食品安全检测中心，有机食品认证中心。食品研究院不仅严格对产品进行各种检测，而且携手用户共同研发和创新运用产品。公司建立了一套从食品源头，流通，加工，销售，配送到消费者的一套可追溯的识别码体系，以保证每一份食品到了消费者手中都是安全的。公司对食品流转的每一个环节都会进行严格的监督和检查，以便确保食品的安全和可靠。
              </div>
              <div class="model-AU1-text1">
                好心冻科技有限公司的愿景是打造一家冷冻冷藏食品领域的业务遍及全球的产业独角兽企业，精准服务全球食品工厂，高效使用资本为农业服务，最终让消费者购买安心，吃得放心。
              </div>
            </div>
          </div>
          <div v-else-if="selectedContent === '法务团队'">
            <div class="page-name">
              <h2 class="name-text">法务团队</h2>
            </div>
            <div class="model-L1 fx-h">
              <div class="model-L1-img">
                <img src="@/assets/brand01.png" alt="" width="100%" />
              </div>
              <div class="model-L1-text fx-h">
                <p>
                  法务团队。好心冻科技有限公司拥有一支专业严谨的法务团队以保证公司的任何行动不侵犯他人合法的权益和知识产权。同时该团队也保证本公司的一切知识产权和合法权益不被他人侵犯和冒用。
                </p>
                <p>
                  产权意识。公司的宣传资料和部分素材可能来源于网络，如果您是该素材的原创，请通过邮件联系我们并提供证据，然后我们会为您的原创和知识产权付费使用。公司一直尊重一切原创和知识产权，同时也崇尚产权的法务意识。
                </p>
                <p>
                  欢迎法务人才的加入。公司欢迎各领域法务人才的加入，例如知识产权，国际法，食品安全，合同法，熟悉互联网相关领域法务的人才加入。
                </p>
              </div>
            </div>
          </div>
          <div v-else-if="selectedContent === '媒体宣传'">
            <div class="page-name">
              <h2 class="name-text">媒体宣传</h2>
            </div>
            <div class="model-M1 fx-h">
              <div class="model-M1-text fx-h">
                <img src="@/assets/brand01.png" alt="" width="100%" />
                <p>
                  公司内部平台媒体主要致力于行业新闻和可能导致行业变动的因素的全球新闻媒体编辑。公司媒体平台也致力于公司内部新闻动态的发布，垂直产业链信息的整合，也关注全球农业的风向。
                </p>
                <p>
                  公司外部媒体的合作致力于推广公司的平台品牌和产品品牌。公司将通过与传统媒体合作，流媒体合作，以及网络媒体，新媒体，户外媒体等现代媒体合作，打造和推广公司的全部品牌。公司急需大量具有深厚经验和实战领域的专业媒体人才，欢迎加入我们！
                </p>
              </div>
            </div>
          </div>
          <div v-else-if="selectedContent === '投资者关系'">
            <div class="page-name">
              <h2 class="name-text">投资者关系</h2>
            </div>
            <div class="I-content">
              <div class="model-I1 fx-r">
                <div class="model-I1-left">
                  四川三指彪供应链管理中心（有限合伙）
                </div>
                <div class="model-I1-right">成都晓皇品牌管理有限公司</div>
              </div>
              <div class="line1"></div>
              <div class="model-I2">四川好心冻供应链科技有限公司</div>
              <div class="line2 fx-r"><div class="line21"></div></div>
              <div class="model-I3 fx-r">
                <div class="model-I3-left">有姬食品有限公司</div>
                <div class="model-I3-middle">好心冻科技有限公司</div>
                <div class="model-I3-right">冷小宝科技有限公司</div>
              </div>
              <div class="line3"></div>
              <div class="model-I4 fx-r">
                <div class="model-I4-left">好心冻网络科技（成都）有限公司</div>
                <div class="model-I4-right">冷小链科技（成都）有限公司</div>
              </div>
            </div>
          </div>
          <div v-else-if="selectedContent === '联系我们'">
            <div class="page-name">
              <h2 class="name-text">联系我们</h2>
            </div>
            <div class="contact-text">
              <p class="contact-one">
                我们始终倾听您的声音，期待与您建立联系。
              </p>
              <ul>
                <li>地址：四川省成都市双流区彭镇国芯大道338号1栋</li>
                <li>电话：028-60625493</li>
                <li>邮箱：haoxindong@haoxindong.com</li>
                <li>联系：刘老师</li>
              </ul>
              <p class="contact-two">
                或者，您可以直接填写以下表单，我们将尽快回复您的消息：
              </p>
            </div>
            <div class="contact-form">
              <h2>联系我们</h2>
              <form @submit.prevent="submitForm">
                <div>
                  <label for="Name"
                    >姓名：
                    <input
                      type="text"
                      id="Name"
                      v-model="formData.Name"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="Email"
                    >邮箱：
                    <input
                      type="email"
                      id="Email"
                      v-model="formData.Email"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="Phone"
                    >电话：
                    <input
                      type="text"
                      id="Phone"
                      v-model="formData.Phone"
                      required
                    />
                  </label>
                </div>
                <div>
                  <label for="leaveMessage"
                    >留言：
                    <input
                      type="textarea"
                      id="contactPhone"
                      v-model="formData.contactPhone"
                      required
                    />
                  </label>
                </div>
                <div style="text-align: center">
                  <button style="text-align: center" type="submit">提交</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";
import Video from "@/components/Video.vue";
import { ref } from "vue";
// import Footer from "@/components/FooterView.vue";

export default {
  name: "IntroductionView",
  components: {
    Video,
    Bar,
    //Footer,
  },
  setup() {
    // 当前选中的内容
    const selectedContent = ref("品牌介绍");

    // 选择内容的方法
    function selectContent(content) {
      selectedContent.value = content;
    }
    return {
      selectedContent,
      selectContent,
    };
  },
  data() {
    return {
      formData: {
        Name: "",
        Email: "",
        Phone: "",
        leaveMessage: "",
      },
      menuItems: [
        {
          title: "联系我们",
          subMenus: [{ title: "咨询" }, { title: "反馈" }, { title: "建议" }],
          isOpen: false,
        },
      ],
      //显示
      isShow: false,
    };
  },
  methods: {
    //提交
    submitForm() {
      // 在这里你可以添加逻辑来处理表单提交，比如发送到服务器
      console.log(this.formData);
    },
    toggleSubMenu(item) {
      item.isOpen = !item.isOpen;
    },
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
};
</script>

<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
/* 大图片 */
.header {
  height: 500px;
  overflow: hidden;
  background-image: url(@/assets/公司.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 图片中间的内容 */
.header-text {
  color: white;
  font-size: 40px; /* 字体大小 */
  margin-top: 10px;
}
/* 公司logo */
.icon-overlay {
  width: 300px;
  height: auto;
  display: block;
  margin: 0 auto;
}
/* 图片中间文字 */
.header-text-right {
  width: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}
.text-overlay {
  font-size: 24px;
  letter-spacing: 5px;
  color: white;
}
.text-overlay2 {
  color: white;
  font-size: 16px;
}
/* 图片以下部分 */
.content {
  width: 100%;
  height: auto;
  overflow: hidden;
}
/* 侧边栏圆圈 */
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: white;
  display: flex;
  margin-top: 9px;
  margin-right: 10px;
  margin-left: 30px;
}
.sidebar {
  width: 20vw;
  border-right: 1px solid red;
  border-left: 8px solid red;
  background-color: white;
  cursor: pointer;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 10px;
  font-size: 20px;
}

.sidebar ul li:hover {
  background-color: rgb(220, 23, 23);
}
.sidebar a {
  color: black;
  text-decoration: none;
}

.active {
  background-color: #e60012;
}

/* 侧边栏右边内容 */
.content-area {
  width: 78%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 50px;
}

/* 所在页面名称（通用） */
.page-name {
  width: 150px;
  height: 60px;
  border: 1px solid white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0 -8px 10px -5px rgba(0, 0, 0, 0.1);
  border-radius: 15px 15px 0px 15px;
  margin-top: 50px;
  margin-left: 100px;
  text-align: center;
  line-height: 60px;
}
.name-text {
  color: #e60012;
  letter-spacing: 2px;
}

/* 品牌介绍 */
.model-BI1 {
  margin-left: 100px;
  margin-top: 60px;
  width: 80%;
}
.model-BI2 {
  margin-left: 100px;
  margin-top: 40px;
  width: 80%;
  letter-spacing: 5px;
}
.BI2-text {
  margin-top: 15px;
  letter-spacing: 5px;
}
.span1 {
  font-weight: 600;
}

/* 关于我们 */
.model-AU {
  width: 80%;
  margin-left: 100px;
}
.model-AU1 {
  margin-top: 60px;
  margin-bottom: 40px;
}
.model-AU1-text1 {
  font-size: 16px;
  line-height: 30px;
  text-indent: 2em;
  letter-spacing: 5px;
  margin-bottom: 30px;
}

/* 法务模块1 */
.model-L1 {
  /* height: 100px; */
  width: 80%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 60px;
}

.model-L1-img {
  width: 100%;
}

/* 法务模块1文字 */
.model-L1-text {
  width: 100%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 5px;
  line-height: 30px;
}
.model-L1-text p {
  font-size: 16px;
  text-indent: 2em;
  margin-top: 30px;
}

/* 媒体模块1 */
.model-M1 {
  width: 80%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 60px;
}
.model-M1-text {
  letter-spacing: 5px;
  line-height: 30px;
}
.model-M1-text p {
  font-size: 16px;
  text-indent: 2em;
  margin-top: 30px;
}
.model-M1-text img {
  margin-top: 20px;
}
/* 投资者关系模块1 */
.I-content{
  width: 900px;
  height: 500px;
  margin-left: 100px;
  margin-top: 60px;
  font-size: 15px;
  position: relative;
}
.model-I1 {
  width: 100%;
  text-align: center;
}
.model-I1-left {
  width: 288px;
  border: 1px solid red;
  padding: 5px 0;
}
.model-I1-right {
  width: 218px;
  border: 1px solid red;
  margin-left: 410px;
  padding: 5px 0;
  right: 0;
  position: absolute;
}
.line1 {
  width: 653px;
  height: 100px;
  border-left: 1px solid red;
  border-right: 1px solid red;
  margin-left: 135px;
}
/* 投资者关系模块2 */
.model-I2 {
  width: 898px;
  border: 1px solid red;
  text-align: center;
  padding: 10px 0;
}
.line2 {
  width: 698px;
  height: 80px;
  border-left: 1px solid red;
  border-right: 1px solid red;
  margin-left: 100px;
}
.line21 {
  width: 348px;
  height: 100%;
  border-right: 1px solid red;
}
/* 投资者关系模块3 */
.model-I3 {
  width: 900px;
  text-align: center;
}
.model-I3-left {
  width: 198px;
  border: 1px solid red;
  padding: 5px 0;
}
.model-I3-middle {
  width: 298px;
  border: 1px solid red;
  padding: 5px 0;
  margin: 0 100px;
}
.model-I3-right {
  width: 198px;
  border: 1px solid red;
  padding: 5px 0;
  right: 0;
  position: absolute;
}
.line3 {
  width: 258px;
  height: 80px;
  border-left: 1px solid red;
  border-right: 1px solid red;
  margin-left: 320px;
}
/* 投资者关系模块4 */
.model-I4 {
  width: 900px;
  text-align: center;
}
.model-I4-left {
  width: 268px;
  border: 1px solid red;
  padding: 5px 0;
  margin-left: 140px;
}
.model-I4-right {
  width: 218px;
  border: 1px solid red;
  margin-left: 80px;
  padding: 5px 0;
}

/* 联系我们文字部分 */
.contact-text {
  width: 1055.5px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
  font-size: 16px;
  font-family: "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Arial, sans-serif;
}
.contact-text p {
  letter-spacing: 5px;
}
.contact-one {
  margin-top: 20px;
}
.contact-two {
  margin-top: 60px;
}
.contact-text ul {
  font-weight: 400;
  margin-top: 18px;
  margin-left: 20px;
}
/* 联系我们表单部分 */
.contact-form {
  background-color: #fff; /* 假设背景是白色的 */
  border: 1px solid #e60012; /* 假设有一个红色的边框 */
  border-radius: 5px; /* 边框圆角 */
  width: 70%;
  margin: 10px 100px; /* 上下外边距20px，左右100px */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微的阴影效果 */
  font-family: Arial, sans-serif; /* 字体设置 */
}
.contact-form h2 {
  text-align: center;
  padding-top: 10px;
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e60012;
}
.contact-form form {
  margin-top: 10px;
}

.contact-form label {
  text-align: center; /* 文本在 label 内部居中 */
  display: block;
  font-size: 18px;
  margin-bottom: 5px; /* 标签与输入框之间的间距 */
  font-weight: bold; /* 标签是粗体的 */
  color: #333; /* 文本颜色 */
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="textarea"] {
  width: 600px;
  height: 30px; /* 减去左右padding和border的宽度 */
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 15px;
}
.contact-form input[type="textarea"] {
  height: 300px;
}

.contact-form button {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 150px;
  padding: 10px;
  background-color: #e60012; /* 鲜红色背景 */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* 假设按钮上的文字稍大一些 */
}
</style>
