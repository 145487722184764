<template>
  <div class="parent-container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px"/>
      <div class="BI1">
      <div class="BI11 fx-h">
        <h1 class="BI111">食品研究院</h1>
        <br />
        <h3 class="BI111">掌握市场动态，领先一步</h3>
      </div>
      </div>
      <div class="BI2 fx-r">
      <div class="BI21 sidebar">
        <ul>
          <li @click="selectContent('食品安全')">
            <div class="fx-r">
              <div class="circle"></div>
              <span>食品安全</span>
            </div>
          </li>
          <li @click="selectContent('食品检测')">
            <div class="fx-r">
              <div class="circle"></div>
              <span>食品检测</span>
            </div>
          </li>
          <li @click="selectContent('农残检测')">
            <div class="fx-r">
              <div class="circle"></div>
              <span>农残检测</span>
            </div>
          </li>
          <li @click="selectContent('有机食品认证')">
            <div class="fx-r">
              <div class="circle"></div>
              <span>有机食品认证</span>
            </div>
          </li>
          <li @click="selectContent('成分检测')">
            <div class="fx-r">
              <div class="circle"></div>
              <span>成分检测</span>
            </div>
          </li>
          <li @click="selectContent('食品科技')">
            <div class="fx-r">
              <div class="circle"></div>
              <span>食品科技</span>
            </div>
          </li>
          <li @click="selectContent('餐饮业态')">
            <div class="fx-r">
              <div class="circle"></div>
              <span>餐饮业态</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="BI22 content-area">
        <!-- 根据 selectedContent 显示内容 -->
        <div v-if="selectedContent === '食品安全'">
          <div class="BI2211">
            <h2 class="BI2212">食品安全</h2>
            <a class="BI2213">Traditional&nbsp;Channels</a>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;好心动科技有限公司，一家专注于提供高品质冻
              </span>
              <span class="BI22221">
                品的企业，致力于为消费者带来安全、美味、便捷的
              </span>
              <span class="BI22221">食品体验。</span>
            </div>
            <div class="BI01"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI02"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的品牌理念是“品质至上，服务第一”。公
              </span>
              <span class="BI22221">
                司拥有严格的质量控制体系，从源头抓起，确保每
              </span>
              <span class="BI22221">一件产品都符合严格的质量标准</span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们与众多优质供应商建立了长期合作关系，精选
              </span>
              <span class="BI22221">
                新鲜、优质的食材，采用先进的冷冻技术，保留食材的
              </span>
              <span class="BI22221">原汁原味和营养成分。</span>
            </div>
            <div class="BI03"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI04"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的产品种类丰富多样，涵盖了肉类、海
              </span>
              <span class="BI22221">
                鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是
              </span>
              <span class="BI22221">
                餐饮行业，我们都能满足您的需求。我们的产品不
              </span>
              <span class="BI22221">
                仅品质优良，而且价格合理，具有很高的性价比。
              </span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除了产品品质，我们还注重服务质量。我们拥有
              </span>
              <span class="BI22221">
                一支专业的销售团队和客服团队，能够为客户提供及时、
              </span>
              <span class="BI22221">周到的服务</span>
            </div>
            <div class="BI05"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI06"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们还提供定制化的解决方案，根据客户的
              </span>
              <span class="BI22221">需求提供个性化的产品和服务。</span>
            </div>
          </div>
        </div>
        <div v-else-if="selectedContent === '食品检测'">
          <div class="BI2211">
            <h2 class="BI2212">食品检测</h2>
            <a class="BI2213">Traditional&nbsp;Channels</a>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;好心动科技有限公司，一家专注于提供高品质冻
              </span>
              <span class="BI22221">
                品的企业，致力于为消费者带来安全、美味、便捷的
              </span>
              <span class="BI22221">食品体验。</span>
            </div>
            <div class="BI01"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI02"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的品牌理念是“品质至上，服务第一”。公
              </span>
              <span class="BI22221">
                司拥有严格的质量控制体系，从源头抓起，确保每
              </span>
              <span class="BI22221">一件产品都符合严格的质量标准</span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们与众多优质供应商建立了长期合作关系，精选
              </span>
              <span class="BI22221">
                新鲜、优质的食材，采用先进的冷冻技术，保留食材的
              </span>
              <span class="BI22221">原汁原味和营养成分。</span>
            </div>
            <div class="BI03"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI04"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的产品种类丰富多样，涵盖了肉类、海
              </span>
              <span class="BI22221">
                鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是
              </span>
              <span class="BI22221">
                餐饮行业，我们都能满足您的需求。我们的产品不
              </span>
              <span class="BI22221">
                仅品质优良，而且价格合理，具有很高的性价比。
              </span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除了产品品质，我们还注重服务质量。我们拥有
              </span>
              <span class="BI22221">
                一支专业的销售团队和客服团队，能够为客户提供及时、
              </span>
              <span class="BI22221">周到的服务</span>
            </div>
            <div class="BI05"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI06"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们还提供定制化的解决方案，根据客户的
              </span>
              <span class="BI22221">需求提供个性化的产品和服务。</span>
            </div>
          </div>
        </div>
        <div v-else-if="selectedContent === '农残检测'">
          <div class="BI2211">
            <h2 class="BI2212">农残检测</h2>
            <a class="BI2213">Traditional&nbsp;Channels</a>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;好心动科技有限公司，一家专注于提供高品质冻
              </span>
              <span class="BI22221">
                品的企业，致力于为消费者带来安全、美味、便捷的
              </span>
              <span class="BI22221">食品体验。</span>
            </div>
            <div class="BI01"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI02"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的品牌理念是“品质至上，服务第一”。公
              </span>
              <span class="BI22221">
                司拥有严格的质量控制体系，从源头抓起，确保每
              </span>
              <span class="BI22221">一件产品都符合严格的质量标准</span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们与众多优质供应商建立了长期合作关系，精选
              </span>
              <span class="BI22221">
                新鲜、优质的食材，采用先进的冷冻技术，保留食材的
              </span>
              <span class="BI22221">原汁原味和营养成分。</span>
            </div>
            <div class="BI03"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI04"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的产品种类丰富多样，涵盖了肉类、海
              </span>
              <span class="BI22221">
                鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是
              </span>
              <span class="BI22221">
                餐饮行业，我们都能满足您的需求。我们的产品不
              </span>
              <span class="BI22221">
                仅品质优良，而且价格合理，具有很高的性价比。
              </span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除了产品品质，我们还注重服务质量。我们拥有
              </span>
              <span class="BI22221">
                一支专业的销售团队和客服团队，能够为客户提供及时、
              </span>
              <span class="BI22221">周到的服务</span>
            </div>
            <div class="BI05"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI06"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们还提供定制化的解决方案，根据客户的
              </span>
              <span class="BI22221">需求提供个性化的产品和服务。</span>
            </div>
          </div>
        </div>
        <div v-else-if="selectedContent === '有机食品认证'">
          <div class="BI2211">
            <h2 class="BI2212">有机食品认证</h2>
            <a class="BI2213">Traditional&nbsp;Channels</a>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;好心动科技有限公司，一家专注于提供高品质冻
              </span>
              <span class="BI22221">
                品的企业，致力于为消费者带来安全、美味、便捷的
              </span>
              <span class="BI22221">食品体验。</span>
            </div>
            <div class="BI01"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI02"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的品牌理念是“品质至上，服务第一”。公
              </span>
              <span class="BI22221">
                司拥有严格的质量控制体系，从源头抓起，确保每
              </span>
              <span class="BI22221">一件产品都符合严格的质量标准</span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们与众多优质供应商建立了长期合作关系，精选
              </span>
              <span class="BI22221">
                新鲜、优质的食材，采用先进的冷冻技术，保留食材的
              </span>
              <span class="BI22221">原汁原味和营养成分。</span>
            </div>
            <div class="BI03"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI04"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的产品种类丰富多样，涵盖了肉类、海
              </span>
              <span class="BI22221">
                鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是
              </span>
              <span class="BI22221">
                餐饮行业，我们都能满足您的需求。我们的产品不
              </span>
              <span class="BI22221">
                仅品质优良，而且价格合理，具有很高的性价比。
              </span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除了产品品质，我们还注重服务质量。我们拥有
              </span>
              <span class="BI22221">
                一支专业的销售团队和客服团队，能够为客户提供及时、
              </span>
              <span class="BI22221">周到的服务</span>
            </div>
            <div class="BI05"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI06"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们还提供定制化的解决方案，根据客户的
              </span>
              <span class="BI22221">需求提供个性化的产品和服务。</span>
            </div>
          </div>
        </div>
        <div v-else-if="selectedContent === '成分检测'">
          <div class="BI2211">
            <h2 class="BI2212">成分检测</h2>
            <a class="BI2213">Traditional&nbsp;Channels</a>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;好心动科技有限公司，一家专注于提供高品质冻
              </span>
              <span class="BI22221">
                品的企业，致力于为消费者带来安全、美味、便捷的
              </span>
              <span class="BI22221">食品体验。</span>
            </div>
            <div class="BI01"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI02"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的品牌理念是“品质至上，服务第一”。公
              </span>
              <span class="BI22221">
                司拥有严格的质量控制体系，从源头抓起，确保每
              </span>
              <span class="BI22221">一件产品都符合严格的质量标准</span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们与众多优质供应商建立了长期合作关系，精选
              </span>
              <span class="BI22221">
                新鲜、优质的食材，采用先进的冷冻技术，保留食材的
              </span>
              <span class="BI22221">原汁原味和营养成分。</span>
            </div>
            <div class="BI03"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI04"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的产品种类丰富多样，涵盖了肉类、海
              </span>
              <span class="BI22221">
                鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是
              </span>
              <span class="BI22221">
                餐饮行业，我们都能满足您的需求。我们的产品不
              </span>
              <span class="BI22221">
                仅品质优良，而且价格合理，具有很高的性价比。
              </span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除了产品品质，我们还注重服务质量。我们拥有
              </span>
              <span class="BI22221">
                一支专业的销售团队和客服团队，能够为客户提供及时、
              </span>
              <span class="BI22221">周到的服务</span>
            </div>
            <div class="BI05"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI06"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们还提供定制化的解决方案，根据客户的
              </span>
              <span class="BI22221">需求提供个性化的产品和服务。</span>
            </div>
          </div>
        </div>
        <div v-else-if="selectedContent === '食品科技'">
          <div class="BI2211">
            <h2 class="BI2212">食品科技</h2>
            <a class="BI2213">Traditional&nbsp;Channels</a>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;好心动科技有限公司，一家专注于提供高品质冻
              </span>
              <span class="BI22221">
                品的企业，致力于为消费者带来安全、美味、便捷的
              </span>
              <span class="BI22221">食品体验。</span>
            </div>
            <div class="BI01"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI02"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的品牌理念是“品质至上，服务第一”。公
              </span>
              <span class="BI22221">
                司拥有严格的质量控制体系，从源头抓起，确保每
              </span>
              <span class="BI22221">一件产品都符合严格的质量标准</span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们与众多优质供应商建立了长期合作关系，精选
              </span>
              <span class="BI22221">
                新鲜、优质的食材，采用先进的冷冻技术，保留食材的
              </span>
              <span class="BI22221">原汁原味和营养成分。</span>
            </div>
            <div class="BI03"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI04"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的产品种类丰富多样，涵盖了肉类、海
              </span>
              <span class="BI22221">
                鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是
              </span>
              <span class="BI22221">
                餐饮行业，我们都能满足您的需求。我们的产品不
              </span>
              <span class="BI22221">
                仅品质优良，而且价格合理，具有很高的性价比。
              </span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除了产品品质，我们还注重服务质量。我们拥有
              </span>
              <span class="BI22221">
                一支专业的销售团队和客服团队，能够为客户提供及时、
              </span>
              <span class="BI22221">周到的服务</span>
            </div>
            <div class="BI05"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI06"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们还提供定制化的解决方案，根据客户的
              </span>
              <span class="BI22221">需求提供个性化的产品和服务。</span>
            </div>
          </div>
        </div>
        <div v-else-if="selectedContent === '餐饮业态'">
          <div class="BI2211">
            <h2 class="BI2212">餐饮业态</h2>
            <a class="BI2213">Traditional&nbsp;Channels</a>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;好心动科技有限公司，一家专注于提供高品质冻
              </span>
              <span class="BI22221">
                品的企业，致力于为消费者带来安全、美味、便捷的
              </span>
              <span class="BI22221">食品体验。</span>
            </div>
            <div class="BI01"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI02"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的品牌理念是“品质至上，服务第一”。公
              </span>
              <span class="BI22221">
                司拥有严格的质量控制体系，从源头抓起，确保每
              </span>
              <span class="BI22221">一件产品都符合严格的质量标准</span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们与众多优质供应商建立了长期合作关系，精选
              </span>
              <span class="BI22221">
                新鲜、优质的食材，采用先进的冷冻技术，保留食材的
              </span>
              <span class="BI22221">原汁原味和营养成分。</span>
            </div>
            <div class="BI03"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI04"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的产品种类丰富多样，涵盖了肉类、海
              </span>
              <span class="BI22221">
                鲜、蔬菜、水果等多个品类。无论是家庭烹饪还是
              </span>
              <span class="BI22221">
                餐饮行业，我们都能满足您的需求。我们的产品不
              </span>
              <span class="BI22221">
                仅品质优良，而且价格合理，具有很高的性价比。
              </span>
            </div>
          </div>
          <div class="BI2221 fx-r">
            <div class="BI2222">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除了产品品质，我们还注重服务质量。我们拥有
              </span>
              <span class="BI22221">
                一支专业的销售团队和客服团队，能够为客户提供及时、
              </span>
              <span class="BI22221">周到的服务</span>
            </div>
            <div class="BI05"></div>
          </div>
          <div class="BI22211 fx-r">
            <div class="BI06"></div>
            <div class="BI2223">
              <span class="BI22221">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们还提供定制化的解决方案，根据客户的
              </span>
              <span class="BI22221">需求提供个性化的产品和服务。</span>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bar from "@/components/NavBar.vue";
import Video from "@/components/Video.vue";
import { ref } from "vue";

export default {
  name: "ComponentTesting",
  components: {
    Bar,
    Video,
  },
  setup() {
    const selectedContent = ref("成分检测");
    // 选择内容的方法
    function selectContent(content) {
    selectedContent.value = content;
    }
    return {
      selectedContent,
      selectContent,
    };
  },
  data() {
    return {
      //显示
      isShow: false,
    };
  },
  methods:{
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
};
</script>
<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.BI1 {
  height: 500px;
  overflow-x: hidden;
  background-image: url(@/assets/食品研究院.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BI111 {
  color: aliceblue;
}
.ul1 {
  font-size: 20px;
  text-decoration: none;
  color: black;
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: #ffffff;
  display: flex;
  margin-top: 9px;
  margin-right: 10px;
  margin-left: 30px;
}
.sidebar {
  width: 200px;
  background-color: rgb(252, 255, 252);
}
li {
  font-size: 20px;
}
.sidebar ul {
  /* list-style-type: none; 去除圆圈 */
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 10px;
  cursor: pointer;
}

.sidebar ul li:hover {
  background-color: rgb(220, 23, 23);
}

.content-area {
  width: 78%;
  height: 100%;
  margin-left: 10px;
  padding: 0px;
}
.BI2 {
  width: 100%;
  height: 1500px;
}
.BI21 {
  width: 20%;
  height: 100%;
  border-right: 1px solid red;
  border-left: 8px solid red;
}
.BI22 {
  width: 75%;
  height: 100%;
}
.BI2211 {
  width: 200px;
  height: 80px;
  border: 1px solid red;
  border-radius: 20px 20px 20px 20px;
  background-color: lightpink;
  margin-top: 50px;
  margin-left: 100px;
  text-align: left 20px;
}
.BI2212 {
  margin-top: 10px;
  margin-left: 20px;
}
.BI2213 {
  margin-top: 10px;
  font-size: 10dm;
  margin-left: 20px;
}
.BI2221 {
  height: 200px;
  width: 90%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
.BI2221::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid red;
}
.BI22211 {
  height: 200px;
  width: 90%;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
.BI22211::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-top: 20px solid red;
}
.BI2222 {
  width: 49%;
  height: 100%;
  margin-left: 10px;
  margin-top: 100px;
  margin-right: 100px;
}
.BI2223 {
  width: 50%;
  height: 100%;
  margin-left: 50px;
  margin-top: 100px;
}
.BI22221 {
  letter-spacing: 4px;
}
.BI01 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/Frame\ 18.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-left: 50px; */
}
.BI02 {
  width: 43%;
  height: 100%;
  background-image: url(@/assets/Rectangle\ 11.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI03 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/Frame\ 1.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI04 {
  width: 43%;
  height: 100%;
  background-image: url(@/assets/线上渠道4.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI05 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/线上渠道5.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
.BI06 {
  width: 40%;
  height: 100%;
  background-image: url(@/assets/线上渠道6.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-right: 4%; */
}
</style>
