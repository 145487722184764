<template>
  <div class="parent-container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px"/>
      <div class="BI1">
        <div class="BI11 fx-h">
          <h1 class="BI111">产 品</h1>
          <br />
          <h3 class="BI111">冷冻锁住新鲜，美味尽在口中</h3>
        </div>
      </div>
      <div class="BI2 fx-h">
        <div class="img fx-r">
          <div class="img1">
            <h1 class="text-h1">冷冻肉类</h1>
            <router-link to="/MeatFrozen"
              ><a class="text-a">查看更多——></a></router-link
            >
          </div>
          <div class="img2">
            <h1 class="text-h1">冷冻海鲜水产</h1>
            <router-link to="/FrozenWater"
              ><a class="text-a">查看更多——></a></router-link
            >
          </div>
        </div>
        <!-- <div class="title">
          <a class="title-text">主食</a>
        </div> -->
        <div class="img fx-r">
          <div class="img3">
            <h1 class="text-h1">冷冻小吃面点</h1>
            <router-link to="/FrozenSnack"
              ><a class="text-a">查看更多——></a></router-link
            >
          </div>
          <div class="img4">
            <h1 class="text-h1">冷冻预制菜</h1>
            <router-link to="/FrozenDishes"
              ><a class="text-a">查看更多——></a></router-link
            >
          </div>
        </div>
        <!-- <div class="title">
          <a class="title-text">调理品</a>
        </div>
        <div class="img fx-r">
          <div class="img9">
            <h1 class="text-h1">冷冻调理品</h1>
            <router-link to="/MeatFrozen"
              ><a class="text-a">查看更多——></a></router-link
            >
          </div>
        </div> -->
        <!-- <div class="title">
          <a class="title-text">果蔬</a>
        </div> -->
        <div class="img fx-r">
          <div class="img5">
            <h1 class="text-h1">冷冻调理品</h1>
            <router-link to="/FrozenConditioning"
              ><a class="text-a">查看更多——></a></router-link
            >
          </div>
          <div class="img6">
            <h1 class="text-h1">冷冻果蔬菌类</h1>
            <router-link to="/FrozenVegetable"
              ><a class="text-a">查看更多——></a></router-link
            >
          </div>
        </div>
        <!-- <div class="title">
          <a class="title-text">其他</a>
        </div> -->
        <div class="img fx-r">
          <div class="img7">
            <h1 class="text-h1">冷藏进口水果</h1>
            <router-link to="/RefrigeratedFruits"
              ><a class="text-a">查看更多——></a></router-link
            >
          </div>
          <div class="img8">
            <h1 class="text-h1">火锅产品</h1>
            <router-link to="/HotpotProducts"
              ><a class="text-a">查看更多——></a></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/NavBar.vue";
import Video from "@/components/Video.vue";
export default {
  name: "ProductIntroduction",
  components: {
    Video,
    Bar,
  },
  data() {
    return {
      //显示
      isShow: false,
    };
  },
  methods: {
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
};
</script>

<style scoped>
.parent-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.BI1 {
  height: 500px;
  overflow-x: hidden;
  background-image: url(@/assets/产品.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.BI11 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BI11 h1{
  font-size: 50px;
}
.BI11 h3{
  font-size: 17px;
}
.BI111 {
  color: white;
  font-weight: 500px;
}
.BI2 {
  width: 100%;
  margin-bottom: 50px;
}
.title {
  width: 100%;
  height: 100px;
  text-align: center;
  margin-bottom: 20px;
}
.title-text {
  width: 180px;
  height: 50px;
  font-size: 40px;
  align-items: center;
  color: black;
  border: 1px;
  border-color: hotpink;
  display: inline-block;
  padding: 5px;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid rgb(230, 53, 53);
  margin-top: 40px;
  margin-bottom: 10px;
}
.img {
  width: 100%;
  height: 350px;
  margin-top: 50px;
}
.img1 {
  width: 49.7%;
  height: 100%;
  text-align: center;
  /* margin-left: 3%; */
  margin-right: 0.3%;
  background-image: url(@/assets/stuk-rosbief.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.img2 {
  width: 49.7%;
  height: 100%;
  text-align: center;
  margin-left: 0.3%;
  /* margin-right: 3%; */
  background-image: url(@/assets/冷冻海鲜水产品.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.img3 {
  width: 49.7%;
  height: 100%;
  text-align: center;
  /* margin-left: 3%; */
  margin-right: 0.3%;
  background-image: url(@/assets/冷冻小吃面点.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.img4 {
  width: 49.7%;
  height: 100%;
  text-align: center;
  margin-left: 0.3%;
  /* margin-right: 3%; */
  background-image: url(@/assets/冷冻预制菜.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.img5 {
  width: 49.7%;
  height: 100%;
  text-align: center;
  /* margin-left: 3%; */
  margin-right: 0.3%;
  background-image: url(@/assets/冷冻调理品.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.img6 {
  width: 49.7%;
  height: 100%;
  text-align: center;
  margin-left: 0.3%;
  /* margin-right: 3%; */
  background-image: url(@/assets/冷冻果蔬菌类.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.img7 {
  width: 49.7%;
  height: 100%;
  text-align: center;
  /* margin-left: 3%; */
  margin-right: 0.3%;
  background-image: url(@/assets/冷藏进口水果.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.img8 {
  width: 49.7%;
  height: 100%;
  text-align: center;
  margin-left: 0.3%;
  /* margin-right: 3%; */
  background-image: url(@/assets/火锅产品.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text-h1 {
  margin-top: 15%;
  color: aliceblue;
  letter-spacing: 20px;
  align-items: center;
}
.text-a {
  margin-top: 10px;
  margin-right: 20px;
  font-size: 23px;
  width: 200px;
  height: 40px;
  color: red;
  align-items: center;
  border: 1px solid red;
  padding: 2px 2px;
  display: inline-block;
}
.text-a:hover {
  background-color: #db1919;
  color: #fff;
}
</style>
