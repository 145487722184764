<template>
  <!-- 这是上半部分主体 -->
  <div class="container">
    <Video :isShow.sync="isShow" />
    <div class="main">
      <Bar @show-video="handleShowVideo" style="position: fixed; top: 0px" />
      <!-- 图片部分 -->
      <div class="fx-h">
        <div class="header">
          <img
            src="@/assets/进口数据.png"
            alt="1"
            width="100%"
            height="100%"
            class="background-image"
          />
          <h1 class="text-overlay">
            进&nbsp;&nbsp;口&nbsp;&nbsp;水&nbsp;&nbsp;果&nbsp;&nbsp;蔬&nbsp;&nbsp;菜
          </h1>
          <br />
          <h3 class="text-overlay2">Imported fruits and vegetables</h3>
        </div>
      </div>
      <!-- 这是下半部分 -->
      <div class="row-b1">
        <div class="wp">
          <!-- 左边部分 -->
          <div class="col-l">
            <div class="work_list clearfix" id="lrnavf1">
              <div class="tit" @click.prevent="goToFactoryadmission">
                <div class="circle"></div>
                <div>准入工厂查询</div>
              </div>
              <div class="tit" @click.prevent="goToImportquantity">
                <div class="circle"></div>
                <div>进口数量统计</div>
              </div>
              <div
                class="tit"
                @click.prevent="goToImportEV"
                style="background-color: #e60012"
              >
                <div class="circle"></div>
                <div>进口水果蔬菜</div>
              </div>
            </div>
          </div>
          <!-- 右边部分 -->
          <div class="col-r">
            <div class="g-titIns1">进口水果蔬菜</div>
            <div class="m-chanpin">
              <div class="supplier-management">
                <!-- 搜索框 -->
                <div class="search-component">
                  <!-- 下拉菜单 -->
                  <select v-model="selectedCountry" @change="onOptionChange1">
                    <option disabled value="">国家</option>
                    <option
                      v-for="option1 in options1"
                      :key="option1"
                      :value="option1"
                    >
                      {{ option1 }}
                    </option>
                  </select>
                  <select v-model="selectedType" @change="onOptionChange2">
                    <option disabled value="">品类</option>
                    <option
                      v-for="option2 in options2"
                      :key="option2"
                      :value="option2"
                    >
                      {{ option2 }}
                    </option>
                  </select>
                  <select v-model="selectedFreshness" @change="onOptionChange3">
                    <option disabled value="">冻/鲜</option>
                    <option
                      v-for="option3 in options3"
                      :key="option3"
                      :value="option3"
                    >
                      {{ option3 }}
                    </option>
                  </select>
                  <input type="date" id="time" name="time" />

                  <button @click="clearSelection">
                    <span>重置</span>
                  </button>
                  <!-- 搜索按钮 -->
                  <button @click="performSearch">
                    <i class="el-icon-search"></i>
                  </button>
                </div>
                <!-- 下部分的表格 -->
                <table>
                  <thead>
                    <tr class="tr-th">
                      <th>国家</th>
                      <th>编码</th>
                      <th>产品名</th>
                      <th>月份</th>
                      <th>数量</th>
                      <th>人民币</th>
                      <th>美元</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in filteredItems"
                      :key="item.id"
                      class="tr-td"
                    >
                      <td>{{ item.country }}</td>
                      <td>{{ item.code }}</td>
                      <td>{{ item.productName }}</td>
                      <td>{{ item.month }}</td>
                      <td>{{ item.count }}</td>
                      <td>{{ item.rmb }}</td>
                      <td>{{ item.dollar }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Video from "@/components/Video.vue";
import Bar from "@/components/NavBar.vue";

export default Vue.extend({
  name: "ImportEV",
  components: {
    Video,
    Bar,
  },
  data() {
    return {
      selectedOption1: "", // 用户选中的选项
      selectedOption2: "", // 用户选中的选项
      selectedOption3: "", // 用户选中的选项
      selectedOption4: "", // 用户选中的选项
      selectedOption5: "", // 用户选中的选项
      options1: [
        // 下拉菜单的选项
        "中国",
        "美国",
        "日本",
        "俄罗斯",
        "越南",
      ],
      options2: [
        // 下拉菜单的选项
        "中国",
        "美国",
        "日本",
        "俄罗斯",
        "越南",
      ],
      options3: [
        // 下拉菜单的选项
        "001",
        "002",
        "003",
        "004",
        "005",
      ],
      options4: [
        // 下拉菜单的选项
        "去骨",
        "带骨",
      ],
      options5: [
        // 下拉菜单的选项
        "冻",
        "鲜",
      ],
      // 示例数据
      items: [
        {
          id: 1,
          country: "美国",
          code: "OK0819",
          productName: "羊后腿",
          month: "202405",
          count: "60001吨",
          rmb: "466164",
          dollar: "62000",
        },
        {
          id: 2,
          country: "美国",
          code: "OK0819",
          productName: "羊后腿",
          month: "202405",
          count: "60001吨",
          rmb: "466164",
          dollar: "62000",
        },
        {
          id: 3,
          country: "美国",
          code: "OK0819",
          productName: "羊后腿",
          month: "202405",
          count: "60001吨",
          rmb: "466164",
          dollar: "62000",
        },
        {
          id: 4,
          country: "美国",
          code: "OK0819",
          productName: "羊后腿",
          month: "202405",
          count: "60001吨",
          rmb: "466164",
          dollar: "62000",
        },
        {
          id: 5,
          country: "美国",
          code: "OK0819",
          productName: "羊后腿",
          month: "202405",
          count: "60001吨",
          rmb: "466164",
          dollar: "62000",
        },
        {
          id: 6,
          country: "美国",
          code: "OK0819",
          productName: "羊后腿",
          month: "202405",
          count: "60001吨",
          rmb: "466164",
          dollar: "62000",
        },
        {
          id: 7,
          country: "美国",
          code: "OK0819",
          productName: "羊后腿",
          month: "202405",
          count: "60001吨",
          rmb: "466164",
          dollar: "62000",
        },
        {
          id: 8,
          country: "美国",
          code: "OK0819",
          productName: "羊后腿",
          month: "202405",
          count: "60001吨",
          rmb: "466164",
          dollar: "62000",
        },
        // 添加更多数据...
      ],
      selectedCountry: "",
      selectedType: "",
      selectedFreshness: "",
      //显示
      isShow: false,
    };
  },
  methods: {
    //搜索
    onOptionChange1() {
      console.log("Selected option changed to:", this.selectedOption1);
    },
    onOptionChange2() {
      console.log("Selected option changed to:", this.selectedOption2);
    },
    onOptionChange3() {
      console.log("Selected option changed to:", this.selectedOption3);
    },
    onOptionChange4() {
      console.log("Selected option changed to:", this.selectedOption4);
    },
    onOptionChange5() {
      console.log("Selected option changed to:", this.selectedOption5);
    },
    // 执行搜索的方法
    performSearch() {
      console.log("全部搜索");
    },
    //进口水果蔬菜
    goToImportEV() {
      this.$router.push("/importdata/ImportEV").catch((err) => {});
    },
    //进口数量查询
    goToImportquantity() {
      this.$router.push("/importdata/Importquantity").catch((err) => {});
    },
    //准入工厂查询
    goToFactoryadmission() {
      this.$router.push("/importdata/Factoryadmission").catch((err) => {});
    },
    //加入我们
    goToJoinUs() {
      this.$router.push("/JoinUs").catch((err) => {});
    },
    //视频
    handleShowVideo() {
      window.scrollTo(0, 0);
      this.isShow = true;
    },
    clearSelection() {  
      // 清除选择框的内容  
      this.selectedCountry = '';
      this.selectedType = "";
      this.selectedFreshness = "";
    } 
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("isVideoShown")) {
      localStorage.setItem("isVideoShown", "false");
    }
    const isVideoShown = localStorage.getItem("isVideoShown");
    if (isVideoShown) {
      this.isShow = true;
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        // 根据selectedCountry筛选
        if (this.selectedCountry && item.country !== this.selectedCountry) {
          return false;
        }
        // 假设items中存在type字段用于去骨带骨的筛选
        if (this.selectedType && item.type !== this.selectedType) {
          return false;
        }
        // 假设items中存在freshness字段用于冷鲜的筛选
        if (
          this.selectedFreshness &&
          item.freshness !== this.selectedFreshness
        ) {
          return false;
        }
        // 假设items中存在factoryCode字段用于厂号的筛选
        if (
          this.selectedFactoryCode &&
          item.factoryCode !== this.selectedFactoryCode
        ) {
          return false;
        }
        return true;
      });
    },
  },
});
</script>

<style scoped>
/* 上半部分 */
.container {
  /* width: 100vw; */
  max-width: 100%;
  position: relative;
  width: 100%;
}
.container .main {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
/* 图片设置 */
.fx-h .header {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  position: relative;
}
.fx-h .text-overlay {
  position: absolute;
  font-size: 50px;
  top: 230px;
  color: white; /* 根据背景色设置合适的文字颜色 */
  font-weight: 500px;
}
.fx-h .text-overlay2 {
  position: absolute;
  top: 300px;
  color: white; /* 根据背景色设置合适的文字颜色 */
  font-size: 17px;
  font-weight: 500;
}
/* 下半部分 */
.row-b1 {
  overflow: hidden;
}
.wp {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
/* 左边 */
.row-b1 .col-l {
  width: 20vw;
  /* 显式地设置左边框 */
  border-left: 8px solid #e60012; /* 左边框宽度为5px，样式为实线，颜色为黑色 */
  /* 显式地设置右边框 */
  border-right: 1px solid #e60012; /* 右边框宽度为2px，样式为实线，颜色为黑色 */
}
.work_list {
  width: 100%;
  /* padding-top: 12px; */
  margin-bottom: 20px;
  /* 上下边框设置为0px（实际上，如果不设置它们，它们就默认为0px，但这里显式设置以表明意图） */
  border-top: 0px solid transparent; /* 宽度为0，颜色不影响显示 */
  border-bottom: 0px solid transparent; /* 同上 */
  /* 添加一些内边距以便更好地看到边框效果 */
}
.work_list .tit {
  display: flex;
  align-items: center; /* 垂直居中 */
  cursor: pointer;
  line-height: 44px;
  background-size: auto 10px;
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
  float: left;
  font-size: 20px;
}
.work_list .tit .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: #fff;
  display: flex;
  margin-right: 10px;
  margin-left: 30px;
}
.col-l .work_list .tit:hover {
  background-color: #e60012; /* 鼠标悬浮时背景变红 */
  transition: background-color 1s ease-in-out; /* 添加背景颜色变化的过渡效果 */
}

/* 右边 */
.row-b1 .col-r {
  float: none;
  overflow: hidden;
  margin-left: 10px;
  width: 80%;
  margin-bottom: 50px;
}
.g-titIns1 {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 44px;
  line-height: 44px;
  font-size: 40px;
  color: black;
  font-weight: normal;
  position: relative;
}
.m-chanpin {
  overflow: hidden;
  margin-left: 30px;
}
.supplier-management {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
/* 搜索栏 */
.search-component {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.search-component select {
  width: 150px;
  height: 30px;
  border: 1px solid #f28088;
  border-radius: 10px;
  padding-left: 10px;
}
input[type="date"] {
  width: 150px;
  height: 30px;
  color: #333;
  border: 1px solid #f28088;
  border-radius: 10px;
  padding-left: 10px;
}
.search-component button {
  width: 50px;
  border: 1px solid #f28088;
  border-radius: 10px;
  background-color: #fff;
}
/* .search-component .bi {
  color: red;
} */
.search-component i {
  color: red;
}
/* 下半部分表单,表格展示 */
table {
  margin-top: 30px;
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 8px 30px 8px 30px; /* 增加内边距以分隔单元格 */
  text-align: center;
  width: 100%;
}
.tr-th {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-color: #f28088;
  border: 1px solid #f28088;
  margin-bottom: 20px;
  border-radius: 5px;
}
.tr-td {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  margin-top: 10px;
  border: 1px solid #f28088;
  border-radius: 5px;
}
</style>
