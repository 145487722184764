import { render, staticRenderFns } from "./Frozenstorage.vue?vue&type=template&id=4bd86049&scoped=true"
import script from "./Frozenstorage.vue?vue&type=script&lang=ts"
export * from "./Frozenstorage.vue?vue&type=script&lang=ts"
import style0 from "./Frozenstorage.vue?vue&type=style&index=0&id=4bd86049&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd86049",
  null
  
)

export default component.exports